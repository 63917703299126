import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Input, Modal, Button, notification, Col, Row,
} from 'antd';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import axios from '../axios';
import UploadImage from './UploadImage';
import ConfirmAction from './ConfirmAction';
import '../css/createDepartments.css';

const { TextArea } = Input;

function CreateDepartment({
  open, setOpen, fetchDepartments,
}) {
  const navigate = useNavigate();
  const [t] = useTranslation('global');

  const languages = ['pt', 'en'];

  const [names, setNames] = useState(Array(languages.length).fill(''));
  const [descriptions, setDescriptions] = useState(Array(languages.length).fill(''));
  const [clearImagePreview, setClearImagePreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  function mergeArraysToObject(arr) {
    const obj = {};
    languages.forEach((element, index) => {
      obj[element] = arr[index];
    });
    return obj;
  }

  const handleCreateRole = async () => {
    setLoading(true);
    const jsonNames = mergeArraysToObject(names);
    const jsonDescriptions = mergeArraysToObject(descriptions);
    try {
      const response = await axios.post(
        '/api/department/create',
        {
          name: jsonNames,
          description: jsonDescriptions,
          companyID: '1',
        },
      );
      if (response.status === 201) {
        openNotificationWithIcon('success', t('departments.create_success'));
        setNames(Array(languages.length).fill(''));
        setDescriptions(Array(languages.length).fill(''));
        setClearImagePreview(true);
        setLoading(false);
        await fetchDepartments();
        setOpen(false);
        setClearImagePreview(false);
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        switch (status) {
          case 406:
            openNotificationWithIcon('error', t('departments.create_406'));
            break;
          case 401:
            openNotificationWithIcon('error', t('auth.need_login'));
            navigate('/login');
            break;
          case 400:
            openNotificationWithIcon('error', t('departments.create_400'));
            break;
          case 403:
            openNotificationWithIcon('error', t('auth.not_authorized'));
            break;
          default:
            openNotificationWithIcon('error', error.response.data.message || t('departments.create_error'));
        }
      } else {
        openNotificationWithIcon('error', t('departments.create_error'));
      }
      setLoading(false);
    }
  };

  const onImageUpload = (imageDataURL) => {
    // Do something with the image Data
    console.log(imageDataURL);
  };

  const handleCreateDepartmentConfirmation = () => {
    handleCreateRole();
  };

  const showConfirmationModal = () => {
    setShowConfirmation(true);
  };

  const updateNames = (name, index) => {
    const updatedArray = names.map((item, i) => {
      if (i === index) {
        return name; // Update the item at the specified index
      }
      return item;
    });
    setNames(updatedArray);
  };

  const updateDescriptions = (description, index) => {
    const updatedArray = descriptions.map((item, i) => {
      if (i === index) {
        return description; // Update the item at the specified index
      }
      return item;
    });
    setDescriptions(updatedArray);
  };

  return (
    <div>
      <Modal
        open={open}
        title={<h2 className="create-department-title">{t('departments.create_department')}</h2>}
        onCancel={handleCancel}
        centered
        footer={null}
        className="create-department-popup"
        width="750px"
      >
        <form onSubmit={handleCreateRole} className="create-department-form" id="create-department-form">
          <Row>
            <Col xs={24} sm={24} md={16}>
              {languages.map((language, index) => (
                <>
                  <p className="create-department-name">
                    {t('departments.name_in')}
                    {t(`languages.${language}`)}
                    :
                  </p>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    maxLength={50}
                    showCount
                    value={names[index]}
                    onChange={(e) => updateNames(e.target.value, index)}
                  />
                </>
              ))}
              {languages.map((language, index) => (
                <>
                  <p className="create-department-name">
                    {t('departments.description_in')}
                    {t(`languages.${language}`)}
                    :
                  </p>
                  <TextArea
                    rows={3}
                    value={descriptions[index]}
                    maxLength={500}
                    showCount
                    onChange={(e) => updateDescriptions(e.target.value, index)}
                  />
                </>
              ))}
            </Col>
            <Col xs={24} sm={24} md={8}>
              <div className="create-department-icon">
                <p className="input-icon">{t('jobs.icon')}</p>
                <UploadImage
                  onImageUpload={onImageUpload}
                  clearImagePreview={clearImagePreview}
                />
              </div>
            </Col>
          </Row>
          <Button className="create-department-button" type="button" loading={loading} onClick={showConfirmationModal}>
            {t('role.create')}
          </Button>
        </form>
        <ConfirmAction
          open={showConfirmation}
          setOpen={setShowConfirmation}
          onConfirm={handleCreateDepartmentConfirmation}
        />
      </Modal>
    </div>
  );
}

CreateDepartment.propTypes = {
  open: PropTypes.isRequired,
  setOpen: PropTypes.isRequired,
  fetchDepartments: PropTypes.isRequired,
};

export default CreateDepartment;
