import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Col, Row, Table, Input, notification,
} from 'antd';
import CreateRole from '../components/CreateRole';
import axios from '../axios';
import Navbar from '../components/Navbar';
import NavbarMobile from '../components/NavbarMobile';
import ConfirmDelete from '../components/ConfirmDelete';
import DeleteIcon from '../images/delete_icon.svg';
import EditIcon from '../images/edit_icon.svg';
import languageStore from '../stores/languageStore';
import '../css/roles.css';

import { AbilityContext } from '../Abilities/Can';

function Roles() {
  const [t] = useTranslation('global');
  const companyID = 1;
  const ability = useContext(AbilityContext);
  const [lightLogo, setLightLogo] = useState('');
  const navigate = useNavigate();

  const [hoveredRowKey, setHoveredRowKey] = useState(null);

  // Confirm dialog delete
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [roleIdToDelete, setRoleIdToDelete] = useState(null);
  const [roleNameToDelete, setRoleNameToDelete] = useState('');

  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const locale = {
    emptyText: t('role.empty_list'),
    triggerDesc: t('table.sort_descend'),
    triggerAsc: t('table.sort_ascend'),
    cancelSort: t('table.cancel_sort'),
  };

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  const [originalData, setOriginalData] = useState('');
  const [filteredData, setFilteredData] = useState('');
  const [searchText, setSearchText] = useState('');
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['3', '5', '8', '10', '15', '20', '30', '50'],
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        ...pagination,
      },
      filters,
      sorter,
    });
  };

  const fetchRoles = async () => {
    setLoading(true);
    await axios.get('/api/role')
      .then((response) => {
        const parsedTableData = response.data;
        parsedTableData.forEach((role, index) => {
          parsedTableData[index].name = role.name[selectedLanguage];
          parsedTableData[index].description = role.description[selectedLanguage];
        });
        setOriginalData(parsedTableData);
        setFilteredData(parsedTableData);

        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: response.data.length,
          },
        });
      })
      .catch((error) => {
        console.error('Error fetching Roles:', error);
      });

    setLoading(false);
  };

  useEffect(() => {
    fetchRoles();
  }, [languageStore.getState().currentLanguage]);

  // Open Role page when the table row
  const handleRowClick = (record) => {
    const roleId = record;
    navigate(`/role/${roleId}`);
  };

  // Open edit page
  const handleEditClick = (record) => {
    const roleId = record;
    navigate(`/role/${roleId}/edit`);
  };

  // Display delete confirmation
  const showDeleteConfirm = (roleId, roleName) => {
    setRoleIdToDelete(roleId);
    setRoleNameToDelete(roleName);
    setShowConfirmation(true);
  };

  // Delete role
  const deleteRow = async () => {
    if (roleIdToDelete) {
      try {
        await axios.delete(`/api/role/${roleIdToDelete}`);
        // Update Table
        fetchRoles();
        // Show success
        openNotificationWithIcon('success', roleNameToDelete + t('confirmModal.success_delete'));
      } catch (error) {
        console.error('Error deleting role:', error);
        openNotificationWithIcon('error', t('confirmModal.error_delete') + roleNameToDelete);
      }
    }
    setRoleIdToDelete(null);
    setRoleNameToDelete('');
  };

  const columns = [
    {
      title: t('role.role_column_title'),
      dataIndex: 'name',
      key: 'role',
      sorter: (a, b) => a.name.localeCompare(b.name), // Sorting alphabetically
      width: '30%',
    },
    {
      title: t('role.description_column_title'),
      dataIndex: 'description',
      sorter: (a, b) => a.description.localeCompare(b.description), // Sorting alphabetically
      key: 'description',
    },
  ];

  if (ability.can('delete', 'Roles') || ability.can('update', 'Roles')) {
    columns.push({
      title: '',
      key: 'actions',
      width: '65px',
      className: 'icons-column',
      render: (text, record) => (
        <Row>
          {/* Edit icon to open Role page */}
          {ability.can('update', 'Roles') && (
            <button
              type="button"
              className={hoveredRowKey === record.id ? 'table-icon show' : 'table-icon'}
              onClick={(e) => {
                e.stopPropagation();
                handleEditClick(record.id);
              }}
            >
              <img src={EditIcon} alt="Edit" />
            </button>
          )}
          {/* Bin icon to delete row */}
          {ability.can('delete', 'Roles') && (
            <button
              type="button"
              className={hoveredRowKey === record.id ? 'table-icon show' : 'table-icon'}
              onClick={(e) => {
                e.stopPropagation();
                showDeleteConfirm(record.id, record.name);
              }}
            >
              <img src={DeleteIcon} alt="Delete" />
            </button>
          )}
        </Row>
      ),
    });
  }

  // Get Roles data from the API
  useEffect(() => {
    fetchRoles();
  }, []);

  // Search bar implementation
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filtered = value === ''
      ? originalData
      : originalData.filter((item) => item.name.toLowerCase().includes(value));

    setFilteredData(filtered);
  };
  useEffect(() => {
    axios.get(`/api/settings/company/${companyID}/logos`)
      .then((response) => {
        setLightLogo(response.data.companyLogoLight);
      })
      .catch((error) => {
        console.error('Error fetching logos:', error);
      });
  }, []);
  return (
    <div>
      <Row>
        <Navbar />
        <NavbarMobile />
        <Col xs={0} sm={0} md={6} className="roles-page-col1">
          <img src={lightLogo} alt="logo" className="roles-logo" id="logo-white-profile" />
        </Col>
        <Col xs={24} sm={24} md={18} className="roles-page-col2">
          <h1 className="roles-title">{t('role.title')}</h1>
          <div className="search-and-table">
            <Input
              placeholder={t('role.search_role')}
              className="search-role search-role-desktop search-role-mobile"
              value={searchText}
              onChange={handleSearch}
            />
            <Table
              locale={locale}
              columns={columns}
              dataSource={filteredData}
              className="roles-table"
              pagination={tableParams.pagination}
              loading={loading}
              onChange={handleTableChange}
              onRow={(record) => ({
                onClick: () => handleRowClick(record.id),
                onMouseEnter: () => setHoveredRowKey(record.id),
                onMouseLeave: () => setHoveredRowKey(null),
              })}
              scroll={{
                x: 400, // Ajust as needed
              }}
            />
            <ConfirmDelete
              open={showConfirmation}
              setOpen={setShowConfirmation}
              onConfirm={deleteRow} // Pass the confirmation handler function
              name={roleNameToDelete}
            />
            <button type="button" className="new-role-btn new-role-btn-mobile" onClick={showModal}>
              {t('role.create_role')}
              <i className="plus-icon">+</i>
            </button>
            <CreateRole
              open={open}
              setOpen={setOpen}
              fetchRoles={fetchRoles}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Roles;
