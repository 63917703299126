import React, {
  useContext, createContext, useState, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { createMongoAbility, AbilityBuilder } from '@casl/ability';
import authUserStore from '../stores/authUserStore';
import { AbilityContext } from '../Abilities/Can';

const initialAuthState = {
  email: null,
  permissions: [],
};

const AuthContext = createContext(initialAuthState);

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const authUser = authUserStore((state) => state.authUser);
  const setAuthUser = authUserStore((state) => state.setAuthUser);
  const [isLoggedIn, setIsLoggedIn] = useState(() => !!localStorage.getItem('isLoggedIn'));
  const [ability, setAbility] = useState(() => createMongoAbility([]));

  useEffect(() => {
    const { can, rules } = new AbilityBuilder(createMongoAbility);
    authUser.permissions.forEach((permission) => {
      can(permission.action, permission.subject);
    });
    setAbility(createMongoAbility(rules));
  }, [authUser.permissions]);

  const login = (permissions) => {
    setAuthUser({ permissions });
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', true);
  };

  const logout = () => {
    setAuthUser(initialAuthState);
    setAbility(createMongoAbility([]));
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
  };

  const value = useMemo(() => ({
    authUser,
    isLoggedIn,
    login,
    logout,
  }), [authUser, isLoggedIn]);

  return (
    <AuthContext.Provider value={value}>
      <AbilityContext.Provider value={ability}>
        {children}
      </AbilityContext.Provider>
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthContext };
export default AuthProvider;
