import React from 'react';
import { Col, Row } from 'antd';
import VerificationForm from '../components/authentication/VerificationForm';
import Footer from '../components/Footer';
import LogoWhite from '../images/logo_contorno_branco_2.svg';
import LogoBlack from '../images/logo_preenchimento_preto_2.svg';
import '../css/App.css';

function VerificationPage() {
  return (
    <div>
      <div className="login">
        <Row>
          <Col xs={24} sm={24} md={15} className="logo-col">
            <img src={LogoBlack} alt="logo" className="header-image" id="logo-black" />
            <h1 className="header-title">BEM-VINDO</h1>
            <img src={LogoWhite} alt="logo" className="header-image" id="logo-white" />
          </Col>
          <Col xs={24} sm={24} md={9}>
            <VerificationForm />
          </Col>
        </Row>
      </div>
      <div className="footer-auth">
        <Footer />
      </div>
    </div>
  );
}

export default VerificationPage;
