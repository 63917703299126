import React, { useEffect, useState } from 'react';
import {
  Col, Row, notification,
} from 'antd';
import { useParams } from 'react-router-dom';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { FaArrowRightLong } from 'react-icons/fa6';
import { IoMdStopwatch } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import axios from '../axios';
import Navbar from '../components/Navbar';
import NavbarMobile from '../components/NavbarMobile';
import SeasonStats from '../components/SeasonStats';
import '../css/season.css';

function Season() {
  const [t] = useTranslation('global');

  let seasonID = 0;
  const { id } = useParams();
  seasonID = id;

  const [seasonName, setSeasonName] = useState('');
  const [seasonStartDate, setSeasonStartDate] = useState('');
  const [seasonEndDate, setSeasonEndDate] = useState('');

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: 'Success',
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: 'Error',
        description: message,
      });
    }
  };

  const transformDate = (date) => {
    const dateObj = new Date(date);

    const day = String(dateObj.getUTCDate()).padStart(2, '0');
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
    const year = dateObj.getUTCFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  useEffect(() => {
    axios.get(
      `api/season/${seasonID}`,
    )
      .then((response) => {
        setSeasonName(response.data.name);
        setSeasonStartDate(transformDate(response.data.beginDate));
        setSeasonEndDate(transformDate(response.data.endDate));
      })
      .catch(() => {
        openNotificationWithIcon('error', 'Error fetching role');
      });
  }, []);

  return (
    <div>
      <Row className="season-container">
        <Navbar />
        <NavbarMobile />
        <Col xs={24} sm={24} md={24} className="season-page">
          <h1 className="season-name">{seasonName}</h1>
          <div className="season-page-body">
            <div className="phases-div">
              <div className="curent-phase-div">
                <div>
                  <h3 className="curent-phase-title">{t('season.current_phase')}</h3>
                  <p className="curent-phase-name">Entrevistas Telefónicas</p>
                  <button className="curent-phase-change-schedules-button" type="button">
                    <div className="icon-container">
                      <FaRegCalendarAlt className="calendar-icon" />
                    </div>
                    <div className="text-container">
                      {t('season.change_shedules')}
                    </div>
                  </button>
                </div>
              </div>
              <div className="next-phase-div">
                <div>
                  <h3 className="next-phase-title">{t('season.next_phase')}</h3>
                  <p className="next-phase-name">Dinâmicas de Grupo</p>
                  <div className="next-phase-buttons-div">
                    <button className="curent-phase-change-schedules-button" type="button">
                      <div className="icon-container">
                        <FaArrowRightLong className="season-icons" />
                      </div>
                      <div className="text-container">
                        {t('season.advance_phase')}
                      </div>
                    </button>
                    <button className="curent-phase-change-schedules-button" type="button">
                      <div className="icon-container">
                        <IoMdStopwatch className="season-icons" />
                      </div>
                      <div className="text-container">
                        {t('season.generate_schedules')}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="middle-section">
              <div className="dates-div">
                <div className="start-date-div">
                  <h3 className="start-date-title">
                    {t('season.current_phase_start_date')}
                    :
                  </h3>
                  <div className="start-date-text-div">
                    <FaRegCalendarAlt className="date-icon" />
                    <div className="start-date-text">
                      <p>{seasonStartDate}</p>
                    </div>
                  </div>
                </div>
                <div className="end-date-div">
                  <h3 className="end-date-title">
                    {t('season.current_phase_end_date')}
                    :
                  </h3>
                  <div className="end-date-text-div">
                    <FaRegCalendarAlt className="date-icon" />
                    <div className="end-date-text">
                      <p>{seasonEndDate}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="season-info-div">
                <h3 className="phases-to-open-title">{t('season.phases_to_open')}</h3>
                <p>Entrevista final</p>
                <p>Problema Técnico</p>
              </div>
            </div>
            <SeasonStats candidates={100} cvPhases={50} dynamics={25} />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Season;
