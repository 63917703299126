import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const languageStore = create(persist(
  (set) => ({
    currentLanguage: 'pt',
    setCurrentLanguage: (currentLanguage) => set({ currentLanguage }),
  }),
  {
    name: 'lng',
  },
));

export default languageStore;
