import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../axios';
import { AbilityContext } from '../Abilities/Can';
import '../css/opportunities.css';

function Opportunities() {
  const navigate = useNavigate();
  const [t] = useTranslation('global');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userId, setUserId] = useState();
  const ability = useContext(AbilityContext);

  useEffect(() => {
    axios.get(
      '/api/user/profile',
    )
      .then((response) => {
        if (response.status === 200) {
          setIsLoggedIn(true);
          setUserId(response.data.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const buttonPress = async () => {
    if (isLoggedIn) {
      // Go to user page
      if (ability.can('update', 'Users')) {
        navigate(`user/${userId}`);
      } else {
        navigate('/profile');
      }
    } else {
      // Go to Login page
      navigate('/login');
    }
  };

  return (
    <div className="opportunities">
      <div className="opportunities-component">
        <div className="opportunities-text-div">
          <h1 className="opportunities-title">{t('opportunities.title')}</h1>
          <div className="opportunities-description-div">
            <p className="opportunities-description">
              Que oportunidades temos para ti? Se procuras pessoas com a mesma
              ambição que tu, não tenhas dúvidas.
            </p>
            <p className="opportunities-description">
              Junta-te a nós ao submeter o teu currículo!
            </p>
          </div>
          <button
            type="button"
            className="opportunities-candidate-area-button"
            onClick={buttonPress}
          >
            {t('opportunities.area_button')}
          </button>
        </div>
      </div>
      <span className="opportunities-opacity-component" />
    </div>
  );
}

export default Opportunities;
