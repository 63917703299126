import React, { useState, useEffect } from 'react';
import {
  Row, Col, DatePicker, Collapse, Checkbox, Button, notification, Input, ConfigProvider, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { IoIosArrowRoundForward } from 'react-icons/io';
import ptBR from 'antd/locale/pt_BR';
import enUS from 'antd/locale/en_US';
import CreatePhase from '../components/CreatePhase';
import CreateSeasonWrap from '../components/CreatePhasesWrap';
import axios from '../axios';
import Navbar from '../components/Navbar';
import NavbarMobile from '../components/NavbarMobile';
import ConfirmAction from '../components/ConfirmAction';
import languageStore from '../stores/languageStore';
import '../css/createSeason.css';

dayjs.extend(customParseFormat);

const { Panel } = Collapse;
const { RangePicker } = DatePicker;

function CreateSeason() {
  const companyID = 1;
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [applicationForms, setApplicationForms] = useState([]);
  const [name, setName] = useState('');
  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [t] = useTranslation('global');
  const navigate = useNavigate();
  const [darkLogo, setDarkLogo] = useState('');
  const [open, setOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [phasesData, setPhasesData] = useState([]);

  const [wrapPage, setWrapPage] = useState(false);
  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';

  const datePickerLanguage = selectedLanguage === 'en' ? enUS : ptBR;

  const [selectedApplicationForms, setSelectedApplicationForms] = useState(null);

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleChange = (value) => {
    setSelectedApplicationForms(value);
  };

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await axios.get('/api/department');
      if (response.status === 200) {
        const parsedDepartmentsData = response.data;
        parsedDepartmentsData.forEach((department, index) => {
          parsedDepartmentsData[index].name = department.name[selectedLanguage];
        });
        const deptOptions = parsedDepartmentsData.map((element) => ({
          value: element.id,
          label: element.name,
        }));
        setDepartmentOptions(deptOptions);
      }
    } catch (error) {
      console.error('Error during departments fetch:', error);
    }
  };

  const fetchJobs = async () => {
    try {
      const response = await axios.get('/api/job');
      const jobsData = response.data;
      jobsData.forEach((job, index) => {
        jobsData[index].name = job.name[selectedLanguage];
      });
      const jobsOptions = jobsData.map((element) => ({
        value: element.id,
        name: element.name,
        departmentID: element.departmentID,
      }));
      setJobs(jobsOptions);
    } catch (error) {
      console.error('Error fetching Jobs:', error);
    }
  };

  const fetchApplicationForms = async () => {
    try {
      const response = await axios.get('/api/applicationForm');
      const applicationFormsData = response.data;
      setApplicationForms(applicationFormsData);
    } catch (error) {
      console.error('Error fetching Application Forms:', error);
    }
  };

  useEffect(() => {
    fetchDepartments();
    fetchJobs();
    fetchApplicationForms();
  }, []);

  const handleCheckboxChange = (jobId, checked) => {
    const updatedSelectedJobs = checked
      ? [...selectedJobs, jobId]
      : selectedJobs.filter((id) => id !== jobId);
    setSelectedJobs(updatedSelectedJobs);
  };

  const handleCreateSeason = async () => {
    const flatJobs = [].concat(...selectedJobs);
    try {
      const response = await axios.post(
        '/api/season/create',
        {
          name,
          beginDate,
          endDate,
          jobs: flatJobs,
          phases: JSON.stringify(phasesData),
          applicationFormsID: selectedApplicationForms,
        },
      );
      if (response.status === 201) {
        openNotificationWithIcon('success', t('season.create_success'));
        setName('');
        setBeginDate(null);
        setEndDate(null);
        setSelectedJobs([]);
        navigate('/seasons');
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        switch (status) {
          case 406:
            openNotificationWithIcon('error', t('season.create_406'));
            break;
          case 401:
            openNotificationWithIcon('error', t('auth.need_login'));
            navigate('/login');
            break;
          case 400:
            openNotificationWithIcon('error', t('season.create_400'));
            break;
          case 403:
            openNotificationWithIcon('error', t('auth.not_authorized'));
            break;
          default:
            openNotificationWithIcon('error', error.response.data.message || t('role.create_error'));
        }
      } else {
        openNotificationWithIcon('error', t('season.create_error'));
      }
    }
  };

  const handleContinue = () => {
    // Check if all fields are filled
    if (!name || !beginDate || !endDate || selectedJobs.length === 0) {
      openNotificationWithIcon('error', t('season.fill_all_fields'));
      return;
    }

    // Check if season dates are valid
    if (beginDate.isAfter(endDate)) {
      openNotificationWithIcon('error', t('seasons.invalid_season_dates'));
      return;
    }
    setOpen(false);
    setCurrentPage(currentPage + 1);
  };

  const handleWrap = () => {
    if (phasesData.length === 0) {
      openNotificationWithIcon('error', t('season.at_least_one_phase'));
      return;
    }
    setCurrentPage(-1);
    setWrapPage(true);
  };

  // eslint-disable-next-line arrow-body-style
  const disabledDate = (current) => {
    // Can not select days before today
    return current < dayjs().startOf('day');
  };

  useEffect(() => {
    axios.get(`/api/settings/company/${companyID}/logos`)
      .then((response) => {
        setDarkLogo(response.data.companyLogoDark);
      })
      .catch((error) => {
        console.error('Error fetching logos:', error);
        openNotificationWithIcon('error', t('settings.logo_fetch_failed'));
      });
  }, []);

  const showModal = () => {
    setOpen(true);
  };

  return (
    <div>
      <Row className="create-season-container">
        <Navbar />
        <NavbarMobile />
        <Col xs={24} sm={24} md={24} className="create-season-page-col2">
          <img src={darkLogo} className="create-season-header-logo" alt="logo" />
          <form onSubmit={handleCreateSeason} className="create-season-form">
            {currentPage === 0 && (
            <div className="create-season-intro-page">
              <h1 className="create-season-title">{t('season.title')}</h1>
              <div className="create-season-name-div">
                <p className="create-season-name-label">
                  {t('season.name')}
                  :
                </p>
                <Input
                  className="create-season-name-input"
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  showCount
                  maxLength={50}
                />
              </div>
              <div className="create-season-dates-div">
                <div className="create-season-begin-date-div">
                  <p className="create-season-begin-date-label">
                    {t('season.date')}
                    :
                  </p>
                  <ConfigProvider locale={datePickerLanguage}>
                    <RangePicker
                      className="create-season-date-range-input"
                      value={[beginDate, endDate]}
                      placeholder={[t('season.start_date'), t('season.end_date')]}
                      onChange={(dates) => {
                        if (dates) {
                          const [start, end] = dates;
                          setBeginDate(start);
                          setEndDate(end);
                        } else {
                          setBeginDate(null);
                          setEndDate(null);
                        }
                      }}
                      disabledDate={disabledDate}
                    />
                  </ConfigProvider>
                </div>
              </div>
              <div className="create-season-departments-div">
                <p className="create-season-departments-label">
                  {t('season.departments')}
                </p>
                <Collapse accordion ghost>
                  {departmentOptions.map((department) => (
                    <Panel header={department.label} key={department.value}>
                      <div className="create-season-departments-grid">
                        {jobs
                          .filter((job) => job.departmentID === department.value).length === 0 ? (
                            <p className="create-season-department-no-job">{t('season.department_no_jobs')}</p>
                          ) : (jobs
                            .filter((job) => job.departmentID === department.value)
                            .map((job) => (
                              <Checkbox
                                key={job.value}
                                onChange={(e) => handleCheckboxChange(job.value, e.target.checked)}
                                checked={selectedJobs.includes(job.value)}
                              >
                                {job.name}
                              </Checkbox>
                            ))
                          )}
                      </div>
                    </Panel>
                  ))}
                </Collapse>
              </div>
              <div className="create-season-forms-select-div">
                <p className="create-season-application-forms-label">
                  {t('season.forms_select')}
                  :
                </p>
                <Select
                  showSearch
                  className="create-season-forms-select"
                  placeholder={t('season.select')}
                  optionFilterProp="children"
                  filterOption={filterOption}
                  options={applicationForms.map((form) => ({ label: form.name, value: form.id }))}
                  onChange={handleChange}
                  value={selectedApplicationForms}
                />
              </div>
              <Button className="create-season-button" onClick={showModal}>
                {t('season.continue')}
                <IoIosArrowRoundForward />
              </Button>
              <ConfirmAction
                open={open}
                setOpen={setOpen}
                onConfirm={handleContinue}
              />
            </div>
            )}
            {currentPage > 0 && (
              <div>
                <CreatePhase
                  phasesData={phasesData}
                  setPhasesData={setPhasesData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  seasonStartDate={beginDate}
                  seasonEndDate={endDate}
                />
                <Button className="create-season-button" onClick={showModal}>
                  {t('season.save')}
                  <IoIosArrowRoundForward />
                </Button>
                <ConfirmAction
                  open={open}
                  setOpen={setOpen}
                  onConfirm={handleWrap}
                />
              </div>
            )}
            {wrapPage && (
              <div>
                <CreateSeasonWrap phasesData={phasesData} />
                <Button className="create-season-button" type="button" onClick={showModal}>
                  {t('season.save')}
                </Button>
                <ConfirmAction
                  open={open}
                  setOpen={setOpen}
                  onConfirm={handleCreateSeason}
                />
              </div>
            )}
          </form>
        </Col>
      </Row>
    </div>
  );
}

export default CreateSeason;
