import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Row, Table, Input, notification,
} from 'antd';
import axios from '../axios';
import CreateDepartment from '../components/CreateDepartment';
import Navbar from '../components/Navbar';
import NavbarMobile from '../components/NavbarMobile';
import ConfirmDelete from '../components/ConfirmDelete';
import DeleteConflicts from '../components/DeleteConflicts';
import EditDepartment from '../components/EditDepartment';
import DeleteIcon from '../images/delete_icon.svg';
import EditIcon from '../images/edit_icon.svg';
import languageStore from '../stores/languageStore';
import '../css/departments.css';

import { AbilityContext } from '../Abilities/Can';

function Departments() {
  const companyID = 1;
  const [t] = useTranslation('global');
  const [LightLogo, setLightLogo] = useState('');

  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';

  const ability = useContext(AbilityContext);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const locale = {
    emptyText: t('departments.empty_list'),
    triggerDesc: t('table.sort_descend'),
    triggerAsc: t('table.sort_ascend'),
    cancelSort: t('table.cancel_sort'),
  };

  const [originalData, setOriginalData] = useState('');
  const [filteredData, setFilteredData] = useState('');
  const [searchText, setSearchText] = useState('');
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });

  const [hoveredRowKey, setHoveredRowKey] = useState(null);

  // Confirm dialog delete
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConflicts, setShowConflicts] = useState(false);
  const [departmentIdToDelete, setDepartmentIdToDelete] = useState(null);
  const [departmentNameToDelete, setDepartmentNameToDelete] = useState('');
  const [departmentConflicts, setDepartmentConflicts] = useState('[]');
  const [showEdit, setShowEdit] = useState(false);
  const [editDepartmentId, setEditDepartmentId] = useState(null);

  // Open Department page when the table row or the edit button is pressed
  const handleRowClick = (record) => {
    const departmentId = record;
    console.log(departmentId);
    // navigate(`/department/${departmentId}`);
  };

  const handleEditClick = (record) => {
    setEditDepartmentId(record);
    setShowEdit(true);
  };

  // Display delete confirmation
  const showDeleteConfirm = async (departmentId, departmentName) => {
    setDepartmentIdToDelete(departmentId);
    setDepartmentNameToDelete(departmentName);

    // Check if the department can be deleted
    await axios.get(`api/department/${departmentId}/can-delete`)
      .then((response) => {
        if (response.data.canDelete) {
          // Show delete confirmation if department can be deleted
          setShowConfirmation(true);
        } else {
          // There are conflicts that need to be fixed before deleting department
          setDepartmentConflicts(JSON.stringify(response.data.conflicts));
          setShowConflicts(true);
        }
      })
      .catch((error) => {
        console.error('Error fetching Departments:', error);
      });
  };

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: 'Success',
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: 'Error',
        description: message,
      });
    }
  };

  const fetchDepartments = async () => {
    setLoading(true);
    await axios.get('/api/department')
      .then((response) => {
        const parsedTableData = response.data;
        parsedTableData.forEach((department, index) => {
          parsedTableData[index].name = department.name[selectedLanguage];
          parsedTableData[index].description = department.description[selectedLanguage];
        });
        setOriginalData(parsedTableData);
        setFilteredData(parsedTableData);

        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: response.data.length,
          },
        });
      })
      .catch((error) => {
        console.error('Error fetching Departments:', error);
      });

    setLoading(false);
  };

  useEffect(() => {
    fetchDepartments();
  }, [languageStore.getState().currentLanguage]);

  // Delete department
  const deleteRow = async () => {
    if (departmentIdToDelete) {
      try {
        await axios.delete(`/api/department/${departmentIdToDelete}`);
        // Update Table
        fetchDepartments();
        // Show success
        openNotificationWithIcon('success', departmentNameToDelete + t('confirmModal.success_delete'));
      } catch (error) {
        console.error('Error deleting department:', error);
        openNotificationWithIcon('error', t('confirmModal.error_delete') + departmentNameToDelete);
      }
    }
    setDepartmentIdToDelete(null);
    setDepartmentNameToDelete('');
  };

  const columns = [
    {
      title: t('departments.department_column_title'),
      dataIndex: 'name',
      key: 'department',
      sorter: (a, b) => a.name.localeCompare(b.name), // Sorting alphabetically
      width: '40%',
    },
    {
      title: t('departments.number_of_jobs_column_title'),
      dataIndex: 'numberOfJobs',
      key: 'numberOfJobs',
      sorter: (a, b) => a.numberOfJobs - b.numberOfJobs, // Sorting alphabetically
      width: '27.5%',
    },
    {
      title: t('departments.number_of_employees_column_title'),
      dataIndex: 'numberOfEmployees',
      key: 'numberOfEmployees',
      sorter: (a, b) => a.numberOfEmployees - b.numberOfEmployees, // Sorting alphabetically
      width: '27.5%',
    },
  ];

  if (ability.can('delete', 'Departments') || ability.can('update', 'Departments')) {
    columns.push({
      title: '',
      key: 'actions',
      width: '65px',
      className: 'icons-column',
      render: (text, record) => (
        <Row>
          {/* Edit icon to open Department page */}
          {ability.can('update', 'Departments') && (
            <button
              type="button"
              className={hoveredRowKey === record.id ? 'table-icon show' : 'table-icon'}
              onClick={() => handleEditClick(record.id)}
            >
              <img src={EditIcon} alt="Edit" />
            </button>
          )}
          {/* Bin icon to delete row */}
          {ability.can('delete', 'Departments') && (
            <button
              type="button"
              className={hoveredRowKey === record.id ? 'table-icon show' : 'table-icon'}
              onClick={(e) => {
                e.stopPropagation();
                showDeleteConfirm(record.id, record.name);
              }}
            >
              <img src={DeleteIcon} alt="Delete" />
            </button>
          )}
        </Row>
      ),
    });
  }

  useEffect(() => {
    axios.get(`/api/settings/company/${companyID}/logos`)
      .then((response) => {
        setLightLogo(response.data.companyLogoLight);
      })
      .catch((error) => {
        console.error('Error fetching logos:', error);
        openNotificationWithIcon('error', t('settings.logo_fetch_failed'));
      });
  }, []);

  // Get Departments data from the API
  useEffect(() => {
    fetchDepartments();
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setOriginalData([]);
    }
  };

  // Search bar implementation
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filtered = value === ''
      ? originalData
      : originalData.filter((item) => item.name.toLowerCase().includes(value));

    setFilteredData(filtered);
  };

  return (
    <div>
      <Row>
        <NavbarMobile />
        <Col xs={0} sm={0} md={6} className="roles-page-col1">
          <Navbar />
          <img src={LightLogo} alt="logo" className="roles-white-logo" />
        </Col>
        <Col xs={24} sm={24} md={18} className="roles-page-col2">
          <h1 className="roles-title">{t('departments.title')}</h1>
          <Input
            placeholder={t('departments.search_department')}
            className="search-role search-role-desktop search-role-mobile"
            value={searchText}
            onChange={handleSearch}
          />
          <Table
            locale={locale}
            columns={columns}
            dataSource={filteredData}
            className="roles-table"
            pagination={tableParams.pagination}
            loading={loading}
            onChange={handleTableChange}
            onRow={(record) => ({
              onClick: () => handleRowClick(record.id),
              onMouseEnter: () => setHoveredRowKey(record.id),
              onMouseLeave: () => setHoveredRowKey(null),
            })}
            scroll={{
              x: 500, // Ajust as needed
            }}
          />
          <ConfirmDelete
            open={showConfirmation}
            setOpen={setShowConfirmation}
            onConfirm={deleteRow} // Pass the confirmation handler function
            name={departmentNameToDelete}
          />
          <DeleteConflicts
            open={showConflicts}
            setOpen={setShowConflicts}
            name={departmentNameToDelete}
            conflicts={departmentConflicts}
          />
          <button type="button" className="new-department-btn new-department-btn-mobile" onClick={showModal}>
            {t('departments.create_department')}
            <i className="plus-icon">+</i>
          </button>
          <CreateDepartment
            open={open}
            setOpen={setOpen}
            fetchDepartments={fetchDepartments}
          />
          <EditDepartment
            open={showEdit}
            setOpen={setShowEdit}
            departmentId={editDepartmentId}
            fetchDepartments={fetchDepartments}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Departments;
