import React, { useState, useEffect } from 'react';
import { ConfigProvider } from 'antd';
import enUS from 'antd/locale/en_US';
import ptPT from 'antd/locale/pt_PT';
import languageStore from './stores/languageStore';
import axios from './axios';

import AppRouter from './AppRouter';

function App() {
  const [colors, setColors] = useState({
    primaryColor: '#001635',
    secondaryColor: '#FEFBFB',
  });

  const [font, setFont] = useState({
    font: 'Montserrat',
  });

  const [dataLoadedColors, setDataLoadedColors] = useState(false);
  const [dataLoadedFont, setDataLoadedFont] = useState(false);
  const companyID = 1;

  const locale = languageStore.getState().currentLanguage === 'pt' ? ptPT : enUS;

  useEffect(() => {
    // Fetch colors from the API
    axios.get(
      `/api/settings/colors/${companyID}`,
    )
      .then((response) => {
        setColors(response.data);
        setDataLoadedColors(true);
      })
      .catch((error) => {
        console.error('Error fetching colors:', error);
      });
  }, []); // Empty dependency array to fetch colors only on component mount

  useEffect(() => {
    // Fetch font from the API
    axios.get(
      `/api/settings/font/${companyID}`,
    )
      .then((response) => {
        setFont(response.data);
        setDataLoadedFont(true);
      })
      .catch((error) => {
        console.error('Error fetching colors:', error);
      });
  }, []); // Empty dependency array to fetch colors only on component mount

  if (dataLoadedColors && dataLoadedFont) {
    return (
      <ConfigProvider
        locale={locale}
        theme={{
          token: {
            colorPrimary: colors.primaryColor,
            fontFamily: font.font,
          },
        }}
      >
        <div className="App">
          <style>
            {`:root {
              --primary-color: ${colors.primaryColor};
              --secondary-color: ${colors.secondaryColor};
              --font: ${font.font};
            }`}
          </style>
          <AppRouter />
        </div>
      </ConfigProvider>
    );
  }
}

export default App;
