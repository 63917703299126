import React from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import ChangePasswordForm from '../components/authentication/ModifyPasswordForm';
import Footer from '../components/Footer';
import LogoWhite from '../images/logo_contorno_branco_2.svg';
import LogoBlack from '../images/logo_preenchimento_preto_2.svg';
import '../css/App.css';
import RecoverPasswordHeader from '../components/RecoverPasswordHeader';

function ModifyPassword() {
  const [t] = useTranslation('global');

  return (
    <div>
      <div className="login">
        <Row>
          <Col xs={24} sm={24} md={9} className="logo-col">
            <RecoverPasswordHeader />
            <img src={LogoBlack} alt="logo" className="header-image-auth header-image" id="logo-black" />
            <h1 className="header-title">{t('auth.welcome')}</h1>
            <img src={LogoWhite} alt="logo" className="header-image-auth header-image" id="logo-white" />
          </Col>
          <Col xs={24} sm={24} md={15}>
            <ChangePasswordForm />
          </Col>
        </Row>
      </div>
      <div className="footer-auth">
        <Footer />
      </div>
    </div>
  );
}

export default ModifyPassword;
