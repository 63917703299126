import React, { useState } from 'react';
import { Input, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';

function VerificationForm() {
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState('');
  const [t] = useTranslation('global');

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  const handleVerificationCode = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        '/api/user/verify',
        { verificationCode },
      );
      if (response.status === 200) {
        const responseData = response.data; // Use response.data directly
        if (responseData.redirectTo) {
          // Redirect the user to the specified route
          window.location.href = responseData.redirectTo;
          return; // Make sure to exit the function after redirecting
        }
        openNotificationWithIcon('success', t('2fa.success'));
        navigate('/');
      } else {
        openNotificationWithIcon('error', t('2fa.fail'));
      }
    } catch (error) {
      console.error(error);
      openNotificationWithIcon('error', t('2fa.fail'));
    }
  };
  return (
    <div>
      <h1>Enter Verification Code</h1>
      <form onSubmit={handleVerificationCode}>
        <Input
          type="text"
          id="verificationCode"
          name="verificationCode"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          required
        />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default VerificationForm;
