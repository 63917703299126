import React from 'react';
import PropTypes from 'prop-types';
import { IoIosArrowForward } from 'react-icons/io';

function CustomRightArrow({ onClick }) {
  return (
    <button className="custom-arrow-right" aria-label="Save" type="button" onClick={() => onClick()}>
      <IoIosArrowForward />
    </button>
  );
}

CustomRightArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CustomRightArrow;
