import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Row, Table, Input, notification,
} from 'antd';
import axios from '../axios';
import CreateJob from '../components/CreateJob';
import Navbar from '../components/Navbar';
import NavbarMobile from '../components/NavbarMobile';
import ConfirmDelete from '../components/ConfirmDelete';
import DeleteConflicts from '../components/DeleteConflicts';
import DeleteIcon from '../images/delete_icon.svg';
import EditIcon from '../images/edit_icon.svg';
import EditJob from '../components/EditJob';
import languageStore from '../stores/languageStore';
import '../css/jobs.css';

import { AbilityContext } from '../Abilities/Can';

function Jobs() {
  const companyID = 1;
  const [t] = useTranslation('global');
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const ability = useContext(AbilityContext);
  const [LightLogo, setLightLogo] = useState('');

  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';

  const [hoveredRowKey, setHoveredRowKey] = useState(null);

  // Confirm dialog delete
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConflicts, setShowConflicts] = useState(false);
  const [jobIdToDelete, setJobIdToDelete] = useState(null);
  const [jobNameToDelete, setJobNameToDelete] = useState('');
  const [jobConflicts, setJobsConflicts] = useState('[]');
  const [showEdit, setShowEdit] = useState(false);
  const [editJobId, setEditJobId] = useState(null);

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  const showModal = () => {
    setOpen(true);
  };

  const locale = {
    emptyText: t('jobs.empty_list'),
    triggerDesc: t('table.sort_descend'),
    triggerAsc: t('table.sort_ascend'),
    cancelSort: t('table.cancel_sort'),
  };

  const [originalData, setOriginalData] = useState('');
  const [filteredData, setFilteredData] = useState('');
  const [searchText, setSearchText] = useState('');
  const [departmentFilters, setDepartmentFilters] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });

  // Format date to DD-MM-YYYY
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
  };

  // Custom sorter function to sort formatted dates
  const dateSorter = (a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateA - dateB;
  };

  useEffect(() => {
    axios.get(`/api/settings/company/${companyID}/logos`)
      .then((response) => {
        setLightLogo(response.data.companyLogoLight);
      })
      .catch((error) => {
        console.error('Error fetching logos:', error);
        openNotificationWithIcon('error', t('settings.logo_fetch_failed'));
      });
  }, []);

  const fetchJobs = async () => {
    setLoading(true);
    await axios.get('/api/job')
      .then((response) => {
        const parsedTableData = response.data;
        parsedTableData.forEach((job, index) => {
          parsedTableData[index].Department.name = job.Department.name[selectedLanguage];
          parsedTableData[index].name = job.name[selectedLanguage];
          parsedTableData[index].shortDescription = job.shortDescription[selectedLanguage];
        });
        setOriginalData(parsedTableData);
        setFilteredData(parsedTableData);

        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: parsedTableData.length,
          },
        });

        // Generate Filters
        const departmentNames = parsedTableData.map((job) => job.Department.name);
        const uniqueDepartments = [...new Set(departmentNames)];
        setDepartmentFilters(uniqueDepartments);
      })
      .catch((error) => {
        console.error('Error fetching Jobs:', error);
      });

    setLoading(false);
  };

  useEffect(() => {
    fetchJobs();
  }, [languageStore.getState().currentLanguage]);

  // Open Job page when the table row or the edit button is pressed
  const handleRowClick = (record) => {
    // TODO: create Job page
    const jobId = record;
    console.log(jobId);
    // navigate(`/role/${jobId}`);
  };

  const handleEditClick = (record) => {
    setEditJobId(record);
    setShowEdit(true);
  };

  // Display delete confirmation
  const showDeleteConfirm = async (jobId, jobName) => {
    setJobIdToDelete(jobId);
    setJobNameToDelete(jobName);

    // Check if Job can be deleted
    await axios.get(`api/job/${jobId}/can-delete`)
      .then((response) => {
        if (response.data.canDelete) {
          // Show delete confirmation if job can be deleted
          setShowConfirmation(true);
        } else {
          // There are conflicts that need to be fixed before deleting job
          setJobsConflicts(JSON.stringify(response.data.conflicts));
          setShowConflicts(true);
        }
      })
      .catch((error) => {
        console.error('Error fetching Jobs:', error);
      });
  };

  // Delete Job
  const deleteRow = async () => {
    if (jobIdToDelete) {
      try {
        await axios.delete(`/api/job/${jobIdToDelete}`);
        // Update Table
        fetchJobs();
        // Show success
        openNotificationWithIcon('success', jobNameToDelete + t('confirmModal.success_delete'));
      } catch (error) {
        console.error('Error deleting role:', error);
        openNotificationWithIcon('error', t('confirmModal.error_delete') + jobNameToDelete);
      }
    }
    setJobIdToDelete(null);
    setJobNameToDelete('');
  };

  const columns = [
    {
      title: t('jobs.job_column_title'),
      dataIndex: 'name',
      key: 'Job',
      sorter: (a, b) => a.name.localeCompare(b.name), // Sorting alphabetically
      width: '20%',
    },
    {
      title: t('jobs.description_column_title'),
      dataIndex: 'shortDescription',
      key: 'shortDescription',
      width: '30%',
    },
    {
      title: t('jobs.department_column_title'),
      dataIndex: ['Department', 'name'],
      key: 'department',
      width: '15%',
      filters: departmentFilters.map((d) => ({ text: d, value: d })),
      onFilter: (value, record) => record.Department.name === value,
      render: (text) => text,
    },
    {
      title: t('jobs.begin_date_column_title'),
      dataIndex: 'beginDate',
      key: 'beginDate',
      width: '15%',
      render: (beginDate) => formatDate(beginDate),
      sorter: (a, b) => dateSorter(a.beginDate, b.beginDate),
    },
    {
      title: t('jobs.end_date_column_title'),
      dataIndex: 'endDate',
      key: 'endDate',
      width: '15%',
      render: (endDate) => formatDate(endDate),
      sorter: (a, b) => dateSorter(a.endDate, b.endDate),
    },
  ];

  if (ability.can('delete', 'Jobs') || ability.can('update', 'Jobs')) {
    columns.push({
      title: '',
      key: 'actions',
      width: '65px',
      className: 'icons-column',
      render: (text, record) => (
        <Row>
          {/* Edit icon to open Job page */}
          {ability.can('update', 'Jobs') && (
            <button
              type="button"
              className={hoveredRowKey === record.id ? 'table-icon show' : 'table-icon'}
              onClick={() => handleEditClick(record.id)}
            >
              <img src={EditIcon} alt="Edit" />
            </button>
          )}
          {/* Bin icon to delete row */}
          {ability.can('delete', 'Jobs') && (
            <button
              type="button"
              className={hoveredRowKey === record.id ? 'table-icon show' : 'table-icon'}
              onClick={(e) => {
                e.stopPropagation();
                showDeleteConfirm(record.id, record.name);
              }}
            >
              <img src={DeleteIcon} alt="Delete" />
            </button>
          )}
        </Row>
      ),
    });
  }

  // Get Jobs data from the API
  useEffect(() => {
    fetchJobs();
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setOriginalData([]);
    }
  };

  // Search bar implementation
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filtered = value === ''
      ? originalData
      : originalData.filter((item) => item.name.toLowerCase().includes(value));

    setFilteredData(filtered);
  };

  return (
    <div>
      <Row>
        <Navbar />
        <NavbarMobile />
        <Col xs={24} sm={24} md={6} className="jobs-page-col1">
          <img src={LightLogo} alt="logo" className="jobs-white-logo" />
        </Col>
        <Col xs={24} sm={24} md={18} className="jobs-page-col2">
          <h1 className="jobs-title">{t('jobs.title')}</h1>
          <Input
            placeholder={t('jobs.search_job')}
            className="search-job search-job-mobile"
            value={searchText}
            onChange={handleSearch}
          />
          <Table
            locale={locale}
            columns={columns}
            dataSource={filteredData}
            className="jobs-table"
            pagination={tableParams.pagination}
            loading={loading}
            onChange={handleTableChange}
            onRow={(record) => ({
              onClick: () => handleRowClick(record.id),
              onMouseEnter: () => setHoveredRowKey(record.id),
              onMouseLeave: () => setHoveredRowKey(null),
            })}
            scroll={{
              x: 500, // Ajust as needed
            }}
          />
          <ConfirmDelete
            open={showConfirmation}
            setOpen={setShowConfirmation}
            onConfirm={deleteRow} // Pass the confirmation handler function
            name={jobNameToDelete}
          />
          <DeleteConflicts
            open={showConflicts}
            setOpen={setShowConflicts}
            name={jobNameToDelete}
            conflicts={jobConflicts}
          />
          <button type="button" className="new-job-btn new-job-btn-mobile" onClick={showModal}>
            {t('jobs.create_job')}
            <i className="plus-icon">+</i>
          </button>
          <CreateJob
            open={open}
            setOpen={setOpen}
            fetchJobs={fetchJobs}
          />
          <EditJob
            open={showEdit}
            setOpen={setShowEdit}
            jobId={editJobId}
            fetchJobs={fetchJobs}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Jobs;
