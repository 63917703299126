import React from 'react';
import PropTypes from 'prop-types';

function ExternalAuthButton({ linkProp, iconProp, labelProp }) {
  return (
    <a href={linkProp}>
      <div className="external-auth-button">
        <img src={iconProp} alt={labelProp} />
        <button className="external-auth-button-text" type="button">{labelProp}</button>
      </div>
    </a>
  );
}

ExternalAuthButton.propTypes = {
  linkProp: PropTypes.func.isRequired,
  iconProp: PropTypes.string.isRequired,
  labelProp: PropTypes.string.isRequired,
};

export default ExternalAuthButton;
