import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import '../css/confirmAction.css';

function ConfirmDelete({
  open, setOpen, onConfirm, name,
}) {
  const [t] = useTranslation('global');

  const handleCancel = () => {
    setOpen(false);
  };

  const handleOk = () => {
    onConfirm();
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        title={[
          <p className="confirm-action-title">
            {t('confirmModal.delete')}
            <strong>{name}</strong>
          </p>,
        ]}
        centered
        className="confirm-action-modal"
        onCancel={handleCancel}
        width="550px"
        footer={[
          <button className="confirm-action-button-ok" key="submit" type="button" onClick={handleOk}>
            {t('confirmModal.ok')}
          </button>,
          <button className="confirm-action-button-cancel" key="back" type="button" onClick={handleCancel}>
            {t('confirmModal.cancel')}
          </button>,
        ]}
      />
    </div>
  );
}

ConfirmDelete.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired, // Add onConfirm prop type
  name: PropTypes.string.isRequired,
};

export default ConfirmDelete;
