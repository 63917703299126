import React, { useState, useEffect } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Input,
  Collapse,
  Select,
  Button,
  Checkbox,
  DatePicker,
  notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { FaRegTrashAlt } from 'react-icons/fa';
import { PhoneInput } from 'react-international-phone';
import { IoAddCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { LuPencil } from 'react-icons/lu';
import axios from '../axios';
import Navbar from '../components/Navbar';
import NavbarMobile from '../components/NavbarMobile';
import ConfirmAction from '../components/ConfirmAction';
import languageStore from '../stores/languageStore';
import '../css/createApplicationForms.css';

const { Panel } = Collapse;

function CreateApplicationForms() {
  const [t] = useTranslation('global');
  const companyID = 1;
  const [darkLogo, setDarkLogo] = useState('');

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  useEffect(() => {
    axios.get(`/api/settings/company/${companyID}/logos`)
      .then((response) => {
        setDarkLogo(response.data.companyLogoDark);
      })
      .catch((error) => {
        console.error('Error fetching logos:', error);
        openNotificationWithIcon('error', t('settings.logo_fetch_failed'));
      });
  }, []);

  const initialApplicationFields = [
    {
      id: 1,
      namePT: 'Nome',
      nameEN: 'Name',
      type: 'text',
      placeholder: t('applicationForm.text'),
    },
    {
      id: 2,
      namePT: 'Data de nascimento',
      nameEN: 'Birth date',
      type: 'date',
      placeholder: t('applicationForm.date_format'),
    },
    {
      id: 3,
      namePT: 'Contacto',
      nameEN: 'Contact',
      type: 'contact',
      placeholder: t('applicationForm.number'),
    },
    {
      id: 4,
      namePT: 'Curso',
      nameEN: 'Course',
      type: 'text',
      placeholder: t('applicationForm.text'),
    },
    {
      id: 5,
      namePT: 'Email',
      nameEN: 'Email',
      type: 'text',
      placeholder: t('applicationForm.text'),
    },
    {
      id: 6,
      namePT: 'CV',
      nameEN: 'CV',
      type: 'file',
      placeholder: t('applicationForm.choose_file'),
    },
  ];

  const [formsName, setFormsName] = useState('');

  const [applicationFields, setApplicationFields] = useState([
    ...initialApplicationFields,
  ]);
  const [selectedInputType, setSelectedInputType] = useState(null);
  const [newInputNamePT, setNewInputNamePT] = useState('');
  const [newInputNameEN, setNewInputNameEN] = useState('');
  const [editingFieldId, setEditingFieldId] = useState(null);

  const [textLimitSize, setTextLimitSize] = useState('');

  const [fileFormat, setFileFormat] = useState([]);

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleInputChange = (value) => {
    setSelectedInputType(value);
  };

  const getPlaceholder = (type) => {
    if (type === 'date') return t('applicationForm.date_format');
    if (type === 'number') return t('applicationForm.number');
    if (type === 'file') return t('applicationForm.file');
    return 'Texto';
  };

  const getType = (type) => {
    if (type === 'date') return 'date';
    if (type === 'number') return 'number';
    if (type === 'file') return 'file';
    if (type === 'contact') return 'contact';
    return 'text';
  };

  const handleAddField = () => {
    if (newInputNamePT && newInputNameEN && selectedInputType) {
      const newField = {
        id: applicationFields.length + 1,
        namePT: newInputNamePT,
        nameEN: newInputNameEN,
        type: getType(selectedInputType),
        placeholder: getPlaceholder(selectedInputType),
        textLimitSize,
        fileFormat,
      };

      setApplicationFields([...applicationFields, newField]);
      setNewInputNamePT('');
      setNewInputNameEN('');
      setSelectedInputType(null);
      setTextLimitSize('');
      setFileFormat([]);
    }
  };

  const handleEditClick = (fieldId, fieldNamePT, fieldNameEN) => {
    setEditingFieldId(fieldId);
    setNewInputNamePT(fieldNamePT);
    setNewInputNameEN(fieldNameEN);
  };

  const handleCancelEdit = () => {
    setEditingFieldId(null);
    setNewInputNamePT('');
    setNewInputNameEN('');
  };

  const handleEditCreatedFieldName = (id) => {
    const updatedFields = applicationFields.map((field) => {
      if (field.id === id) {
        return {
          ...field,
          namePT: newInputNamePT,
          nameEN: newInputNameEN,
        };
      }
      return field;
    });
    setApplicationFields(updatedFields);
    setNewInputNamePT('');
    setNewInputNameEN('');
    setEditingFieldId(null);
  };

  const handleDeleteField = (id) => {
    const updatedFields = applicationFields.filter((field) => field.id !== id);
    setApplicationFields(updatedFields);
  };

  const handleCheckboxTechnicalProblemFormat = (format, checked) => {
    const updatedFormats = checked
      ? [...fileFormat, format]
      : fileFormat.filter((val) => val !== format);
    setFileFormat(updatedFormats);
  };

  const renderPreviewInput = () => {
    switch (selectedInputType) {
      case 'date':
        return (
          <div className="app-forms-add-field-preview-section">
            <div className="app-forms-preview-section-header">
              <p className="app-forms-label">
                {selectedLanguage === 'pt' ? newInputNamePT : newInputNameEN}
              </p>
            </div>
            <DatePicker
              format={selectedLanguage === 'pt' ? 'DD-MM-YYYY' : 'YYYY-MM-DD'}
              placeholder={t('applicationForm.date_format')}
            />
          </div>
        );
      case 'file':
        return (
          <div className="app-forms-add-field-preview-section-file">
            <div className="add-input-file-format-div">
              <p className="add-input-file-format">
                {t('applicationForm.file_format')}
                :
              </p>
              <div className="phase-technical-problem-format-checkboxes">
                <Checkbox onChange={(e) => handleCheckboxTechnicalProblemFormat('pdf', e.target.checked)}>PDF</Checkbox>
                <Checkbox onChange={(e) => handleCheckboxTechnicalProblemFormat('jpg', e.target.checked)}>JPG</Checkbox>
                <Checkbox onChange={(e) => handleCheckboxTechnicalProblemFormat('png', e.target.checked)}>PNG</Checkbox>
                <Checkbox onChange={(e) => handleCheckboxTechnicalProblemFormat('other', e.target.checked)}>Outro</Checkbox>
              </div>
            </div>
          </div>
        );
      case 'text':
        return (
          <div className="add-input-file-size-div">
            <p className="add-input-file-size">
              {t('applicationForm.character_limit')}
              :
            </p>
            <Input
              className="add-input-text-size-limit"
              type="number"
              min="0"
              onChange={(e) => setTextLimitSize(e.target.value)}
            />
          </div>
        );
      case 'number':
        return (
          <div className="app-forms-add-field-preview-section">
            <div className="app-forms-preview-section-header">
              <p className="app-forms-label">
                {selectedLanguage === 'pt' ? newInputNamePT : newInputNameEN}
              </p>
            </div>
            <Input
              id="newFieldName"
              className="app-forms-preview-field"
              placeholder={t('applicationForm.number')}
              type="number"
              maxLength={320}
            />
          </div>
        );
      case 'contact':
        return (
          <div className="app-forms-add-field-preview-section">
            <p className="app-forms-label">
              {selectedLanguage === 'pt' ? newInputNamePT : newInputNameEN}
            </p>
            <PhoneInput
              defaultCountry="pt"
              className="app-forms-preview-field-phone"
            />
          </div>
        );
      default:
        return null;
    }
  };

  const handleCreateApplicationForms = async () => {
    try {
      const response = await axios.post(
        '/api/applicationForm/create',
        {
          applicationFields,
          name: formsName,
        },
      );
      if (response.status === 201) {
        openNotificationWithIcon('success', t('applicationForm.create_success'));
        setApplicationFields([...initialApplicationFields]);
        setNewInputNamePT('');
        setNewInputNameEN('');
        setSelectedInputType(null);
        setTextLimitSize('');
        setFileFormat([]);
        setFormsName('');
        navigate('/applicationForms');
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        switch (status) {
          case 401:
            openNotificationWithIcon('error', t('auth.need_login'));
            navigate('/login');
            break;
          case 403:
            openNotificationWithIcon('error', t('auth.not_authorized'));
            break;
          default:
            openNotificationWithIcon('error', error.response.data.message || t('role.create_error'));
        }
      } else {
        openNotificationWithIcon('error', t('season.create_error'));
      }
    }
  };

  const showModal = () => {
    setOpen(true);
  };

  return (
    <div>
      <Row className="create-app-forms-container">
        <Navbar />
        <NavbarMobile />
        <Col xs={24} sm={24} md={24} className="create-app-forms-page-col2">
          <img src={darkLogo} className="create-app-forms-header-logo" alt="logo" />
          <h1 className="create-app-forms-header">{t('applicationForm.title')}</h1>
          <div className="create-app-body">
            <div className="create-app-name">
              <p className="app-forms-name-input-label">
                {t('applicationForm.name')}
                :
              </p>
              <input
                className="app-forms-name-input-field"
                type="text"
                maxLength={320}
                value={formsName}
                onChange={(e) => setFormsName(e.target.value)}
              />
              <LuPencil className="create-app-name-icon" />
            </div>
            <form className="create-app-forms-form" onSubmit={handleCreateApplicationForms}>
              <div className="app-forms-fields-div">
                {applicationFields.map((field) => (
                  <div key={field.id} className="app-forms-input-section">
                    <div className="app-forms-input-section-header">
                      {editingFieldId !== field.id ? (
                        <div className="field-name-container">
                          <p className="app-forms-label">{selectedLanguage === 'pt' ? field.namePT : field.nameEN }</p>
                          <button
                            type="button"
                            className="app-forms-input-edit-btn"
                            aria-label={`Edit ${field}`}
                            onClick={() => handleEditClick(field.id, field.namePT, field.nameEN)}
                          >
                            <LuPencil className="create-app-name-icon" />
                          </button>
                          <button
                            type="button"
                            className="app-forms-input-delete-btn"
                            aria-label={`Delete ${field}`}
                            onClick={() => handleDeleteField(field.id)}
                          >
                            <FaRegTrashAlt />
                          </button>
                        </div>
                      ) : (
                        <div className="app-forms-edit-field">
                          <div className="app-forms-edit-field-names-div">
                            <div className="app-forms-add-field-input-section">
                              <div className="app-forms-input-section-header">
                                <p className="app-forms-edit-label">
                                  {t('applicationForm.field_name_PT')}
                                  :
                                </p>
                              </div>
                              <Input
                                id="newFieldName"
                                className="app-forms-input-field"
                                placeholder={t('applicationForm.text')}
                                value={newInputNamePT}
                                onChange={(e) => setNewInputNamePT(e.target.value)}
                                type="text"
                                maxLength={320}
                              />
                            </div>
                            <div className="app-forms-add-field-input-section">
                              <div className="app-forms-input-section-header">
                                <p className="app-forms-edit-label">
                                  {t('applicationForm.field_name_EN')}
                                  :
                                </p>
                              </div>
                              <Input
                                id="newFieldName"
                                className="app-forms-input-field"
                                placeholder={t('applicationForm.text')}
                                value={newInputNameEN}
                                onChange={(e) => setNewInputNameEN(e.target.value)}
                                type="text"
                                maxLength={320}
                              />
                            </div>
                          </div>
                          <div className="edit-button-container">
                            <Button
                              type="button"
                              onClick={() => handleEditCreatedFieldName(field.id)}
                              className="app-forms-edit-button"
                            >
                              {t('applicationForm.edit')}
                            </Button>
                            <Button
                              type="button"
                              onClick={() => handleCancelEdit()}
                              className="app-forms-edit-button"
                            >
                              {t('applicationForm.cancel')}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                    {field.type === 'date' && (
                      <DatePicker
                        className="app-forms-input-date-input"
                        placeholder={t('applicationForm.date_format')}
                        format={selectedLanguage === 'pt' ? 'DD-MM-YYYY' : 'YYYY-MM-DD'}
                      />
                    )}
                    {field.type === 'file' && (
                      <div className="app-forms-input-file-input">
                        <input type="file" id={`file-input-${field.id}`} className="inputfile" />
                        <label htmlFor={`file-input-${field.id}`}>{t('applicationForm.choose_file')}</label>
                        <UploadOutlined className="upload-icon" />
                      </div>
                    )}
                    {field.type === 'contact' && (
                      <PhoneInput
                        defaultCountry="pt"
                        className="app-forms-preview-field-phone"
                      />
                    )}
                    {field.type !== 'date' && field.type !== 'file' && field.type !== 'contact' && (
                      <Input
                        className="app-forms-input-field"
                        placeholder={field.placeholder}
                        type={field.type}
                        maxLength={320}
                      />
                    )}
                  </div>
                ))}
              </div>
              <Collapse accordion ghost>
                <Panel
                  header={(
                    <div className="app-forms-add-field-header">
                      <p className="app-forms-add-field-label">{t('applicationForm.add_field')}</p>
                      <IoAddCircleOutline />
                    </div>
                  )}
                  showArrow={false}
                >
                  <div className="app-forms-add-field">
                    <div className="app-forms-add-field-names-div">
                      <div className="app-forms-add-field-input-section">
                        <div className="app-forms-input-section-header">
                          <p className="app-forms-label">
                            {t('applicationForm.field_name_PT')}
                            :
                          </p>
                        </div>
                        <Input
                          id="newFieldName"
                          className="app-forms-input-field"
                          placeholder={t('applicationForm.text')}
                          value={newInputNamePT}
                          onChange={(e) => setNewInputNamePT(e.target.value)}
                          type="text"
                          maxLength={320}
                        />
                      </div>
                      <div className="app-forms-add-field-input-section">
                        <div className="app-forms-input-section-header">
                          <p className="app-forms-label">
                            {t('applicationForm.field_name_EN')}
                            :
                          </p>
                        </div>
                        <Input
                          id="newFieldName"
                          className="app-forms-input-field"
                          placeholder={t('applicationForm.text')}
                          value={newInputNameEN}
                          onChange={(e) => setNewInputNameEN(e.target.value)}
                          type="text"
                          maxLength={320}
                        />
                      </div>
                    </div>
                    <div className="app-forms-add-field-type-section">
                      <div className="app-forms-add-field-select-type">
                        <div className="app-forms-input-section-header">
                          <p className="app-forms-label">
                            {t('applicationForm.input_type')}
                            :
                          </p>
                        </div>
                        <Select
                          showSearch
                          placeholder={t('applicationForm.select')}
                          optionFilterProp="children"
                          filterOption={filterOption}
                          options={[
                            {
                              value: 'date',
                              label: t('applicationForm.date'),
                            },
                            {
                              value: 'file',
                              label: t('applicationForm.choose_file'),
                            },
                            {
                              value: 'text',
                              label: t('applicationForm.text'),
                            },
                            {
                              value: 'number',
                              label: t('applicationForm.number'),
                            },
                            {
                              value: 'contact',
                              label: t('applicationForm.contact'),
                            },
                          ]}
                          onChange={handleInputChange}
                          value={selectedInputType}
                          style={{ width: '100%' }}
                        />
                      </div>
                      <div className="app-forms-preview-section">
                        {renderPreviewInput()}
                      </div>
                    </div>
                    <Button type="primary" onClick={handleAddField} className="app-forms-add-button">
                      {t('applicationForm.add')}
                    </Button>
                  </div>
                </Panel>
              </Collapse>
              <Button type="button" onClick={showModal} className="app-forms-submit-button">
                {t('applicationForm.create')}
              </Button>
            </form>
          </div>
        </Col>
      </Row>
      <ConfirmAction
        open={open}
        setOpen={setOpen}
        onConfirm={handleCreateApplicationForms}
      />
    </div>
  );
}

export default CreateApplicationForms;
