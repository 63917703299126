import React from 'react';
import PropTypes from 'prop-types';
import '../css/recruitment.css';

function RecruitmentPhase({ logoSrc, phaseName }) {
  return (
    <div className="recruitment-phase-div">
      <div className="recruitment-phase-logo">
        <img src={logoSrc} alt="Phase Logo" className="recruitment-phase-logo-img" />
      </div>
      <p className="recruitment-phase-name">{phaseName}</p>
    </div>
  );
}

RecruitmentPhase.propTypes = {
  logoSrc: PropTypes.isRequired,
  phaseName: PropTypes.isRequired,
};

export default RecruitmentPhase;
