import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../axios';

function ConfirmAccount() {
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    axios.post(
      `/api/user/confirm/${token}`,
    )
      .then(() => {
        navigate('/login');
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [token, navigate]);

  return <div>A confirmar conta...</div>;
}

export default ConfirmAccount;
