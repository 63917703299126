import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

function PasswordForm(
  {
    design,
    submitted,
    onPasswordChange,
    password,
    onConfirmPasswordChange,
    confirmPassword,
    onRequirementsChange,
  },
) {
  const containerClass = `password-form-container password-form-design-${design}`;
  const [t] = useTranslation('global');

  // Define regular expressions for password requirements
  const minLengthRegex = /.{8,}/;
  const uppercaseRegex = /[A-Z]/;
  const lowercaseRegex = /[a-z]/;
  const numberRegex = /[0-9]/;
  const specialCharRegex = /[^A-Za-z0-9]/;

  const handleCheckRequirements = () => {
    onRequirementsChange(
      password === confirmPassword
      && minLengthRegex.test(password)
      && uppercaseRegex.test(password)
      && lowercaseRegex.test(password)
      && numberRegex.test(password)
      && specialCharRegex.test(password),
    );
  };

  useEffect(() => {
    handleCheckRequirements();
  }, [password, confirmPassword]);

  return (
    <div className={containerClass}>
      <Input.Password
        className="password-form-input"
        placeholder={t('auth.password')}
        value={password}
        onChange={(e) => onPasswordChange(e.target.value)}
        bordered={design === '3'}
        visibilityToggle
      />
      <ul className="password-requirements-list">
        {/* Has 8 characters */}
        <li className={(minLengthRegex.test(password) && 'success password-requirements-item') || (!minLengthRegex.test(password) && submitted && 'danger password-requirements-item') || 'password-requirements-item'}>
          <p>
            {t('auth.min_8_characters')}
            &nbsp;
            {(minLengthRegex.test(password) && <CheckOutlined />)
            || (!minLengthRegex.test(password) && submitted && <CloseOutlined />)}
          </p>
        </li>
        {/* Has one lowercase letter */}
        <li className={(lowercaseRegex.test(password) && 'success password-requirements-item') || (!lowercaseRegex.test(password) && submitted && 'danger password-requirements-item') || 'password-requirements-item'}>
          <p>
            {t('auth.lowercase_character')}
            &nbsp;
            {(lowercaseRegex.test(password) && <CheckOutlined />)
            || (!lowercaseRegex.test(password) && submitted && <CloseOutlined />)}
          </p>
        </li>
        {/* Has one uppercase letter */}
        <li className={(uppercaseRegex.test(password) && 'success password-requirements-item') || (!uppercaseRegex.test(password) && submitted && 'danger password-requirements-item') || 'password-requirements-item'}>
          <p>
            {t('auth.uppercase_character')}
            &nbsp;
            {(uppercaseRegex.test(password) && <CheckOutlined />)
            || (!lowercaseRegex.test(password) && submitted && <CloseOutlined />)}
          </p>
        </li>
        {/* Has one number */}
        <li className={(numberRegex.test(password) && 'success password-requirements-item') || (!numberRegex.test(password) && submitted && 'danger password-requirements-item') || 'password-requirements-item'}>
          <p>
            {t('auth.number')}
            &nbsp;
            {(numberRegex.test(password) && <CheckOutlined />)
            || (!numberRegex.test(password) && submitted && <CloseOutlined />)}
          </p>
        </li>
        {/* Has one special character */}
        <li className={(specialCharRegex.test(password) && 'success password-requirements-item') || (!specialCharRegex.test(password) && submitted && 'danger password-requirements-item') || 'password-requirements-item'}>
          <p>
            {t('auth.special_character')}
            &nbsp;
            {(specialCharRegex.test(password) && <CheckOutlined />)
            || (!specialCharRegex.test(password) && submitted && <CloseOutlined />)}
          </p>
        </li>
      </ul>
      <Input.Password
        className="password-form-input"
        placeholder={t('auth.confirm_password')}
        value={confirmPassword}
        onChange={(e) => onConfirmPasswordChange(e.target.value)}
        bordered={design === '3'}
        visibilityToggle
      />
      <ul className="password-requirements-list">
        <li className={(password === confirmPassword && password !== '' && 'success password-requirements-item') || (((password !== confirmPassword) || confirmPassword === '') && submitted && 'danger password-requirements-item') || 'password-requirements-item'}>
          <p>
            {t('auth.passwords_match')}
            &nbsp;
            {(password === confirmPassword && password !== '' && <CheckOutlined />)
            || (((password !== confirmPassword) || confirmPassword === '') && submitted && <CloseOutlined />)}
          </p>
        </li>
      </ul>
    </div>
  );
}

PasswordForm.propTypes = {
  design: PropTypes.string.isRequired,
  submitted: PropTypes.bool.isRequired,
  onPasswordChange: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  onConfirmPasswordChange: PropTypes.func.isRequired,
  confirmPassword: PropTypes.string.isRequired,
  onRequirementsChange: PropTypes.func.isRequired,
};

export default PasswordForm;
