import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Input, Modal, Button, Select, notification,
} from 'antd';
import { MdDelete } from 'react-icons/md';
import { IoIosAddCircleOutline } from 'react-icons/io';
import PropTypes from 'prop-types';
import axios from '../axios';
import ConfirmAction from './ConfirmAction';
import languageStore from '../stores/languageStore';
import '../css/createUsers.css';

function CreateUsers({
  open, setOpen, fetchUsers,
}) {
  const [t] = useTranslation('global');

  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';

  const [loading, setLoading] = useState(false);
  const [emails, setEmails] = useState(['']); // Array to store multiple email values
  const [role, setRole] = useState('');
  const [roles, setRoles] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const fetchRoles = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/role');
      const rolesData = response.data;
      rolesData.forEach((roleData, index) => {
        rolesData[index].name = roleData.name[selectedLanguage];
        rolesData[index].description = roleData.description[selectedLanguage];
      });
      setRoles(rolesData);
    } catch (error) {
      console.error('Error fetching Roles:', error);
    }
    setLoading(false);
  };

  // Get Roles data from the API
  useEffect(() => {
    fetchRoles();
  }, []);

  const handleAddEmail = () => {
    setEmails([...emails, '']); // Add a new empty email to the array
  };

  const handleRemoveEmail = (index) => {
    const newEmails = [...emails];
    newEmails.splice(index, 1);
    setEmails(newEmails);
  };

  const handleEmailChange = (index, value) => {
    const newEmails = [...emails];
    newEmails[index] = value;
    setEmails(newEmails);
  };

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleCreateUsers = async () => {
    setLoading(true);
    // remove empty strings from the emails array
    const filteredEmails = emails.filter((email) => email !== '');
    if (filteredEmails.length === 0) {
      openNotificationWithIcon('error', t('users.create_empty_email'));
      setLoading(false);
      return;
    }
    if (role === '') {
      openNotificationWithIcon('error', t('users.create_empty_role'));
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post(
        '/api/user/create',
        {
          emails: filteredEmails,
          role,
          companyID: '1',
        },
      );
      if (response.status === 201) {
        openNotificationWithIcon('success', t('users.create_success'));
        setEmails(['']);
        setRole('');
        setLoading(false);
        await fetchUsers();
        setOpen(false);
      }
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        setLoading(false);
        switch (status) {
          case 400:
            openNotificationWithIcon('error', t('users.create_400'));
            break;
          case 401:
            openNotificationWithIcon('error', `${t('users.create_401_start')} ${data.email} ${t('users.create_401_end')}`);
            break;
          default:
            openNotificationWithIcon('error', error.response.data.message || t('users.create_error'));
        }
      } else {
        openNotificationWithIcon('error', t('role.create_error'));
      }
    }
  };

  const handleCreateJobConfirmation = () => {
    handleCreateUsers();
  };

  const showConfirmationModal = () => {
    setShowConfirmation(true);
  };

  return (
    <div>
      <Modal
        open={open}
        title={<h2 className="create-user-title">{t('users.create_user')}</h2>}
        onCancel={handleCancel}
        centered
        footer={null}
        className="create-user-popup"
        width="750px"
      >
        <form className="create-users-form" id="create-users-form" onSubmit={handleCreateUsers}>
          <p className="create-users-email">
            {t('users.email')}
            :
          </p>
          {emails.map((email, index) => (
            <div className="create-users-email-input-div">
              <Input
                type="email"
                value={email}
                maxLength={320}
                onChange={(e) => handleEmailChange(index, e.target.value)}
                className="create-users-email-input-field"
              />
              <button
                type="button"
                onClick={handleAddEmail}
                className="add-email-button"
                aria-label={t('users.add_email')}
              >
                <IoIosAddCircleOutline />
              </button>
              {index > 0 && (
                <button
                  type="button"
                  onClick={() => handleRemoveEmail(index)}
                  className="remove-email-button"
                  aria-label={t('users.remove_email')}
                >
                  <MdDelete />
                </button>
              )}
            </div>
          ))}
          <p className="create-users-roles-title">{t('users.roles')}</p>
          <Select
            className="create-users-role"
            value={role}
            onChange={(currRole) => setRole(currRole)}
            options={roles.map((roleAux) => ({ value: roleAux.id, label: roleAux.name }))}
            optionFilterProp="children"
            filterOption={filterOption}
            showSearch
          />
          <Button className="create-users-button" type="button" loading={loading} onClick={showConfirmationModal}>
            {t('role.create')}
          </Button>
        </form>
        <ConfirmAction
          open={showConfirmation}
          setOpen={setShowConfirmation}
          onConfirm={handleCreateJobConfirmation}
        />
      </Modal>
    </div>
  );
}

CreateUsers.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  fetchUsers: PropTypes.func.isRequired,
};

export default CreateUsers;
