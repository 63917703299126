import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  Col, Row, Table, Input, notification,
} from 'antd';
import axios from '../axios';
import Navbar from '../components/Navbar';
import NavbarMobile from '../components/NavbarMobile';
import ConfirmDelete from '../components/ConfirmDelete';
import DeleteIcon from '../images/delete_icon.svg';
import EditIcon from '../images/edit_icon.svg';
import '../css/seasons.css';

import { AbilityContext } from '../Abilities/Can';

function Seasons() {
  const companyID = 1;
  const [t] = useTranslation('global');
  const [loading, setLoading] = useState(false);
  const ability = useContext(AbilityContext);
  const [LightLogo, setLightLogo] = useState('');

  const navigate = useNavigate();

  const locale = {
    emptyText: t('seasons.empty_list'),
    triggerDesc: t('table.sort_descend'),
    triggerAsc: t('table.sort_ascend'),
    cancelSort: t('table.cancel_sort'),
  };

  const [originalData, setOriginalData] = useState('');
  const [filteredData, setFilteredData] = useState('');
  const [searchText, setSearchText] = useState('');
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });

  const [hoveredRowKey, setHoveredRowKey] = useState(null);

  // Confirm dialog delete
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [seasonIdToDelete, setSeasonIdToDelete] = useState(null);
  const [seasonNameToDelete, setSeasonNameToDelete] = useState('');

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  const handleRowClick = (record) => {
    const seasonId = record;
    navigate(`/season/${seasonId}`);
  };

  useEffect(() => {
    axios.get(`/api/settings/company/${companyID}/logos`)
      .then((response) => {
        setLightLogo(response.data.companyLogoLight);
      })
      .catch((error) => {
        console.error('Error fetching logos:', error);
        openNotificationWithIcon('error', t('settings.logo_fetch_failed'));
      });
  }, []);

  const fetchSeasons = async () => {
    setLoading(true);
    await axios.get('/api/season')
      .then((response) => {
        const parsedTableData = response.data;
        setOriginalData(parsedTableData);
        setFilteredData(parsedTableData);

        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: parsedTableData.length,
          },
        });
      })
      .catch((error) => {
        console.error('Error fetching Seasons:', error);
      });

    setLoading(false);
  };

  // Format date to DD-MM-YYYY
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;
  };

  // Custom sorter function to sort formatted dates
  const dateSorter = (a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateA - dateB;
  };

  // Display delete confirmation
  const showDeleteConfirm = (seasonId, seasonName) => {
    setSeasonIdToDelete(seasonId);
    setSeasonNameToDelete(seasonName);
    setShowConfirmation(true);
  };

  // Delete season
  const deleteRow = async () => {
    if (seasonIdToDelete) {
      try {
        await axios.delete(`/api/season/${seasonIdToDelete}`);
        // Update Table
        fetchSeasons();
        // Show success
        openNotificationWithIcon('success', seasonNameToDelete + t('confirmModal.success_delete'));
      } catch (error) {
        console.error('Error deleting seasons:', error);
        openNotificationWithIcon('error', t('confirmModal.error_delete') + seasonNameToDelete);
      }
    }
    setSeasonIdToDelete(null);
    setSeasonNameToDelete('');
  };

  const columns = [
    {
      title: t('seasons.season_column_title'),
      dataIndex: 'name',
      key: 'Season',
      sorter: (a, b) => a.name.localeCompare(b.name), // Sorting alphabetically
      width: '20%',
    },
    {
      title: t('seasons.begin_date_column_title'),
      dataIndex: 'beginDate',
      key: 'beginDate',
      width: '20%',
      render: (beginDate) => formatDate(beginDate),
      sorter: (a, b) => dateSorter(a.beginDate, b.beginDate),
    },
    {
      title: t('seasons.end_date_column_title'),
      dataIndex: 'endDate',
      key: 'endDate',
      width: '20%',
      render: (endDate) => formatDate(endDate),
      sorter: (a, b) => dateSorter(a.endDate, b.endDate),
    },
    {
      title: t('seasons.state'),
      dataIndex: 'state',
      key: 'state',
      width: '20%',
      render: (state) => state,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: t('seasons.number_of_candidates'),
      dataIndex: 'numberOfCandidates',
      key: 'numberOfCandidates',
      width: '15%',
      render: (numberOfCandidates) => numberOfCandidates,
      sorter: (a, b) => a.numberOfCandidates - b.numberOfCandidates,
    },
  ];

  if (ability.can('delete', 'Seasons') || ability.can('update', 'Seasons')) {
    columns.push({
      title: '',
      key: 'actions',
      width: '65px',
      className: 'icons-column',
      render: (text, record) => (
        <Row>
          {/* Edit icon to open Season page */}
          {ability.can('update', 'Seasons') && (
            <button
              type="button"
              className={hoveredRowKey === record.id ? 'table-icon show' : 'table-icon'}
              onClick={() => handleRowClick(record.id)}
            >
              <img src={EditIcon} alt="Edit" />
            </button>
          )}
          {/* Bin icon to delete row */}
          {ability.can('delete', 'Seasons') && (
            <button
              type="button"
              className={hoveredRowKey === record.id ? 'table-icon show' : 'table-icon'}
              onClick={(e) => {
                e.stopPropagation();
                showDeleteConfirm(record.id, record.name);
              }}
            >
              <img src={DeleteIcon} alt="Delete" />
            </button>
          )}
        </Row>
      ),
    });
  }

  // Get Seasons data from the API
  useEffect(() => {
    fetchSeasons();
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setOriginalData([]);
    }
  };

  // Search bar implementation
  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filtered = value === ''
      ? originalData
      : originalData.filter((item) => item.name.toLowerCase().includes(value));

    setFilteredData(filtered);
  };

  return (
    <div>
      <Row>
        <Navbar />
        <NavbarMobile />
        <Col xs={24} sm={24} md={6} className="seasons-page-col1">
          <img src={LightLogo} alt="logo" className="seasons-white-logo" />
        </Col>
        <Col xs={24} sm={24} md={18} className="seasons-page-col2">
          <h1 className="seasons-title">{t('seasons.title')}</h1>
          <Input
            placeholder={t('seasons.search_season')}
            className="search-season search-season-mobile"
            value={searchText}
            onChange={handleSearch}
          />
          <Table
            locale={locale}
            columns={columns}
            dataSource={filteredData}
            className="seasons-table"
            pagination={tableParams.pagination}
            loading={loading}
            onChange={handleTableChange}
            onRow={(record) => ({
              onClick: () => handleRowClick(record.id),
              onMouseEnter: () => setHoveredRowKey(record.id),
              onMouseLeave: () => setHoveredRowKey(null),
            })}
            scroll={{
              x: 500, // Ajust as needed
            }}
          />
          <ConfirmDelete
            open={showConfirmation}
            setOpen={setShowConfirmation}
            onConfirm={deleteRow} // Pass the confirmation handler function
            name={seasonNameToDelete}
          />
          <button type="button" className="new-season-btn new-season-btn-mobile" onClick={() => navigate('/season/create')}>
            {t('seasons.create_season')}
            <i className="plus-icon">+</i>
          </button>
        </Col>
      </Row>
    </div>
  );
}

export default Seasons;
