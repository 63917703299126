import React, { useState, useEffect, useContext } from 'react';
import {
  Col, Row, notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useNavigate, useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from '../axios';
import ProfilePlaceholder from '../images/user_pic_placeholder.svg';
import Navbar from '../components/Navbar';
import NavbarMobile from '../components/NavbarMobile';
import { Can, AbilityContext } from '../Abilities/Can';
import languageStore from '../stores/languageStore';
import '../css/userProfile.css';

function UserProfile({ adminView }) {
  const navigate = useNavigate();
  const [t] = useTranslation('global');
  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';
  const companyID = 1;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [department, setDepartment] = useState('');
  const [role, setRole] = useState('');
  const [, setIsLoaded] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const [darkLogo, setDarkLogo] = useState('');
  const [loadingImage, setLoadingImage] = useState(true);
  const ability = useContext(AbilityContext);
  let userID = 0;
  if (adminView) {
    const { id } = useParams();
    userID = id;
  }

  const endpointURL = adminView ? `/api/user/${userID}` : '/api/user/profile';
  const editProfileURL = adminView ? `/user/${userID}/edit` : '/profile/edit';

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: 'Success',
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: 'Error',
        description: message,
      });
    }
  };
  useEffect(() => {
    axios.get(
      endpointURL,
    )
      .then((response) => {
        setName(response.data.name);
        setEmail(response.data.email);
        const genderKey = String(response.data.gender);
        setGender(genderKey);
        setDepartment(response.data.Department.name[selectedLanguage]);
        setProfileImage(response.data.profileImage || ProfilePlaceholder);
        if (adminView) {
          setRole(response.data.Role.name[selectedLanguage]);
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openNotificationWithIcon('error', t('auth.need_login'));
          navigate('/login');
        } else if (error.response.status === 403) {
          openNotificationWithIcon('error', t('auth.not_authorized'));
          navigate('/login');
        } else if (error.response.status === 404) {
          openNotificationWithIcon('error', t('profile.not_found'));
          navigate('/');
        }
      });
  }, [languageStore.getState().currentLanguage]);

  useEffect(() => {
    axios.get(`/api/settings/company/${companyID}/logos`)
      .then((response) => {
        setDarkLogo(response.data.companyLogoDark);
      })
      .catch((error) => {
        console.error('Error fetching logos:', error);
        openNotificationWithIcon('error', t('settings.logo_fetch_failed'));
      });
  }, []);

  return (
    <div>
      <Row className="user-profile-container">
        <NavbarMobile />
        <Navbar />
        <Col xs={24} sm={24} md={9} className="user-col1">
          <h1 className="user-profile-title-mobile">{t('profile.user_profile')}</h1>
          {loadingImage && <span className="loader" /> }
          {}
          <img
            src={profileImage}
            alt="profile"
            className="profile-img"
            onLoad={() => setLoadingImage(false)}
            style={{ display: loadingImage ? 'none' : 'block' }}
          />
          {!adminView ? (
            <button onClick={() => navigate(editProfileURL)} className="edit-profile-btn" type="button">
              {t('profile.edit_profile')}
            </button>
          ) : (
            <Can do="update" on="Users">
              <button onClick={() => navigate(editProfileURL)} className="edit-profile-btn" type="button">
                {t('profile.edit_profile')}
              </button>
            </Can>
          )}
        </Col>
        <Col xs={24} sm={24} md={15} className="user-col2">
          <img src={darkLogo} alt="logo" className="profile-logo desktop" id="logo-desktop" />
          <div className="user-col2-content">
            <div className="user-info">
              <h1 className="user-profile-title-desktop">{t('profile.user_profile')}</h1>
              <div className="user-info-container">
                <h3 className="user-info-title">{t('profile.name')}</h3>
                <p className="user-info-text">{name}</p>
              </div>
              <div className="user-info-container">
                <h3 className="user-info-title">{t('profile.email')}</h3>
                <p className="user-info-text">{email}</p>
              </div>
              <div className="user-info-container">
                <h3 className="user-info-title">{t('profile.gender')}</h3>
                <p className="user-info-text">{t(`gender.${gender}`)}</p>
              </div>
              <div className="user-info-container">
                <h3 className="user-info-title">{t('profile.department')}</h3>
                <p className="user-info-text">{department}</p>
              </div>
              {adminView && ability.can('update', 'Users') && (
                <div className="user-info-container">
                  <h3 className="user-info-title">{t('profile.role')}</h3>
                  <p className="user-info-text">{role}</p>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

UserProfile.propTypes = {
  adminView: PropTypes.bool.isRequired,
};

export default UserProfile;
