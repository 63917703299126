import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/index.css';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthContext';
import globalEn from './translations/en/global.json';
import globalPt from './translations/pt/global.json';
import languageStore from './stores/languageStore';
import App from './App';

// Initialize i18next
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: languageStore.getState().currentLanguage || 'pt', // language to use, defaults to 'pt'
  resources: {
    en: {
      global: globalEn, // 'global' is our custom namespace
    },
    pt: {
      global: globalPt,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </I18nextProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
