import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import '../css/uploadImage.css';

function UploadImage({
  onImageUpload, clearImagePreview,
}) {
  const [t] = useTranslation('global');
  const [dragging, setDragging] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const fileInputRef = React.createRef();

  useEffect(() => {
    if (clearImagePreview) {
      // Reset the image preview when the form it is inserted in is submitted
      setImagePreview(null);
      fileInputRef.current.value = '';
    }
  });

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleImageUpload = (file) => {
    const reader = new FileReader();
    reader.onload = () => {
      const imageDataURL = reader.result;
      onImageUpload(imageDataURL);
      setImagePreview(imageDataURL);
    };
    reader.readAsDataURL(file);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) { // Check if its an image
      handleImageUpload(file);
    }
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      handleImageUpload(file);
    }
  };

  return (
    <div
      className={`upload-image-container ${dragging ? 'dragging' : ''}`}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      <input
        type="file"
        accept="image/*"
        onChange={handleFileInputChange}
        style={{ display: 'none' }}
        ref={fileInputRef}
      />
      <div
        className="upload-text"
        onClick={() => fileInputRef.current.click()}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            fileInputRef.current.click();
          }
        }}
        role="button"
        tabIndex={0}
      >
        {dragging ? (
          <span className="bolded">
            {t('upload_image.drop_here')}
          </span>
        ) : (
          <div>
            {imagePreview ? (
              <img src={imagePreview} alt="Uploaded" className="uploaded-image" />
            ) : (
              <div className="upload-text">
                <span className="bolded">
                  {t('upload_image.drag_and_drop')}
                </span>
                <span>
                  {t('upload_image.or')}
                </span>
                <span className="bolded underlined">
                  {t('upload_image.browse')}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

UploadImage.propTypes = {
  onImageUpload: PropTypes.isRequired,
  clearImagePreview: PropTypes.bool.isRequired,
};

export default UploadImage;
