import React from 'react';
import PropTypes from 'prop-types';

function SocialMediaButton({
  link, icon, altText, iconSize,
}) {
  const padding = 0.4 * iconSize;

  const socialMediaIconsStyle = { width: `${iconSize}px`, height: `${iconSize}px` };
  const socialMediaIconsImgStyle = { width: `${iconSize - padding}px`, height: `${iconSize - padding}px` };

  return (
    <div className="social-media-button">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <div className="social-media-icons" style={socialMediaIconsStyle}>
          <img src={icon} alt={altText} className="social-media-icons-img" style={socialMediaIconsImgStyle} />
        </div>
      </a>
    </div>
  );
}

SocialMediaButton.propTypes = {
  link: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  iconSize: PropTypes.number.isRequired,
};

export default SocialMediaButton;
