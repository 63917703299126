import React, { useState, useEffect } from 'react';
import {
  Col, Row, notification, Button, Input, Checkbox, Collapse,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../axios';
import ConfirmAction from '../components/ConfirmAction';
import NavbarMobile from '../components/NavbarMobile';
import Navbar from '../components/Navbar';

const { TextArea } = Input;

function EditRole() {
  const [t] = useTranslation('global');
  const navigate = useNavigate();

  let roleID = 0;
  const { id } = useParams();
  roleID = id;

  const [loading, setLoading] = useState(false);

  const languages = ['pt', 'en'];

  const companyID = 1;
  const [names, setNames] = useState(Array(languages.length).fill(''));
  const [descriptions, setDescriptions] = useState(Array(languages.length).fill(''));
  const [permissionSubjects, setPermissionSubjects] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [activeKeys, setActiveKeys] = useState([]);
  const [LightLogo, setLightLogo] = useState('');

  const [items, setItems] = useState([]);

  const [selectedPermissions, setSelectedPermissions] = useState([[]]);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const showConfirmationModal = () => {
    setShowConfirmation(true);
  };

  let permissionSubs = [];

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: 'Success',
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: 'Error',
        description: message,
      });
    }
  };

  useEffect(() => {
    axios.get(`/api/settings/company/${companyID}/logos`)
      .then((response) => {
        setLightLogo(response.data.companyLogoLight);
      })
      .catch((error) => {
        console.error('Error fetching logos:', error);
        openNotificationWithIcon('error', t('settings.logo_fetch_failed'));
      });
  }, []);

  const handleCheckboxChange = (subjectIndex, checkedValues, allPermissions) => {
    const updatedCheckedValues = [...checkedValues];
    allPermissions.forEach((permission) => {
      if (updatedCheckedValues.includes(permission.id)) {
        if (!permission.action.startsWith('read')) {
          const seePermissionId = allPermissions.find((perm) => perm.subject === permission.subject && perm.action.startsWith('read'))?.id;
          if (seePermissionId && !updatedCheckedValues.includes(seePermissionId)) {
            updatedCheckedValues.push(seePermissionId);
            openNotificationWithIcon('error', t('roles.permissions_required'));
          }
        }
      }
    });
    const updatedPermissions = [...selectedPermissions];
    updatedPermissions[subjectIndex] = updatedCheckedValues;
    setSelectedPermissions(updatedPermissions);
  };

  const [indeterminate, setIndeterminate] = useState(new Array(permissionSubjects.length)
    .fill(false));
  const [checkAll, setCheckAll] = useState(new Array(permissionSubjects.length).fill(false));

  const onCheckAllChange = (e, subjectIndex) => {
    const perms = [...selectedPermissions];
    perms[subjectIndex] = e.target.checked
      ? permissions.filter((permission) => permission.subject === permissionSubjects[subjectIndex])
        .map((permission) => permission.id)
      : [];
    setSelectedPermissions(perms);
    setIndeterminate(indeterminate.map((ind, i) => (i === subjectIndex ? false : ind)));
    setCheckAll(checkAll.map((chk, i) => (i === subjectIndex ? e.target.checked : chk)));
  };

  function generateItems(permissionSubjs = permissionSubjects, perms = permissions) {
    return permissionSubjs.map((subject, index) => {
      const subjectPermissionIds = perms
        .filter((permission) => permission.subject === subject)
        .map((permission) => permission.id);
      const isAllChecked = subjectPermissionIds
        .every((iD) => selectedPermissions[index]?.includes(iD));
      return {
        key: subject,
        label: t(`role.${subject}`),
        children: (
          <div className="create-role-checkboxes">
            <div className="select-all-checkbox">
              <Checkbox
                onChange={(e) => onCheckAllChange(e, index)}
                checked={isAllChecked}
              >
                {t('roles.select_all')}
              </Checkbox>
            </div>
            <Checkbox.Group
              style={{
                width: '100%',
              }}
              onChange={(checkedValues) => {
                handleCheckboxChange(index, checkedValues, perms);
              }}
              value={[].concat(...selectedPermissions)}
            >
              <Row>
                {perms.map((permission) => (
                  permission.subject === subject && (
                    <Col span={12}>
                      <Checkbox value={permission.id}>
                        {`${t(`role.${permission.action}`)} ${t(`role.${permission.subject}`)}`}
                      </Checkbox>
                    </Col>
                  )))}
              </Row>
            </Checkbox.Group>
          </div>
        ),
      };
    });
  }

  useEffect(() => {
    axios.get('/api/role/permission')
      .then((response) => {
        const filteredPermissions = response.data;
        const subjects = filteredPermissions.map((permission) => permission.subject);
        permissionSubs = [...new Set(subjects)];
        setPermissions(response.data);
        setPermissionSubjects([...new Set(subjects)]);
        setItems(generateItems([...new Set(subjects)], response.data));
      })
      .catch((error) => {
        console.error('Error fetching Permissions:', error);
      });
  }, []);

  const onChange = (key) => {
    setActiveKeys(key);
  };

  function mergeArraysToObject(arr) {
    const obj = {};
    languages.forEach((element, index) => {
      obj[element] = arr[index];
    });
    return obj;
  }

  const parseSelectedPermissions = (currentPermissions) => {
    const perms = [];
    for (let i = 0; i < currentPermissions.length; i += 1) {
      const { subject } = currentPermissions[i];
      const index = permissionSubs.indexOf(subject);
      if (!perms[index]) {
        perms[index] = [];
      }
      perms[index].push(currentPermissions[i].id);
    }
    return perms;
  };

  const handleEditRole = async () => {
    // event.preventDefault();
    setLoading(true);
    let flatPermissions = [].concat(...selectedPermissions);
    // convert flat permissions to json structure
    // remove undefineds from flatPermissions
    flatPermissions = flatPermissions.filter((element) => element !== undefined);
    const jsonPermissions = [];
    for (let i = 0; i < flatPermissions.length; i += 1) {
      const permissionID = flatPermissions[i] - 1;
      jsonPermissions.push({
        id: permissionID + 1,
        action: permissions[permissionID].action,
        subject: permissions[permissionID].subject,
        conditions: permissions[permissionID].conditions,
      });
    }
    const jsonNames = mergeArraysToObject(names);
    const jsonDescriptions = mergeArraysToObject(descriptions);
    try {
      const response = await axios.post(
        `/api/role/${roleID}/edit`,
        {
          name: jsonNames,
          description: jsonDescriptions,
          companyID: '1',
          permissions: JSON.stringify(jsonPermissions),
        },
      );
      if (response.status === 201) {
        openNotificationWithIcon('success', t('role.edit_success'));
        setActiveKeys([]);
        setLoading(false);
        navigate(`/role/${roleID}`);
      }
    } catch (error) {
      setLoading(false);
      if (error.response) {
        const { status } = error.response;
        switch (status) {
          case 406:
            openNotificationWithIcon('error', t('role.create_406'));
            break;
          case 401:
            openNotificationWithIcon('error', t('auth.need_login'));
            navigate('/login');
            break;
          case 400:
            openNotificationWithIcon('error', t('role.create_400'));
            break;
          case 403:
            openNotificationWithIcon('error', t('auth.not_authorized'));
            break;
          default:
            openNotificationWithIcon('error', error.response.data.message || t('role.create_error'));
        }
      } else {
        openNotificationWithIcon('error', t('role.create_error'));
      }
    }
  };

  const handleRoleEditConfirmation = () => {
    handleEditRole();
  };

  const updateNames = (name, index) => {
    const updatedArray = names.map((item, i) => {
      if (i === index) {
        return name; // Update the item at the specified index
      }
      return item;
    });
    setNames(updatedArray);
  };

  const updateDescriptions = (description, index) => {
    const updatedArray = descriptions.map((item, i) => {
      if (i === index) {
        return description; // Update the item at the specified index
      }
      return item;
    });
    setDescriptions(updatedArray);
  };

  const jsonToArray = (json) => {
    const arr = [];
    languages.forEach((language) => {
      arr.push(json[language]);
    });
    return arr;
  };

  useEffect(() => {
    axios.get(
      `api/role/${roleID}`,
    )
      .then((response) => {
        console.log('response data: ', response.data);
        setNames(jsonToArray(response.data.name));
        setDescriptions(jsonToArray(response.data.description));
        setSelectedPermissions(parseSelectedPermissions(response.data.permissions));
      })
      .catch(() => {
        openNotificationWithIcon('error', 'Error fetching role');
      });
  }, []);

  useEffect(() => {
    setItems(generateItems());
  }, [selectedPermissions]);

  return (
    <div>
      <Row className="role-container">
        <Col xs={24} sm={24} md={9} className="roles-page-col1">
          <img src={LightLogo} alt="logo" className="roles-white-logo" />
          <NavbarMobile />
        </Col>
        <Col xs={24} sm={24} md={15} className="role-page-col2">
          <div className="role-page-col2-content">
            <Navbar />
            <div className="role-info">
              <h1 className="role-page-title-desktop">{t('roles.edit')}</h1>
              <div className="role-page-container">
                {languages.map((language, index) => (
                  <>
                    <p className="create-role-name">
                      {t('role.name_in')}
                      {t(`languages.${language}`)}
                      :
                    </p>
                    <Input
                      type="text"
                      id="name"
                      name="name"
                      maxLength={50}
                      showCount
                      value={names[index]}
                      onChange={(e) => updateNames(e.target.value, index)}
                    />
                  </>
                ))}
                {languages.map((language, index) => (
                  <>
                    <p className="create-role-name">
                      {t('role.description_in')}
                      {t(`languages.${language}`)}
                      :
                    </p>
                    <TextArea
                      rows={3}
                      value={descriptions[index]}
                      maxLength={500}
                      showCount
                      onChange={(e) => updateDescriptions(e.target.value, index)}
                    />
                  </>
                ))}
                <div className="role-page-permissions">
                  <h3 className="role-info-permission-title">{t('roles.permissions')}</h3>
                  <Collapse ghost items={items} onChange={onChange} activeKey={activeKeys} />
                </div>
              </div>
            </div>
            <div className="edit-role-button-container">
              <Button onClick={showConfirmationModal} className="edit-role-button" loading={loading} type="submit">
                {t('role.update_button')}
              </Button>
            </div>
            <ConfirmAction
              open={showConfirmation}
              setOpen={setShowConfirmation}
              onConfirm={handleRoleEditConfirmation} // Pass the confirmation handler function
            />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default EditRole;
