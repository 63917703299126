import React from 'react';
import { Row } from 'antd';
import InstagramLogo from '../images/instagram_logo.svg';
import FacebookLogo from '../images/facebook_logo.svg';
import LinkedinLogo from '../images/linkedin_logo.svg';
import SocialMediaButton from './SocialMediaButton';

function AuthFooter() {
  return (
    <footer>
      <div className="footer">
        <Row>
          <SocialMediaButton icon={InstagramLogo} altText="Instagram" link="https://www.instagram.com/junifeup" />
          <SocialMediaButton icon={FacebookLogo} altText="Facebook" link="https://m.facebook.com/junifeup" />
          <SocialMediaButton icon={LinkedinLogo} altText="Linkedin" link="https://www.linkedin.com/company/junifeup" />
        </Row>
      </div>
    </footer>
  );
}

export default AuthFooter;
