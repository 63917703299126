/* eslint-disable */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import languageStore from '../stores/languageStore';
import '../css/translateButton.css';

function TranslateSwitch({ className = '' }) {
  const [t, i18n] = useTranslation('global');
  const [isPTSelected, setIsPTSelected] = useState(languageStore.getState().currentLanguage === 'pt');
  const handleChange = () => {
    const newLanguage = isPTSelected ? 'en' : 'pt';
    setIsPTSelected(!isPTSelected);
    i18n.changeLanguage(newLanguage);
    languageStore.setState({ currentLanguage: newLanguage });
  };
  const translateSwitchClasses = `translate-switch ${className}`;
  return (
    <div className={translateSwitchClasses}>
      <label className="translate-label-left" onClick={handleChange}>
        {t('EN')}
      </label>
      <label className="switch">
        <input type="checkbox" id="switch-pt" checked={isPTSelected} onChange={handleChange} />
        <span className="slider" />
      </label>
      <label className="translate-label-right" onClick={handleChange}>
        {t('PT')}
      </label>
    </div>
  );
}

TranslateSwitch.propTypes = {
  className: PropTypes.string,
};

export default TranslateSwitch;
