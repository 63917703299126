import React, { useState, useEffect, useContext } from 'react';
import { Layout, Menu, Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HamburguerLines from '../images/open_menu_icon.svg';
import CloseMenu from '../images/close_icon.svg';
import HomepageIcon from '../images/homepage_icon.svg';
import UsersIcon from '../images/users_icon.svg';
import RolesIcon from '../images/roles_icon.svg';
import PersonalizeIcon from '../images/personalize_icon.svg';
import CandidatesIcon from '../images/candidates_icon.svg';
import SeasonsIcon from '../images/seasons_icon.svg';
import JobsIcon from '../images/jobs_icon.svg';
import DepartmentsIcon from '../images/departments_icon.svg';
import ProfileIcon from '../images/profile_icon.svg';
import TranslateButton from './TranslateButton';
import axios from '../axios';
import '../css/navbar.css';

import { AbilityContext } from '../Abilities/Can';

const { Sider } = Layout;

function Navbar() {
  const storedCollapsed = localStorage.getItem('navbarCollapsed');
  const [collapsed, setCollapsed] = useState(storedCollapsed === 'true');
  const [t] = useTranslation('global');
  const location = useLocation();
  const ability = useContext(AbilityContext);
  const [userId, setUserId] = useState(null);

  // Persist the collapsed state through pages
  useEffect(() => {
    localStorage.setItem('navbarCollapsed', collapsed);
    axios.get(
      '/api/user/profile',
    )
      .then((response) => {
        if (response.status === 200) {
          setUserId(response.data.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [collapsed]);

  // Navbar items
  const items = [
    {
      label: t('menu.homepage'), key: '1', icon: <img src={HomepageIcon} alt="homepage icon" className="navbar-icon" />, link: '/', canAccess: () => true,
    },
    {
      label: t('menu.profile'), key: '2', icon: <img src={ProfileIcon} alt="profile icon" className="navbar-icon" />, link: ability.can('update', 'Users') ? `/user/${userId}` : '/profile', canAccess: () => true,
    },
    {
      label: t('menu.users'), key: '3', icon: <img src={UsersIcon} alt="users icon" className="navbar-icon users-icon" />, link: '/users', canAccess: () => ability.can('read', 'Users'),
    },
    {
      label: t('menu.roles'), key: '4', icon: <img src={RolesIcon} alt="roles icon" className="navbar-icon" />, link: '/roles', canAccess: () => ability.can('read', 'Roles'),
    },
    {
      label: t('menu.personalize'), key: '5', icon: <img src={PersonalizeIcon} alt="personalize icon" className="navbar-icon" />, link: '/settings', canAccess: () => ability.can('read', 'Users'),
    },
    {
      label: t('menu.departments'), key: '6', icon: <img src={DepartmentsIcon} alt="candidates icon" className="navbar-icon" />, link: '/departments', canAccess: () => ability.can('read', 'all'),
    },
    {
      label: t('menu.seasons'), key: '7', icon: <img src={SeasonsIcon} alt="seasons icon" className="navbar-icon" />, link: '/seasons', canAccess: () => ability.can('read', 'Seasons'),
    },
    {
      label: t('menu.jobs'), key: '8', icon: <img src={JobsIcon} alt="jobs icon" className="navbar-icon" />, link: '/jobs', canAccess: () => ability.can('read', 'Jobs'),
    },
    {
      label: t('menu.application_forms'), key: '9', icon: <img src={CandidatesIcon} alt="applicationForms icon" className="navbar-icon" />, link: '/applicationForms', canAccess: () => ability.can('read', 'Application Forms'),
    },
  ];

  const currentKey = items.find((item) => item.link === location.pathname)?.key;

  return (
    <div>
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed}
          className={collapsed ? 'navbar-menu' : 'navbar-menu wide'}
        >
          <div className="toggle-menu-buttons">
            {!collapsed && <TranslateButton className="translate-button-navbar" /> }
            <Button
              type="text"
              className={collapsed ? 'toggle-menu-button center' : 'toggle-menu-button right'}
              icon={<img src={collapsed ? HamburguerLines : CloseMenu} alt="toggle-menu" className="toggle-menu-icon" />}
              onClick={() => setCollapsed(!collapsed)}
            />
          </div>
          <Menu theme="dark" mode="inline" selectedKeys={[currentKey]}>
            {items.filter((i) => i.canAccess()).map((item) => (
              <Menu.Item key={item.key} title={item.label}>
                <Link to={item.link}>
                  <span className={collapsed ? 'menu-link-icon-collapsed' : 'menu-link-icon'}>{item.icon}</span>
                  <span className={collapsed ? 'menu-link-title hide' : 'menu-link-title'}>{item.label}</span>
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </Sider>
      </Layout>
    </div>
  );
}

export default Navbar;
