import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Input, Modal, Button, notification, Col, Row, Select,
} from 'antd';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import axios from '../axios';
import UploadImage from './UploadImage';
import ConfirmAction from './ConfirmAction';
import languageStore from '../stores/languageStore';
import '../css/createJob.css';

const { TextArea } = Input;

function CreateJob({
  open, setOpen, fetchJobs,
}) {
  const navigate = useNavigate();
  const [t] = useTranslation('global');

  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';

  const languages = ['pt', 'en'];

  const [names, setNames] = useState(Array(languages.length).fill(''));
  const [descriptions, setDescriptions] = useState(Array(languages.length).fill(''));
  const [shortDescriptions, setShortDescriptions] = useState(Array(languages.length).fill(''));
  const [departmentID, setDepartment] = useState('');
  const [departments, setDepartments] = useState([]);
  const [clearImagePreview, setClearImagePreview] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  // Get departments to display on select dropdown
  const fetchDepartments = async () => {
    try {
      const response = await axios.get('/api/department');
      const parsedDepartmentsData = response.data;
      parsedDepartmentsData.forEach((department, index) => {
        parsedDepartmentsData[index].name = department.name[selectedLanguage];
        parsedDepartmentsData[index].description = department.description[selectedLanguage];
      });
      setDepartments(parsedDepartmentsData);
    } catch (error) {
      console.error('Error fetching departments:', error);
    }
  };

  function mergeArraysToObject(arr) {
    const obj = {};
    languages.forEach((element, index) => {
      obj[element] = arr[index];
    });
    return obj;
  }

  useEffect(() => {
    fetchDepartments();
  }, [loading]); // Argument is only to restrict the number of times it fetches departments

  const handleCreateJob = async () => {
    setLoading(true);
    const jsonNames = mergeArraysToObject(names);
    const jsonDescriptions = mergeArraysToObject(descriptions);
    const jsonShortDescriptions = mergeArraysToObject(shortDescriptions);
    try {
      const response = await axios.post(
        '/api/job/create',
        {
          name: jsonNames,
          description: jsonDescriptions,
          shortDescription: jsonShortDescriptions,
          companyID: '1',
          departmentID,
        },
      );
      if (response.status === 201) {
        openNotificationWithIcon('success', t('jobs.create_success'));
        setNames(Array(languages.length).fill(''));
        setDescriptions(Array(languages.length).fill(''));
        setShortDescriptions(Array(languages.length).fill(''));
        setDepartment('');
        setClearImagePreview(true);
        setLoading(false);
        await fetchJobs();
        setOpen(false);
        setClearImagePreview(false);
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        switch (status) {
          case 406:
            openNotificationWithIcon('error', t('jobs.create_406'));
            break;
          case 401:
            openNotificationWithIcon('error', t('auth.need_login'));
            navigate('/login');
            break;
          case 400:
            openNotificationWithIcon('error', t('jobs.create_400'));
            break;
          case 403:
            openNotificationWithIcon('error', t('auth.not_authorized'));
            break;
          default:
            openNotificationWithIcon('error', error.response.data.message || t('jobs.create_error'));
        }
      } else {
        openNotificationWithIcon('error', t('jobs.create_error'));
      }
      setLoading(false);
    }
  };

  const onImageUpload = (imageDataURL) => {
    // Do something with the image Data
    console.log(imageDataURL);
  };

  const handleCreateJobConfirmation = () => {
    handleCreateJob();
  };

  const showConfirmationModal = () => {
    setShowConfirmation(true);
  };

  const updateNames = (name, index) => {
    const updatedArray = names.map((item, i) => {
      if (i === index) {
        return name; // Update the item at the specified index
      }
      return item;
    });
    setNames(updatedArray);
  };

  const updateDescriptions = (description, index) => {
    const updatedArray = descriptions.map((item, i) => {
      if (i === index) {
        return description; // Update the item at the specified index
      }
      return item;
    });
    setDescriptions(updatedArray);
  };

  const updateShortDescriptions = (description, index) => {
    const updatedArray = shortDescriptions.map((item, i) => {
      if (i === index) {
        return description; // Update the item at the specified index
      }
      return item;
    });
    setShortDescriptions(updatedArray);
  };

  return (
    <div>
      <Modal
        open={open}
        title={<h2 className="create-job-title">{t('jobs.create_job')}</h2>}
        onCancel={handleCancel}
        centered
        footer={null}
        className="create-job-popup"
        width="750px"
      >
        <form onSubmit={handleCreateJob} className="create-job-form" id="create-job-form">
          <Row>
            <Col xs={24} sm={24} md={16}>
              {languages.map((language, index) => (
                <>
                  <p className="create-job-name">
                    {t('jobs.name_in')}
                    {t(`languages.${language}`)}
                    :
                  </p>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    maxLength={50}
                    showCount
                    value={names[index]}
                    onChange={(e) => updateNames(e.target.value, index)}
                  />
                </>
              ))}
              {languages.map((language, index) => (
                <>
                  <p className="create-job-name">
                    {t('jobs.description_in')}
                    {t(`languages.${language}`)}
                    :
                  </p>
                  <TextArea
                    rows={3}
                    value={descriptions[index]}
                    maxLength={500}
                    showCount
                    onChange={(e) => updateDescriptions(e.target.value, index)}
                  />
                </>
              ))}
              {languages.map((language, index) => (
                <>
                  <p className="create-job-name">
                    {t('jobs.short_description_in')}
                    {t(`languages.${language}`)}
                    :
                  </p>
                  <TextArea
                    rows={3}
                    value={shortDescriptions[index]}
                    maxLength={200}
                    showCount
                    onChange={(e) => updateShortDescriptions(e.target.value, index)}
                  />
                </>
              ))}
              <p className="create-job-name">{t('jobs.department')}</p>
              <Select
                className="create-job"
                value={departmentID}
                onChange={(e) => setDepartment(e)}
                options={departments.map((d) => ({ value: d.id, label: d.name }))}
                optionFilterProp="children"
                filterOption={filterOption}
                showSearch
              />
            </Col>
            <Col xs={24} sm={24} md={8}>
              <div className="create-job-icon">
                <p className="input-icon">{t('jobs.icon')}</p>
                <UploadImage
                  onImageUpload={onImageUpload}
                  clearImagePreview={clearImagePreview}
                />
              </div>
            </Col>
          </Row>
          <Button className="create-job-button" type="button" loading={loading} onClick={showConfirmationModal}>
            {t('role.create')}
          </Button>
        </form>
        <ConfirmAction
          open={showConfirmation}
          setOpen={setShowConfirmation}
          onConfirm={handleCreateJobConfirmation}
        />
      </Modal>
    </div>
  );
}

CreateJob.propTypes = {
  open: PropTypes.isRequired,
  setOpen: PropTypes.isRequired,
  fetchJobs: PropTypes.isRequired,
};

export default CreateJob;
