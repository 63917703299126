// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* User Setup Profile */
.ant-form-item {
    margin-bottom: 0;
  }
  
  .setup-profile-submit-button {
    font-family: var(--font), sans-serif;
    background-color: var(--primary-color);
    cursor: pointer;
    font-weight: bold;
    align-self: center;
    height: 40px;
    color: var(--secondary-color);
    padding: 0.5em 3em;
    margin-block: 1.5em;
    border-radius: 15px;
    border: none;
  }
  
  .setup-profile-submit-button:hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
  }
  
  .ant-btn-primary {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    color: var(--secondary-color);
    font-family: var(--font), sans-serif;
    font-weight: bold;
    border-radius: 15px;
    padding: 0.5em 3em;
    margin-block: 1.5em;
    border: none;
  }
  
  .ant-btn-primary:hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
  }
  
  .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {
    color: var(--secondary-color);
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
  
  :where(.css-dev-only-do-not-override-6j9yrn).ant-form-item .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }`, "",{"version":3,"sources":["webpack://./src/css/setupProfile.css"],"names":[],"mappings":"AAAA,uBAAuB;AACvB;IACI,gBAAgB;EAClB;;EAEA;IACE,oCAAoC;IACpC,sCAAsC;IACtC,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,YAAY;IACZ,6BAA6B;IAC7B,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,wCAAwC;IACxC,2BAA2B;EAC7B;;EAEA;IACE,sCAAsC;IACtC,kCAAkC;IAClC,6BAA6B;IAC7B,oCAAoC;IACpC,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,wCAAwC;IACxC,2BAA2B;EAC7B;;EAEA;IACE,6BAA6B;IAC7B,sCAAsC;IACtC,kCAAkC;EACpC;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;EACrB","sourcesContent":["/* User Setup Profile */\n.ant-form-item {\n    margin-bottom: 0;\n  }\n  \n  .setup-profile-submit-button {\n    font-family: var(--font), sans-serif;\n    background-color: var(--primary-color);\n    cursor: pointer;\n    font-weight: bold;\n    align-self: center;\n    height: 40px;\n    color: var(--secondary-color);\n    padding: 0.5em 3em;\n    margin-block: 1.5em;\n    border-radius: 15px;\n    border: none;\n  }\n  \n  .setup-profile-submit-button:hover {\n    background-color: var(--secondary-color);\n    color: var(--primary-color);\n  }\n  \n  .ant-btn-primary {\n    background-color: var(--primary-color);\n    border-color: var(--primary-color);\n    color: var(--secondary-color);\n    font-family: var(--font), sans-serif;\n    font-weight: bold;\n    border-radius: 15px;\n    padding: 0.5em 3em;\n    margin-block: 1.5em;\n    border: none;\n  }\n  \n  .ant-btn-primary:hover {\n    background-color: var(--secondary-color);\n    color: var(--primary-color);\n  }\n  \n  .ant-btn-primary:not(:disabled):not(.ant-btn-disabled):hover {\n    color: var(--secondary-color);\n    background-color: var(--primary-color);\n    border-color: var(--primary-color);\n  }\n  \n  :where(.css-dev-only-do-not-override-6j9yrn).ant-form-item .ant-form-item-control-input-content {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
