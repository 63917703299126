import React from 'react';
import PropTypes from 'prop-types';

function Testimonial({ name, description, photo }) {
  return (
    <div className="testimonial-div">
      <div className="testimonial-photo-div">
        <img className="testimonial-photo" src={photo} alt="user" />
      </div>
      <h3 className="testimonial-name">{name}</h3>
      <p className="testimonial-description">{description}</p>
    </div>
  );
}

Testimonial.propTypes = {
  name: PropTypes.isRequired,
  description: PropTypes.isRequired,
  photo: PropTypes.isRequired,
};

export default Testimonial;
