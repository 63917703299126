import React, { useState, useEffect } from 'react';
import {
  Col, Row, Input, notification, Select, Form, Button,
} from 'antd'; // Import Form component from Ant Design
import { useTranslation } from 'react-i18next';
import { LuPencil } from 'react-icons/lu';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../axios';
import ProfilePlaceholder from '../images/user_pic_placeholder.svg';
import Navbar from '../components/Navbar';
import NavbarMobile from '../components/NavbarMobile';
import PasswordForm from '../components/authentication/PasswordForm';
import '../css/App.css';
import ConfirmAction from '../components/ConfirmAction';
import languageStore from '../stores/languageStore';
import '../css/setupProfile.css';

function UserSetupProfile() {
  const { token } = useParams();
  const navigate = useNavigate();
  const [t] = useTranslation('global');
  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [department, setDepartment] = useState('');
  const [gender, setGender] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [passedRequirements, setPassedRequirements] = useState(false);

  const genderOptions = [{
    value: '1',
    label: t('gender.1'),
  },
  {
    value: '2',
    label: t('gender.2'),
  },
  {
    value: '0',
    label: t('gender.0'),
  },
  ];

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(
          `/api/department/setup/${token}`,
        );
        if (response.status === 200) {
          const departments = response.data;
          const deptOptions = [];
          departments.forEach((element) => {
            deptOptions.push({ value: element.id, label: element.name[selectedLanguage] });
          });
          setDepartmentOptions(deptOptions);
        }
      } catch (error) {
        // Handle error responses
        console.error('Error during departments fetch:', error);
      }
    };
    fetchDepartments();

    const fetchUserSetupEmail = async () => {
      try {
        const response = await axios.get(
          `/api/user/profile/setup/email/${token}`,
        );
        if (response.status === 200) {
          const userEmail = response.data;
          setEmail(userEmail);
        }
      } catch (error) {
        // Handle error responses
        console.error('Error during user setup email fetch:', error);
      }
    };
    fetchUserSetupEmail();
  }, [languageStore.getState().currentLanguage]);

  const handleSubmit = async () => {
    setSubmitted(true);
    if (!passedRequirements) {
      openNotificationWithIcon('error', t('auth.requirements_not_met'));
      return;
    }
    if (!name
        || !department
        || !gender
        || !newPassword
        || !confirmPassword) {
      openNotificationWithIcon('error', t('setup.new_password_required'));
      return;
    }
    try {
      const response = await axios.post(
        `/api/user/profile/setup/${token}`,
        {
          name, department, gender, newPassword, confirmPassword,
        },
      );
      if (response.status === 201) {
        openNotificationWithIcon('success', t('profile.edit_success'));
        navigate('/login');
      } else {
        openNotificationWithIcon('error', t('profile.edit_failed'));
      }
    } catch (error) {
      // Handle error responses
      if (error.response.status === 403) {
        openNotificationWithIcon('error', t('auth.not_authorized'));
      }
      openNotificationWithIcon('error', t('profile.edit_failed'));
    }
  };

  const handleProfileSetupConfirmation = () => {
    handleSubmit();
  };

  const showConfirmationModal = () => {
    setShowConfirmation(true);
  };

  return (
    <div>
      <Row className="user-profile-container">
        <Navbar />
        <NavbarMobile />
        <Col xs={24} sm={24} md={9} className="user-col1">
          <h1 className="user-profile-title-mobile">{t('setup.title')}</h1>
          <img src={ProfilePlaceholder} alt="profile" className="profile-img" />
          <div className="edit-user-photo"><LuPencil /></div>
        </Col>
        <Col xs={24} sm={24} md={15} className="user-col2">
          <div className="user-col2-content">
            <div className="user-info">
              <h1 className="edit-profile-title-desktop">{t('setup.title')}</h1>
              <Form className="edit-profile-input-container" onFinish={handleSubmit}>
                <div className="edit-profile-input-section">
                  <div className="edit-profile-input-section-header">
                    <LuPencil />
                    <p className="edit-profile-label">{t('profile.name')}</p>
                  </div>
                  <Form.Item
                    name="name"
                    rules={[{ required: true, message: t('setup.name_required') }]}
                  >
                    <Input
                      className="edit-profile-input-field"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Item>
                </div>
                <div className="edit-profile-input-section">
                  <div className="edit-profile-input-section-header">
                    <LuPencil />
                    <p className="edit-profile-label">{t('profile.email')}</p>
                  </div>
                  <Input
                    className="edit-profile-input-field"
                    type="email"
                    value={email}
                    disabled
                    placeholder={email}
                    required
                  />
                </div>
                <div className="edit-profile-input-section">
                  <div className="edit-profile-input-section-header">
                    <LuPencil />
                    <p className="edit-profile-label">{t('profile.gender')}</p>
                  </div>
                  <Form.Item
                    name="gender"
                    rules={[{ required: true, message: t('setup.gender_required') }]}
                  >
                    <Select
                      className="edit-profile-input-field"
                      value={gender}
                      onChange={(value) => setGender(value)}
                      options={genderOptions}
                      optionFilterProp="children"
                      filterOption={filterOption}
                      showSearch
                    />
                  </Form.Item>
                </div>
                <div className="edit-profile-input-section">
                  <div className="edit-profile-input-section-header">
                    <LuPencil />
                    <p className="edit-profile-label">{t('profile.department')}</p>
                  </div>
                  <Form.Item
                    name="department"
                    rules={[{ required: true, message: t('setup.department_required') }]}
                  >
                    <Select
                      className="edit-profile-input-field"
                      value={department}
                      onChange={(value) => setDepartment(value)}
                      options={departmentOptions}
                      optionFilterProp="children"
                      filterOption={filterOption}
                      showSearch
                    />
                  </Form.Item>
                </div>
                <div>
                  <div className="edit-profile-input-section-header">
                    <LuPencil />
                    <p className="edit-profile-label">{t('auth.password')}</p>
                  </div>
                  <PasswordForm
                    design="3"
                    submitted={submitted}
                    password={newPassword}
                    onPasswordChange={(password) => setNewPassword(password)}
                    confirmPassword={confirmPassword}
                    onConfirmPasswordChange={(password) => setConfirmPassword(password)}
                    onRequirementsChange={(e) => setPassedRequirements(e)}
                  />
                </div>
                <Form.Item>
                  <Button className="setup-profile-submit-button" type="button" onClick={showConfirmationModal}>
                    {t('profile.update_button')}
                  </Button>
                </Form.Item>
              </Form>
              <ConfirmAction
                open={showConfirmation}
                setOpen={setShowConfirmation}
                onConfirm={handleProfileSetupConfirmation} // Pass the confirmation handler function
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default UserSetupProfile;
