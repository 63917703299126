// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HomePage */

.homepage-header {
    background-color: var(--primary-color);
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding-block: 1.5em;
}

.logout-button {
    color: var(--secondary-color);
    border: none;
    border-radius: 15px;
    background-color: transparent;
    font-family: var(--font);
    font-size: 16px;
    padding: 0.5em;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.logout-icon {
    height: 20px;
    margin-right: 10px;
}

.translate-button-home { /* Reverse position of translate button in Auth page */
    position: relative;
    top: 0;
    left: 0;
    width: 85px;
    padding-inline: 1.5em;
}`, "",{"version":3,"sources":["webpack://./src/css/home.css"],"names":[],"mappings":"AAAA,aAAa;;AAEb;IACI,sCAAsC;IACtC,aAAa;IACb,mBAAmB;IACnB,yBAAyB;IACzB,oBAAoB;AACxB;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,mBAAmB;IACnB,6BAA6B;IAC7B,wBAAwB;IACxB,eAAe;IACf,cAAc;IACd,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA,yBAAyB,sDAAsD;IAC3E,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,qBAAqB;AACzB","sourcesContent":["/* HomePage */\n\n.homepage-header {\n    background-color: var(--primary-color);\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    padding-block: 1.5em;\n}\n\n.logout-button {\n    color: var(--secondary-color);\n    border: none;\n    border-radius: 15px;\n    background-color: transparent;\n    font-family: var(--font);\n    font-size: 16px;\n    padding: 0.5em;\n    cursor: pointer;\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n\n.logout-icon {\n    height: 20px;\n    margin-right: 10px;\n}\n\n.translate-button-home { /* Reverse position of translate button in Auth page */\n    position: relative;\n    top: 0;\n    left: 0;\n    width: 85px;\n    padding-inline: 1.5em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
