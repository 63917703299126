import React from 'react';
import { Row } from 'antd';

import LogoBlack from '../images/logo_preenchimento_preto_2.svg';
import TranslateButton from './TranslateButton';

function RecoverPasswordHeader() {
  return (
    <div className="recover-password-header">
      <Row>
        <img src={LogoBlack} alt="logo" className="header-logo" id="logo-black" />
        <TranslateButton />
      </Row>
    </div>
  );
}

export default RecoverPasswordHeader;
