import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Input, Modal, Button, notification, Col, Row,
} from 'antd';
import PropTypes from 'prop-types';
import axios from '../axios';
import UploadImage from './UploadImage';
import ConfirmAction from './ConfirmAction';
import '../css/createDepartments.css';

const { TextArea } = Input;

function EditDepartment({
  open, setOpen, departmentId, fetchDepartments,
}) {
  const [t] = useTranslation('global');

  const languages = ['pt', 'en'];

  const [names, setNames] = useState({});
  const [descriptions, setDescriptions] = useState({});
  const [clearImagePreview, setClearImagePreview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  useEffect(() => {
    if (!open) return;
    const fetchDepartment = async () => {
      try {
        const response = await axios.get(`/api/department/${departmentId}`);
        if (response.status === 200) {
          setNames(response.data.name);
          setDescriptions(response.data.description);
        }
      } catch (error) {
        openNotificationWithIcon('error', t('departments.error_loading_department'));
      }
    };
    fetchDepartment();
  }, [departmentId, open]);

  const handleCancel = () => {
    setOpen(false);
  };

  const handleEdit = async () => {
    setLoading(true);
    try {
      const response = await axios.put(`/api/department/${departmentId}`, {
        name: names,
        description: descriptions,
      });
      if (response.status === 200) {
        openNotificationWithIcon('success', t('departments.edit_success'));
        setClearImagePreview(true);
        setLoading(false);
        await fetchDepartments();
        setOpen(false);
        setClearImagePreview(false);
      }
    } catch (error) {
      openNotificationWithIcon('error', t('departments.error_updating_department'));
    }
  };

  const onImageUpload = (imageDataURL) => {
    // Do something with the image Data
    console.log(imageDataURL);
  };

  const showConfirmationModal = () => {
    setShowConfirmation(true);
  };

  const updateNames = (value, index) => {
    const newNames = { ...names, [languages[index]]: value };
    setNames(newNames);
  };

  const updateDescriptions = (value, index) => {
    const newDescriptions = { ...descriptions, [languages[index]]: value };
    setDescriptions(newDescriptions);
  };

  return (
    <div>
      <Modal
        open={open}
        title={<h2 className="create-department-title">{t('departments.edit_department')}</h2>}
        onCancel={handleCancel}
        centered
        footer={null}
        className="create-department-popup"
        width="750px"
      >
        <form onSubmit={handleEdit} className="create-department-form" id="create-department-form">
          <Row>
            <Col xs={24} sm={24} md={16}>
              {languages.map((language, index) => (
                <>
                  <p className="create-department-name">
                    {t('departments.name_in')}
                    {t(`languages.${language}`)}
                    :
                  </p>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    maxLength={50}
                    showCount
                    value={names[language]}
                    onChange={(e) => updateNames(e.target.value, index)}
                  />
                </>
              ))}
              {languages.map((language, index) => (
                <>
                  <p className="create-department-name">
                    {t('departments.description_in')}
                    {t(`languages.${language}`)}
                    :
                  </p>
                  <TextArea
                    rows={3}
                    value={descriptions[language]}
                    maxLength={500}
                    showCount
                    onChange={(e) => updateDescriptions(e.target.value, index)}
                  />
                </>
              ))}
            </Col>
            <Col xs={24} sm={24} md={8}>
              <div className="create-department-icon">
                <p className="input-icon">{t('jobs.icon')}</p>
                <UploadImage
                  onImageUpload={onImageUpload}
                  clearImagePreview={clearImagePreview}
                />
              </div>
            </Col>
          </Row>
          <Button className="create-department-button" type="button" loading={loading} onClick={showConfirmationModal}>
            {t('departments.edit')}
          </Button>
        </form>
        <ConfirmAction
          open={showConfirmation}
          setOpen={setShowConfirmation}
          onConfirm={handleEdit}
        />
      </Modal>
    </div>
  );
}

EditDepartment.propTypes = {
  open: PropTypes.isRequired,
  setOpen: PropTypes.isRequired,
  departmentId: PropTypes.isRequired,
  fetchDepartments: PropTypes.isRequired,
};

export default EditDepartment;
