// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Create department pop up*/

.upload-image-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--primary-color);
    border-radius: 15px;
    height: 180px;
    max-width: 180px;
    position: relative;
    overflow: hidden;
    margin-left: 25px;
}

.upload-image-container.dragging {
    border: 2px dashed var(--primary-color);
    background-color: rgb(240, 240, 240);
}

.upload-image-container p {
    margin-inline: auto;
}

.upload-text {
    display: flex;
    flex-direction: column;
    font-family: var(--font), sans-serif;
    cursor: pointer;
    text-align: center;
    width: 100%;
    overflow: hidden;
    font-size: 16px;
}

.upload-text .bolded {
    font-weight: bold;
}

.upload-text .underlined {
    text-decoration: underline;
}

.uploaded-image {
    object-fit: contain;
    height: 100%;
    width: 100%;
}

@media (max-width: 767px) {
    .upload-image-container {
        margin-inline: 0;
    }
}
`, "",{"version":3,"sources":["webpack://./src/css/uploadImage.css"],"names":[],"mappings":"AAAA,4BAA4B;;AAE5B;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,sCAAsC;IACtC,mBAAmB;IACnB,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,uCAAuC;IACvC,oCAAoC;AACxC;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,oCAAoC;IACpC,eAAe;IACf,kBAAkB;IAClB,WAAW;IACX,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,mBAAmB;IACnB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["/* Create department pop up*/\r\n\r\n.upload-image-container {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    border: 2px solid var(--primary-color);\r\n    border-radius: 15px;\r\n    height: 180px;\r\n    max-width: 180px;\r\n    position: relative;\r\n    overflow: hidden;\r\n    margin-left: 25px;\r\n}\r\n\r\n.upload-image-container.dragging {\r\n    border: 2px dashed var(--primary-color);\r\n    background-color: rgb(240, 240, 240);\r\n}\r\n\r\n.upload-image-container p {\r\n    margin-inline: auto;\r\n}\r\n\r\n.upload-text {\r\n    display: flex;\r\n    flex-direction: column;\r\n    font-family: var(--font), sans-serif;\r\n    cursor: pointer;\r\n    text-align: center;\r\n    width: 100%;\r\n    overflow: hidden;\r\n    font-size: 16px;\r\n}\r\n\r\n.upload-text .bolded {\r\n    font-weight: bold;\r\n}\r\n\r\n.upload-text .underlined {\r\n    text-decoration: underline;\r\n}\r\n\r\n.uploaded-image {\r\n    object-fit: contain;\r\n    height: 100%;\r\n    width: 100%;\r\n}\r\n\r\n@media (max-width: 767px) {\r\n    .upload-image-container {\r\n        margin-inline: 0;\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
