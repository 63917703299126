// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirm-action-title {
    font-family: var(--font), sans-serif;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 40px;
    padding-right: 10px;
}

.ant-modal-title {
    text-align: start;
}

.confirm-action-modal {
    font-family: var(--font), sans-serif;

}

.confirm-action-button-cancel {
    color: var(--primary-color);
    background-color: var(--secondary-color);
    font-family: var(--font), sans-serif;
    font-weight: 500;
    padding: 7px;
    padding-inline: 10px;
    border-radius: 10px;
    border: 1px solid var(--primary-color);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}


.confirm-action-button-ok {
    color: var(--secondary-color);
    background-color: var(--primary-color);
    font-family: var(--font), sans-serif;
    font-weight: 500;
    margin-right: 10px;
    padding-block: 7px;
    padding-inline: 25px;
    border-radius: 10px;
    border: 1px solid var(--primary-color);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.confirm-action-button-ok:hover {
    background-color: var(--secondary-color);
    color: var(--primary-color);
}

.confirm-action-button-cancel:hover {
    background-color: var(--primary-color);
    color: var(--secondary-color);
}`, "",{"version":3,"sources":["webpack://./src/css/confirmAction.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,eAAe;IACf,gBAAgB;IAChB,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,oCAAoC;;AAExC;;AAEA;IACI,2BAA2B;IAC3B,wCAAwC;IACxC,oCAAoC;IACpC,gBAAgB;IAChB,YAAY;IACZ,oBAAoB;IACpB,mBAAmB;IACnB,sCAAsC;IACtC,eAAe;IACf,gCAAgC;AACpC;;;AAGA;IACI,6BAA6B;IAC7B,sCAAsC;IACtC,oCAAoC;IACpC,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,oBAAoB;IACpB,mBAAmB;IACnB,sCAAsC;IACtC,eAAe;IACf,gCAAgC;AACpC;;AAEA;IACI,wCAAwC;IACxC,2BAA2B;AAC/B;;AAEA;IACI,sCAAsC;IACtC,6BAA6B;AACjC","sourcesContent":[".confirm-action-title {\n    font-family: var(--font), sans-serif;\n    font-size: 20px;\n    font-weight: 400;\n    margin-bottom: 40px;\n    padding-right: 10px;\n}\n\n.ant-modal-title {\n    text-align: start;\n}\n\n.confirm-action-modal {\n    font-family: var(--font), sans-serif;\n\n}\n\n.confirm-action-button-cancel {\n    color: var(--primary-color);\n    background-color: var(--secondary-color);\n    font-family: var(--font), sans-serif;\n    font-weight: 500;\n    padding: 7px;\n    padding-inline: 10px;\n    border-radius: 10px;\n    border: 1px solid var(--primary-color);\n    cursor: pointer;\n    transition: all 0.3s ease-in-out;\n}\n\n\n.confirm-action-button-ok {\n    color: var(--secondary-color);\n    background-color: var(--primary-color);\n    font-family: var(--font), sans-serif;\n    font-weight: 500;\n    margin-right: 10px;\n    padding-block: 7px;\n    padding-inline: 25px;\n    border-radius: 10px;\n    border: 1px solid var(--primary-color);\n    cursor: pointer;\n    transition: all 0.3s ease-in-out;\n}\n\n.confirm-action-button-ok:hover {\n    background-color: var(--secondary-color);\n    color: var(--primary-color);\n}\n\n.confirm-action-button-cancel:hover {\n    background-color: var(--primary-color);\n    color: var(--secondary-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
