import React, { lazy, Suspense, useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Auth from './views/Auth';
import Settings from './views/Settings';
import NoPage from './views/NoPage';
import Home from './views/Home';
import Roles from './views/Roles';
import UserProfile from './views/UserProfile';
import EditRole from './views/EditRole';
import ConfirmAccount from './views/ConfirmAccount';
import UserEditProfile from './views/UserEditProfile';
import UserSetupProfile from './views/UserSetupProfile';
import VerificationPage from './views/VerificationPage';
import RecoverPassword from './views/RecoverPassword';
import Departments from './views/Departments';
import Role from './views/Role';
import UserList from './views/UserList';
import ModifyPassword from './views/ModifyPassword';
import Jobs from './views/Jobs';
import Seasons from './views/Seasons';
import Season from './views/Season';
import CreateSeason from './views/CreateSeason';
import CreateApplicationForms from './views/CreateApplicationForms';
import Applications from './views/Applications';
import EditApplicationForms from './views/EditApplicationForms';

import { AbilityContext } from './Abilities/Can';
import { PermissionProvider } from './contexts/PermissionProvider';

const LoadingScreen = lazy(() => import('./components/Loading'));

function AppRouter() {
  const ability = useContext(AbilityContext);
  return (
    <PermissionProvider>
      <BrowserRouter>
        <Suspense fallback={<LoadingScreen />}>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* Authentication */}
            <Route path="/login" element={<Auth />} />
            <Route path="/register" element={<Auth register />} />
            {/* Settings */}
            {ability.can('read', 'Users') && <Route path="/settings" element={<Settings />} />}
            {/* Jobs */}
            {ability.can('read', 'Jobs') && <Route path="/jobs" element={<Jobs />} />}
            {/* Roles */}
            {ability.can('read', 'Roles') && <Route path="/roles" element={<Roles />} /> }
            {ability.can('read', 'Roles') && <Route path="/role/:id" element={<Role />} />}
            {ability.can('read', 'Roles') && <Route path="/role/:id/edit" element={<EditRole />} />}
            {/* Departments */}
            {ability.can('read', 'all') && <Route path="/departments" element={<Departments />} />}
            {/* Seasons */}
            {ability.can('read', 'Seasons') && <Route path="/seasons" element={<Seasons />} />}
            {ability.can('read', 'Seasons') && <Route path="/season/:id" element={<Season />} />}
            {/* Profile */}
            <Route path="/profile" element={<UserProfile adminView={false} />} />
            <Route path="/profile/edit" element={<UserEditProfile />} />
            <Route path="/profile/setup/:token" element={<UserSetupProfile />} />
            {/* Users */}
            {ability.can('read', 'Users') && <Route path="/users" element={<UserList />} />}
            {ability.can('read', 'Users') && <Route path="/user/:id" element={<UserProfile adminView />} />}
            {ability.can('update', 'Users') && <Route path="/user/:id/edit" element={<UserEditProfile adminView />} /> }
            <Route path="/confirm/:token" element={<ConfirmAccount />} />
            <Route path="/verify" element={<VerificationPage />} />
            <Route path="/recover-password" element={<RecoverPassword />} />
            <Route path="/modify-password" element={<ModifyPassword />} />
            <Route path="/loading" element={<LoadingScreen />} />
            {ability.can('read', 'Seasons') && <Route path="/season/create" element={<CreateSeason />} />}
            {ability.can('create', 'Application Forms') && <Route path="/applicationForm/create" element={<CreateApplicationForms />} />}
            {ability.can('read', 'Application Forms') && <Route path="/applicationForms" element={<Applications />} />}
            {ability.can('update', 'Application Forms') && <Route path="/applicationForm/:id/edit" element={<EditApplicationForms />} />}
            <Route path="*" element={<NoPage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </PermissionProvider>
  );
}

AppRouter.contextType = AbilityContext;

export default AppRouter;
