import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Drawer, notification } from 'antd';
import HamburguerLines from '../images/open_menu_icon_black.svg';
import HomepageIcon from '../images/homepage_icon.svg';
import UsersIcon from '../images/users_icon.svg';
import RolesIcon from '../images/roles_icon.svg';
import PersonalizeIcon from '../images/personalize_icon.svg';
import CandidatesIcon from '../images/candidates_icon.svg';
import SeasonsIcon from '../images/seasons_icon.svg';
import JobsIcon from '../images/jobs_icon.svg';
import DepartmentsIcon from '../images/departments_icon.svg';
import ProfileIcon from '../images/profile_icon.svg';
import TranslateButton from './TranslateButton';
import axios from '../axios';
import '../css/navbar.css';

import { AbilityContext } from '../Abilities/Can';

function NavbarMobile() {
  const companyID = 1;
  const [t] = useTranslation('global');
  const [open, setOpen] = useState(false);
  const [darkLogo, setDarkLogo] = useState('');
  const ability = useContext(AbilityContext);
  const [userId, setUserId] = useState(null);

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: 'Success',
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: 'Error',
        description: message,
      });
    }
  };

  useEffect(() => {
    axios.get(
      '/api/user/profile',
    )
      .then((response) => {
        if (response.status === 200) {
          setUserId(response.data.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  // Navbar items
  const items = [
    {
      label: t('menu.homepage'), key: '1', icon: <img src={HomepageIcon} alt="homepage icon" className="navbar-icon" />, link: '/', canAccess: () => true,
    },
    {
      label: t('menu.profile'), key: '2', icon: <img src={ProfileIcon} alt="profile icon" className="navbar-icon" />, link: ability.can('update', 'Users') ? `/user/${userId}` : '/profile', canAccess: () => true,
    },
    {
      label: t('menu.users'), key: '3', icon: <img src={UsersIcon} alt="users icon" className="navbar-icon" />, link: '/users', canAccess: () => ability.can('read', 'Users'),
    },
    {
      label: t('menu.roles'), key: '4', icon: <img src={RolesIcon} alt="roles icon" className="navbar-icon" />, link: '/roles', canAccess: () => ability.can('read', 'Roles'),
    },
    {
      label: t('menu.personalize'), key: '5', icon: <img src={PersonalizeIcon} alt="personalize icon" className="navbar-icon" />, link: '/settings', canAccess: () => ability.can('read', 'Users'),
    },
    {
      label: t('menu.departments'), key: '6', icon: <img src={DepartmentsIcon} alt="candidates icon" className="navbar-icon" />, link: '/departments', canAccess: () => ability.can('read', 'all'),
    },
    {
      label: t('menu.seasons'), key: '7', icon: <img src={SeasonsIcon} alt="seasons icon" className="navbar-icon" />, link: '/seasons', canAccess: () => ability.can('read', 'Seasons'),
    },
    {
      label: t('menu.jobs'), key: '8', icon: <img src={JobsIcon} alt="jobs icon" className="navbar-icon" />, link: '/jobs', canAccess: () => ability.can('read', 'Jobs'),
    },
    {
      label: t('menu.application_forms'), key: '9', icon: <img src={CandidatesIcon} alt="applicationForms icon" className="navbar-icon" />, link: '/applicationForms', canAccess: () => ability.can('read', 'Application Forms'),
    },
  ];

  const showMenu = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios.get(`/api/settings/company/${companyID}/logos`)
      .then((response) => {
        setDarkLogo(response.data.companyLogoDark);
      })
      .catch((error) => {
        console.error('Error fetching logos:', error);
        openNotificationWithIcon('error', t('settings.logo_fetch_failed'));
      });
  }, []);

  return (
    <div className="navbar-mobile-container">
      <div className="navbar-mobile-header">
        <img src={darkLogo} alt="logo" className="logo-navbar-mobile" />
        <Button
          type="text"
          className="open-menu-mobile"
          icon={<img src={HamburguerLines} className="open-menu-icon" alt="open-menu" />}
          onClick={showMenu}
        />
      </div>
      <Drawer onClose={onClose} open={open} class="menu-drawer" placement="right">
        <TranslateButton className="translate-button-navbar-mobile" />
        <ul className="menu-links-mobile">
          {items.filter((i) => i.canAccess()).map((item) => (
            <li>
              <Link to={item.link}>
                {/* <span>{item.icon}</span> */}
                <span className="menu-link-title-mobile">{item.label}</span>
              </Link>
            </li>
          ))}
        </ul>
      </Drawer>
    </div>
  );
}

export default NavbarMobile;
