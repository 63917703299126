import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Input, DatePicker, ConfigProvider, Checkbox, notification, Select,
} from 'antd';
import { IoIosArrowBack } from 'react-icons/io';
import { AiOutlinePlus } from 'react-icons/ai';
import PropTypes from 'prop-types';
import ptBR from 'antd/locale/pt_BR';
import enUS from 'antd/locale/en_US';
import axios from '../axios';
import ConfirmAction from './ConfirmAction';
import languageStore from '../stores/languageStore';
import '../css/createPhase.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

function CreatePhase({
  phasesData, setPhasesData, currentPage, setCurrentPage, seasonStartDate, seasonEndDate,
}) {
  const [t] = useTranslation('global');

  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';

  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [phoneInterviewCandidatesAvailability, setPhoneInterviewCandidatesAvailability] = useState('');
  const [groupDynamicTime, setGroupDynamicTime] = useState('');
  const [groupDynamicCandidates, setGroupDynamicCandidates] = useState('');
  const [groupDynamicInterviewers, setGroupDynamicInterviewers] = useState('');
  const [groupDynamicPresentMembers, setGroupDynamicPresentMembers] = useState([]);
  const [
    phaseTechnicalProblemFileSubmission,
    setPhaseTechnicalProblemFileSubmission,
  ] = useState(false);
  const [phaseTechnicalProblemFormat, setPhaseTechnicalProblemFormat] = useState([]);
  const [phaseInPersonInterviewTime, setPhaseInPersonInterviewTime] = useState('');
  const [phaseInPersonInterviewCandidates, setPhaseInPersonInterviewCandidates] = useState('');
  const [phaseInPersonInterviewInterviewers, setPhaseInPersonInterviewInterviewers] = useState('');
  const [phaseInPersonInterviewPresentMembers, setPhaseInPersonInterviewPresentMembers] = useState(
    [],
  );
  const [open, setOpen] = useState(false);

  const [departmentOptions, setDepartmentOptions] = useState([]);

  /* eslint-disable */
  const [clearImagePreview, setClearImagePreview] = useState(false);

  const datePickerLanguage = selectedLanguage === 'en' ? enUS : ptBR;

  const defaultPhases = [t('season.phase_phone_interview'), t('season.phase_group_dynamic'), t('season.phase_technical_problem'), t('season.phase_in_person_interview')]; // Array of checkbox values
  const [currentPhases, setCurrentPhases] = useState([]);

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await axios.get('/api/department');
      if (response.status === 200) {
        const departments = response.data;
        const deptOptions = departments.map((element) => ({
          value: element.id,
          label: element.name,
        }));
        setDepartmentOptions(deptOptions);
      }
    } catch (error) {
      console.error('Error during departments fetch:', error);
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, []);

  const onImageUpload = (imageDataURL) => {
    // Do something with the image Data
    console.log(imageDataURL);
  };

  const handleSelectChange = (value) => {
    setCurrentPhases([value]); 
    setType(value); 
  };

  // Function to add a new phase to phasesData
  const addPhase = () => {
    // check if all mandatory fields are filled
    if (!name || !description || !beginDate || !endDate) {
      openNotificationWithIcon('error', t('season.fill_all_fields'));
      return;
    }
    // Validate phase dates
    if (beginDate.isBefore(seasonStartDate) || endDate.isAfter(seasonEndDate)) {
      openNotificationWithIcon('error', t('season.invalid_phase_dates'));
      return;
    }
    const newPhase = {
      name,
      type,
      description,
      beginDate,
      endDate,
      phoneInterviewCandidatesAvailability,
      groupDynamicTime,
      groupDynamicCandidates,
      groupDynamicInterviewers,
      groupDynamicPresentMembers,
      phaseTechnicalProblemFileSubmission,
      phaseTechnicalProblemFormat,
      phaseInPersonInterviewTime,
      phaseInPersonInterviewCandidates,
      phaseInPersonInterviewInterviewers,
      phaseInPersonInterviewPresentMembers,
    };
    // Update phasesData with the new phase
    setPhasesData([...phasesData, newPhase]);

    setCurrentPage(currentPage + 1);

    // Clear the form fields
    setName('');
    setType('');
    setDescription('');
    setBeginDate(null);
    setEndDate(null);
    setPhoneInterviewCandidatesAvailability('');
    setGroupDynamicTime('');
    setGroupDynamicCandidates('');
    setGroupDynamicInterviewers('');
    setGroupDynamicPresentMembers([]);
    setPhaseTechnicalProblemFileSubmission(false);
    setPhaseTechnicalProblemFormat([]);
    setPhaseInPersonInterviewTime('');
    setPhaseInPersonInterviewCandidates('');
    setPhaseInPersonInterviewInterviewers('');
    setPhaseInPersonInterviewPresentMembers([]);

    setCurrentPhases([]);

    openNotificationWithIcon('success', t('season.phase_added'));
  };

  const handleContinue = (event) => {
    event.preventDefault();
    setCurrentPage(currentPage + 1);
  };

  const handleBack = (event) => {
    event.preventDefault();
    setCurrentPage(currentPage - 1);
  };

  const handleCheckboxGroupDynamicMembers = (member, checked) => {
    const updatedMembers = checked
      ? [...groupDynamicPresentMembers, member]
      : groupDynamicPresentMembers.filter((val) => val !== member);
    setGroupDynamicPresentMembers(updatedMembers);
  };

  const handleCheckboxTechnicalProblemFormat = (format, checked) => {
    const updatedFormats = checked
      ? [...phaseTechnicalProblemFormat, format]
      : phaseTechnicalProblemFormat.filter((val) => val !== format);
    setPhaseTechnicalProblemFormat(updatedFormats);
  };

  const handleCheckboxInPersonInterviewMembers = (member, checked) => {
    const updatedMembers = checked
      ? [...phaseInPersonInterviewPresentMembers, member]
      : phaseInPersonInterviewPresentMembers.filter((val) => val !== member);
    setPhaseInPersonInterviewPresentMembers(updatedMembers);
  };

  const showModal = () => {
    setOpen(true);
  }

  return (
    <div>
      <div className="create-phase-header">
        <button onClick={handleBack} className="go-back-btn">
          <IoIosArrowBack />
        </button>
        <h1 className="create-phase-title">{t('season.phase_title')}</h1>
      </div>
      <div className="create-phase-name-div">
        <p className="create-phase-name-label">
          {t('season.name')}
          :
        </p>
        <Input
          className="create-phase-name-input"
          type="text"
          id="name"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="create-phase-description-div">
        <p className="create-phase-description-label">
          {t('season.phase_description_label')}
        </p>
        <Input.TextArea
          className="create-phase-description-input"
          type="text"
          id="description"
          name="description"
          value={description}
          placeholder={t('season.phase_description_label_placeholder')}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className="create-phase-begin-date-div">
        <p className="create-phase-begin-date-label">
          {t('season.date')}
          :
        </p>
         <ConfigProvider locale={datePickerLanguage}>
          <RangePicker
            className="create-phase-begin-date-input"
            value={[beginDate, endDate]}
            placeholder={[t('season.start_date'), t('season.end_date')]}
            disabledDate={(current) => current && (current.isBefore(seasonStartDate, 'day') || current.isAfter(seasonEndDate, 'day'))}
            onChange={(dates) => {
              const [start, end] = dates;
              setBeginDate(start);
              setEndDate(end);
            }}
          />
        </ConfigProvider>
      </div>
      <div className="create-phase-select-phases-types">
        <Select
          placeholder={t('season.phase_select')}
          onChange={handleSelectChange}
          className="create-phase-select-phases-input"
        >
          {defaultPhases.map((phase) => (
            <Option key={phase} value={phase}>
              {t(`${phase}`)}
            </Option>
          ))}
        </Select>
      </div>
      {currentPhases.includes(t('season.phase_phone_interview')) && (
        <div className="phase-phone-interview-div">
          <p className="phase-phone-interview-candidates-availability">
            {t('season.phase_phone_interview_candidates_availability')}
            <span className="submit-button">
              <Checkbox onChange={(e) => setPhoneInterviewCandidatesAvailability(e.target.value)}></Checkbox>
            </span>
          </p>
        </div>
      )}
      {currentPhases.includes(t('season.phase_group_dynamic')) && (
        <div className="phase-group-dynamic-div">
          <div className="phase-group-dynamic-time-div">
            <p className="phase-group-dynamic-time">
              {t('season.phase_group_dynamic_time')}
              :
            </p>
            <div className="phase-group-dynamic-time-input-div">
              <input
                className="phase-group-dynamic-time-input"
                type="number"
                min="0"
                value={groupDynamicTime}
                onChange={(e) => setGroupDynamicTime(e.target.value)}
              />
            <span className="phase-group-dynamic-time-input-inside-label">{t('season.phase_group_dynamic_minutes')}</span>
            </div>
          </div>
          <div className="phase-group-dynamic-n-candidates-div">
            <p className="phase-group-dynamic-n-candidates">
              {t('season.phase_group_dynamic_candidates_number')}
              :
            </p>
            <input
              className="phase-group-dynamic-n-candidates-input"
              type="number"
              min="0"
              value={groupDynamicCandidates}
              onChange={(e) => setGroupDynamicCandidates(e.target.value)}
            />
          </div>
          <div className="phase-group-dynamic-n-interviewers-div">
            <p className="phase-group-dynamic-n-interviewers">
              {t('season.phase_group_dynamic_interviewers_number')}
              :
            </p>
            <input
              className="phase-group-dynamic-n-interviewers-input"
              type="number"
              min="0"
              value={groupDynamicInterviewers}
              onChange={(e) => setGroupDynamicInterviewers(e.target.value)}
            />
          </div>
          <div className="phase-group-dynamic-present-members-div">
            <p className="phase-group-dynamic-present-members">
              {t('season.phase_group_dynamic_present_members')}
              :
            </p>
            <div className="phase-group-dynamic-present-members-checkboxes">
              <Checkbox onChange={(e) => handleCheckboxGroupDynamicMembers("directors", e.target.checked)}>{t('season.phase_group_dynamic_present_members_directors')}</Checkbox>
              <Checkbox onChange={(e) => handleCheckboxGroupDynamicMembers("membersDiversity", e.target.checked)}>{t('season.phase_group_dynamic_present_members_diversity')}</Checkbox>
            </div>
          </div>
        </div>
      )}
      {currentPhases.includes(t('season.phase_technical_problem')) && (
        <div className="phase-technical-problem-div">
          <div className="phase-technical-problem-submit-div">
            <p className="phase-technical-problem-submit">
              {t('season.phase_technical_problem_file_submission')}
              <span className="submit-button">
                <Checkbox onChange={(e) => setPhaseTechnicalProblemFileSubmission(e.target.checked)}></Checkbox>
              </span>
            </p>
          </div>
          {phaseTechnicalProblemFileSubmission && (
            <>
              <div className="phase-technical-problem-format-div">
                <p className="phase-technical-problem-format">
                  {t('season.phase_technical_problem_file_format')}
                  :
                </p>
                <div className="phase-technical-problem-format-checkboxes">
                  <Checkbox onChange={(e) => handleCheckboxTechnicalProblemFormat("pdf", e.target.checked)}>PDF</Checkbox>
                  <Checkbox onChange={(e) => handleCheckboxTechnicalProblemFormat("jpg", e.target.checked)}>JPG</Checkbox>
                  <Checkbox onChange={(e) => handleCheckboxTechnicalProblemFormat("png", e.target.checked)}>PNG</Checkbox>
                  <Checkbox onChange={(e) => handleCheckboxTechnicalProblemFormat("other", e.target.checked)}>Outro</Checkbox>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {currentPhases.includes(t('season.phase_in_person_interview')) && (
        <div className="phase-in-person-interview-div">
        <div className="phase-in-person-interview-time-div">
          <p className="phase-in-person-interview-time">
            {t('season.phase_in_person_interview_time')}
            :
          </p>
          <div className="phase-in-person-interview-time-input-div">
            <input
              className="phase-in-person-interview-time-input"
              type="number"
              min="0"
              value={phaseInPersonInterviewTime}
              onChange={(e) => setPhaseInPersonInterviewTime(e.target.value)}
            />
          <span className="phase-in-person-interview-time-input-inside-label">{t('season.phase_group_dynamic_minutes')}</span>
          </div>
        </div>
        <div className="phase-in-person-interview-n-candidates-div">
          <p className="phase-in-person-interview-n-candidates">
            {t('season.phase_group_dynamic_candidates_number')}
            :
          </p>
          <input
            className="phase-in-person-interview-n-candidates-input"
            type="number"
            min="0"
            value={phaseInPersonInterviewCandidates}
            onChange={(e) => setPhaseInPersonInterviewCandidates(e.target.value)}
          />
        </div>
        <div className="phase-in-person-interview-n-interviewers-div">
          <p className="phase-in-person-interview-n-interviewers">
            {t('season.phase_group_dynamic_interviewers_number')}
            :
          </p>
          <input
            className="phase-in-person-interview-n-interviewers-input"
            type="number"
            min="0"
            value={phaseInPersonInterviewInterviewers}
            onChange={(e) => setPhaseInPersonInterviewInterviewers(e.target.value)}
          />
        </div>
        <div className="phase-in-person-interview-present-members-div">
          <p className="phase-in-person-interview-present-members">
            {t('season.phase_group_dynamic_present_members')}
            :
          </p>
          <div className="phase-in-person-interview-present-members-checkboxes">
            <Checkbox onChange={(e) => handleCheckboxInPersonInterviewMembers("directors", e.target.checked)}>{t('season.phase_group_dynamic_present_members_directors')}</Checkbox>
            {departmentOptions.map((department) => (
              <Checkbox onChange={(e) => handleCheckboxInPersonInterviewMembers(department.label, e.target.checked)}>{department.label[selectedLanguage]}</Checkbox>
            ))}
          </div>
        </div>
      </div>
      )}
      <button type="button" onClick={showModal} className="add-phase-btn">
        {t('season.add_phase')}
        <AiOutlinePlus />
      </button>
      <ConfirmAction
        open={open}
        setOpen={setOpen}
        onConfirm={addPhase}
      />
    </div>
  );
}

CreatePhase.propTypes = {
  phasesData: PropTypes.array.isRequired,
  setPhasesData: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  seasonStartDate: PropTypes.object.isRequired,
  seasonEndDate: PropTypes.object.isRequired,
};

export default CreatePhase;
