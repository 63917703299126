import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Input, Modal, Button, notification, Col, Row, Select,
} from 'antd';
import PropTypes from 'prop-types';
import axios from '../axios';
import UploadImage from './UploadImage';
import ConfirmAction from './ConfirmAction';
import languageStore from '../stores/languageStore';
import '../css/createJob.css';

const { TextArea } = Input;

function CreateJob({
  open, setOpen, jobId, fetchJobs,
}) {
  const [t] = useTranslation('global');

  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';

  const languages = ['pt', 'en'];

  const [names, setNames] = useState(Array(languages.length).fill(''));
  const [descriptions, setDescriptions] = useState(Array(languages.length).fill(''));
  const [shortDescriptions, setShortDescriptions] = useState(Array(languages.length).fill(''));
  const [departmentID, setDepartment] = useState('');
  const [departments, setDepartments] = useState([]);
  const [clearImagePreview, setClearImagePreview] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!open) return;
    const fetchJob = async () => {
      try {
        const response = await axios.get(`/api/job/${jobId}`);
        if (response.status === 200) {
          setNames(response.data.name);
          setDescriptions(response.data.description);
          setShortDescriptions(response.data.shortDescription);
          setDepartment(response.data.departmentID);
        }
      } catch (error) {
        openNotificationWithIcon('error', t('departments.error_loading_department'));
      }
    };
    const fetchDepartments = async () => {
      try {
        const response = await axios.get('/api/department');
        const parsedDepartmentsData = response.data;
        parsedDepartmentsData.forEach((department, index) => {
          parsedDepartmentsData[index].name = department.name[selectedLanguage];
          parsedDepartmentsData[index].description = department.description[selectedLanguage];
        });
        setDepartments(parsedDepartmentsData);
      } catch (error) {
        console.error('Error fetching departments:', error);
      }
    };
    fetchJob();
    fetchDepartments();
  }, [jobId, open]);

  function mergeArraysToObject(arr) {
    const obj = {};
    languages.forEach((element, index) => {
      obj[element] = arr[index];
    });
    return obj;
  }

  useEffect(() => {
    fetchJobs();
  }, [loading]);

  const handleEdit = async () => {
    setLoading(true);
    const namesArray = Object.values(names);
    const descriptionsArray = Object.values(descriptions);
    const shortDescriptionsArray = Object.values(shortDescriptions);
    const jsonNames = mergeArraysToObject(namesArray);
    const jsonDescriptions = mergeArraysToObject(descriptionsArray);
    const jsonShortDescriptions = mergeArraysToObject(shortDescriptionsArray);
    try {
      const response = await axios.put(`/api/job/${jobId}`, {
        name: jsonNames,
        description: jsonDescriptions,
        shortDescription: jsonShortDescriptions,
        companyID: '1',
        departmentID,
      });
      if (response.status === 200) {
        openNotificationWithIcon('success', t('jobs.edit_success'));
        setClearImagePreview(true);
        setLoading(false);
        await fetchJobs();
        setOpen(false);
        setClearImagePreview(false);
      }
    } catch (error) {
      openNotificationWithIcon('error', t('departments.error_updating_department'));
    }
  };

  const onImageUpload = (imageDataURL) => {
    // Do something with the image Data
    console.log(imageDataURL);
  };

  const showConfirmationModal = () => {
    setShowConfirmation(true);
  };

  const updateNames = (value, index) => {
    const newNames = { ...names, [languages[index]]: value };
    setNames(newNames);
  };

  const updateDescriptions = (value, index) => {
    const newDescriptions = { ...descriptions, [languages[index]]: value };
    setDescriptions(newDescriptions);
  };

  const updateShortDescriptions = (value, index) => {
    const newShortDescriptions = { ...shortDescriptions, [languages[index]]: value };
    setShortDescriptions(newShortDescriptions);
  };

  return (
    <div>
      <Modal
        open={open}
        title={<h2 className="create-job-title">{t('jobs.edit_job')}</h2>}
        onCancel={handleCancel}
        centered
        footer={null}
        className="create-job-popup"
        width="750px"
      >
        <form onSubmit={handleEdit} className="create-job-form" id="create-job-form">
          <Row>
            <Col xs={24} sm={24} md={16}>
              {languages.map((language, index) => (
                <>
                  <p className="create-job-name">
                    {t('jobs.name_in')}
                    {t(`languages.${language}`)}
                    :
                  </p>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    maxLength={50}
                    showCount
                    value={names[language]}
                    onChange={(e) => updateNames(e.target.value, index)}
                  />
                </>
              ))}
              {languages.map((language, index) => (
                <>
                  <p className="create-job-name">
                    {t('jobs.description_in')}
                    {t(`languages.${language}`)}
                    :
                  </p>
                  <TextArea
                    rows={3}
                    value={descriptions[language]}
                    maxLength={500}
                    showCount
                    onChange={(e) => updateDescriptions(e.target.value, index)}
                  />
                </>
              ))}
              {languages.map((language, index) => (
                <>
                  <p className="create-job-name">
                    {t('jobs.short_description_in')}
                    {t(`languages.${language}`)}
                    :
                  </p>
                  <TextArea
                    rows={3}
                    value={shortDescriptions[language]}
                    maxLength={200}
                    showCount
                    onChange={(e) => updateShortDescriptions(e.target.value, index)}
                  />
                </>
              ))}
              <p className="create-job-name">{t('jobs.department')}</p>
              <Select
                className="create-job"
                value={departmentID}
                onChange={(e) => setDepartment(e)}
                options={departments.map((d) => ({ value: d.id, label: d.name }))}
                optionFilterProp="children"
                filterOption={filterOption}
                showSearch
              />
            </Col>
            <Col xs={24} sm={24} md={8}>
              <div className="create-job-icon">
                <p className="input-icon">{t('jobs.icon')}</p>
                <UploadImage
                  onImageUpload={onImageUpload}
                  clearImagePreview={clearImagePreview}
                />
              </div>
            </Col>
          </Row>
          <Button className="create-job-button" type="button" loading={loading} onClick={showConfirmationModal}>
            {t('departments.edit')}
          </Button>
        </form>
        <ConfirmAction
          open={showConfirmation}
          setOpen={setShowConfirmation}
          onConfirm={handleEdit}
        />
      </Modal>
    </div>
  );
}

CreateJob.propTypes = {
  open: PropTypes.isRequired,
  setOpen: PropTypes.isRequired,
  jobId: PropTypes.isRequired,
  fetchJobs: PropTypes.isRequired,
};

export default CreateJob;
