import React, { useState, useEffect } from 'react';
import {
  ColorPicker,
  notification,
  Select,
  Row,
  Upload,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PhoneInput } from 'react-international-phone';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { LuPencil } from 'react-icons/lu';
import axios from '../axios';
import Navbar from '../components/Navbar';
import ConfirmAction from '../components/ConfirmAction';
import NavbarMobile from '../components/NavbarMobile';
import '../css/settings.css';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

function Settings() {
  const navigate = useNavigate();
  const [primaryColor, setPrimaryColor] = useState('#001635'); // Initialize with a default color
  const [secondaryColor, setSecondaryColor] = useState('#FFFBFB');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [font, setFont] = useState('');
  const [fontPreview, setFontPreview] = useState('');
  const [loading, setLoading] = useState(false);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [lightLogoFileList, setLightLogoFileList] = useState([]);
  const [darkLogoFileList, setDarkLogoFileList] = useState([]);
  const [lightLogoUrl, setLightLogoUrl] = useState('');
  const [darkLogoUrl, setDarkLogoUrl] = useState('');

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const isValid = isPhoneValid(phone);
  const [formattedPhone, setFormattedPhone] = useState('');
  const [isPhoneFilled, SetIsPhoneFilled] = useState('');
  const [address, setAddress] = useState('');

  const [linksData, setLinksData] = useState({
    instagramLink: '',
    linkedinLink: '',
    facebookLink: '',
    twitterLink: '',
    youtubeLink: '',
    tiktokLink: '',
  });

  const [t] = useTranslation('global');

  const handleLinksChange = (e) => {
    const { name, value } = e.target;
    setLinksData({
      ...linksData,
      [name]: value,
    });
  };

  const companyID = 1;

  const webSafeFonts = [
    {
      value: 'Arial, Helvetica, sans-serif',
      label: 'Arial',
    },
    {
      value: 'Courier New, Courier, monospace',
      label: 'Courier New',
    },
    {
      value: 'Georgia, serif',
      label: 'Georgia',
    },
    {
      value: 'Impact, Charcoal, sans-serif',
      label: 'Impact',
    },
    {
      value: 'Lucida Sans Unicode, Lucida Grande, sans-serif',
      label: 'Lucida Sans Unicode',
    },
    {
      value: 'Montserrat',
      label: 'Montserrat',
    },
    {
      value: 'Palatino Linotype, Book Antiqua, Palatino, serif',
      label: 'Palatino Linotype',
    },
    {
      value: 'Times New Roman, Times, serif',
      label: 'Times New Roman',
    },
    {
      value: 'Trebuchet MS, Helvetica, sans-serif',
      label: 'Trebuchet MS',
    },
    {
      value: 'Verdana, Geneva, sans-serif',
      label: 'Verdana',
    },
  ];

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: 'Success',
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: 'Error',
        description: message,
      });
    }
  };

  useEffect(() => {
    axios.get(`/api/settings/company/${companyID}/logos`)
      .then((response) => {
        setLightLogoUrl(response.data.companyLogoLight);
        setDarkLogoUrl(response.data.companyLogoDark);
      })
      .catch((error) => {
        console.error('Error fetching logos:', error);
        openNotificationWithIcon('error', t('settings.logo_fetch_failed'));
      });
  }, [companyID, t]);

  const handlePhoneChange = (value, country) => {
    setPhone(value);
    setFormattedPhone(country.inputValue);
  };

  const handleLightLogoChange = (info) => {
    const file = info.fileList[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setLightLogoUrl(e.target.result);
    };
    reader.readAsDataURL(file.originFileObj);
    setLightLogoFileList(info.fileList);
  };

  const handleDarkLogoChange = (info) => {
    const file = info.fileList[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      setDarkLogoUrl(e.target.result);
    };
    reader.readAsDataURL(file.originFileObj);
    setDarkLogoFileList(info.fileList);
  };
  const handleImageUpload = async () => {
    const formData = new FormData();
    if (lightLogoFileList.length > 0) {
      formData.append('lightLogo', lightLogoFileList[0].originFileObj);
    }
    if (darkLogoFileList.length > 0) {
      formData.append('darkLogo', darkLogoFileList[0].originFileObj);
    }

    try {
      await axios.post(`/api/upload/company/${companyID}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      console.error('Error uploading logos:', error);
      openNotificationWithIcon('error', t('settings.logo_update_failed'));
    }
  };

  useEffect(() => {
    axios.get('/api/user/profile')
      .then(() => {
        // setIsLoggedIn(true);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openNotificationWithIcon('error', t('auth.need_login'));
          navigate('/login');
        } else if (error.response.status === 403) {
          openNotificationWithIcon('error', t('auth.not_authorized'));
          navigate('/login');
        }
      });
  }, []);

  useEffect(() => {
    // Fetch settings from the API
    axios.get(`/api/settings/${companyID}`)
      .then((response) => {
        setPrimaryColor(response.data.primaryColor);
        setSecondaryColor(response.data.secondaryColor);
        setFont(response.data.font);
        setEmail(response.data.email);
        setPhone(response.data.phone);
        setAddress(response.data.address);
        setLinksData(response.data.linksData);
      })
      .catch((error) => {
        console.error('Error fetching settings:', error);
      });
  }, []);

  const handleFontChange = (value) => {
    setFont(value);
    // Update the font preview
    setFontPreview(value);
  };

  const handlePrimColorChange = (value) => {
    setPrimaryColor(value.toHexString());
    setFont(font);
    setFontPreview(font);
  };

  const handleSecColorChange = (value) => {
    setSecondaryColor(value.toHexString());
    setFont(font);
    setFontPreview(font);
  };

  const handleUpdate = async () => {
    setLoading(true);
    if (!isValid && isPhoneFilled) {
      openNotificationWithIcon('error', t('settings.invalid_phone'));
      setLoading(false);
      return;
    }
    const root = document.documentElement;
    handleImageUpload();
    try {
      await axios.post(
        '/api/settings/update',
        {
          companyID,
          primaryColor,
          secondaryColor,
          font,
          email,
          phone,
          formattedPhone,
          address,
          linksData,
        },
      );
      root.style.setProperty('--primary-color', primaryColor);
      root.style.setProperty('--secondary-color', secondaryColor);
      root.style.setProperty('--font', font);
      setLoading(false);
      // Sucessfully updated all settings
      openNotificationWithIcon('success', t('settings.success_notification'));
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };

  const handleSettingsConfirmation = () => {
    handleUpdate();
  };

  const showConfirmationModal = () => {
    setShowConfirmation(true);
  };

  useEffect(() => {
    const aux = formattedPhone.split(' ');
    const auxPhone = aux[1];
    SetIsPhoneFilled(auxPhone);
  }, [formattedPhone]);

  return (
    <div>
      <Row>
        <Navbar />
        <NavbarMobile />
        <div className="settings-page-container">
          <form onSubmit={handleUpdate}>
            <div className="settings-container">
              <h2 className="settings-title">{t('settings.title')}</h2>
              <div className="settings-content">
                <div className="settings-font-div">
                  <h3 className="settings-section">{t('settings.typography')}</h3>
                  <div className="settings-font-inner-div">
                    <div className="settings-choose-font-div">
                      <p className="settings-font">{t('settings.font')}</p>
                      <Select
                        showSearch
                        placeholder="Select a font"
                        optionFilterProp="children"
                        value={font}
                        onChange={handleFontChange}
                        filterOption={filterOption}
                        options={webSafeFonts}
                        variant="borderless"
                        popupMatchSelectWidth={false}
                        className="settings-select-font"
                      />
                    </div>
                    <div className="settings-font-aa-div">
                      <p className="settings-font-aa-bold" style={{ fontFamily: fontPreview }}>Aa</p>
                      <p className="settings-font-aa-light" style={{ fontFamily: fontPreview }}>Aa</p>
                    </div>
                    <div className="settings-font-abcde-div">
                      <p className="settings-font-abcde-lowercase" style={{ fontFamily: fontPreview }}>abcde</p>
                      <p className="settings-font-abcde-uppercase" style={{ fontFamily: fontPreview }}>ABCDE</p>
                      <p className="settings-font-nums" style={{ fontFamily: fontPreview }}>012345</p>
                    </div>
                  </div>
                </div>
                <div className="settings-color-div">
                  <h3 className="settings-section">{t('settings.logos_title')}</h3>
                  <div className="logos-section">
                    <div className="logo-container">
                      <span className="logo_background_type">
                        {t('settings.light_background')}
                      </span>
                      <div className="logo-preview dark">
                        <Upload
                          className="dark-logo-upload"
                          name="darkLogo"
                          listType="picture-card"
                          beforeUpload={() => false}
                          onChange={handleDarkLogoChange}
                          fileList={darkLogoFileList}
                          showUploadList={false}
                          accept="image/png, image/jpeg, image/jpg"
                        >
                          {darkLogoUrl ? (
                            <img src={darkLogoUrl} alt="Dark Logo" />
                          ) : (
                            <div className="logo-placeholder">{t('settings.no_dark_logo')}</div>
                          )}
                        </Upload>
                      </div>
                    </div>
                    <div className="logo-container">
                      <span className="logo_background_type">
                        {t('settings.dark_background')}
                      </span>
                      <div className="logo-preview light">
                        <Upload
                          className="light-logo-upload"
                          name="lightLogo"
                          listType="picture-card"
                          beforeUpload={() => false}
                          onChange={handleLightLogoChange}
                          fileList={lightLogoFileList}
                          showUploadList={false}
                          accept="image/png, image/jpeg, image/jpg"
                        >
                          {lightLogoUrl ? (
                            <img src={lightLogoUrl} alt="Light Logo" />
                          ) : (
                            <div className="logo-placeholder">{t('settings.no_dark_logo')}</div>
                          )}
                        </Upload>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="settings-footer-links-div">
                  <div className="settings-footer-section">
                    <h3 className="settings-footer-section-title">{t('settings.footer')}</h3>
                    <div className="settings-footer-email-section">
                      <p className="settings-footer-email">{t('settings.email')}</p>
                      <div className="settings-footer-section-input-div">
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={email}
                          placeholder={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="settings-footer-section-input"
                        />
                        <LuPencil className="settings-footer-section-input-icon" />
                      </div>
                    </div>
                    <div className="settings-footer-phone-section">
                      <p className="settings-footer-phone">{t('settings.phone_number')}</p>
                      <PhoneInput
                        defaultCountry="pt"
                        value={phone}
                        placeholder={phone}
                        onChange={handlePhoneChange}
                      />
                      <div className={`invalid-phone-message ${(isPhoneFilled && !isValid) ? 'visible' : ''}`}>{t('settings.invalid_phone')}</div>
                    </div>
                    <div className="settings-footer-address-section">
                      <p className="settings-footer-address">{t('settings.address')}</p>
                      <div className="settings-footer-section-input-div">
                        <input
                          type="text"
                          id="address"
                          name="address"
                          maxLength={50}
                          value={address}
                          placeholder={address}
                          onChange={(e) => setAddress(e.target.value)}
                          className="settings-footer-section-input"
                        />
                        <LuPencil className="settings-footer-section-input-icon" />
                      </div>
                    </div>
                  </div>
                  <div className="settings-links-section">
                    <h3 className="settings-links-section-title">{t('settings.links')}</h3>
                    <div className="settings-footer-links-container">
                      <div className="settings-footer-link-column">
                        <div className="settings-footer-link">
                          <p className="settings-footer-link-label">{t('settings.insta_link')}</p>
                          <div className="settings-footer-section-input-div">
                            <input
                              type="text"
                              id="instagramLink"
                              name="instagramLink"
                              value={linksData?.instagramLink || ''}
                              onChange={handleLinksChange}
                              placeholder={linksData?.instagramLink || ''}
                              className="settings-footer-section-input"
                            />
                            <LuPencil className="settings-footer-section-input-icon" />
                          </div>
                        </div>
                        <div className="settings-footer-link">
                          <p className="settings-footer-link-label">{t('settings.linkedin_link')}</p>
                          <div className="settings-footer-section-input-div">
                            <input
                              type="text"
                              id="linkedinLink"
                              name="linkedinLink"
                              value={linksData?.linkedinLink || ''}
                              placeholder={linksData?.linkedinLink || ''}
                              onChange={handleLinksChange}
                              className="settings-footer-section-input"
                            />
                            <LuPencil className="settings-footer-section-input-icon" />
                          </div>
                        </div>
                        <div className="settings-footer-link">
                          <p className="settings-footer-link-label">{t('settings.facebook_link')}</p>
                          <div className="settings-footer-section-input-div">
                            <input
                              type="text"
                              id="facebookLink"
                              name="facebookLink"
                              value={linksData?.facebookLink || ''}
                              placeholder={linksData?.facebookLink || ''}
                              onChange={handleLinksChange}
                              className="settings-footer-section-input"
                            />
                            <LuPencil className="settings-footer-section-input-icon" />
                          </div>
                        </div>
                      </div>
                      <div className="settings-footer-link-column">
                        <div className="settings-footer-link">
                          <p className="settings-footer-link-label">{t('settings.twitter_link')}</p>
                          <div className="settings-footer-section-input-div">
                            <input
                              type="text"
                              id="twitterLink"
                              name="twitterLink"
                              value={linksData?.twitterLink || ''}
                              placeholder={linksData?.twitterLink || ''}
                              onChange={handleLinksChange}
                              className="settings-footer-section-input"
                            />
                            <LuPencil className="settings-footer-section-input-icon" />
                          </div>
                        </div>
                        <div className="settings-footer-link">
                          <p className="settings-footer-link-label">{t('settings.youtube_link')}</p>
                          <div className="settings-footer-section-input-div">
                            <input
                              type="text"
                              id="youtubeLink"
                              name="youtubeLink"
                              value={linksData?.youtubeLink || ''}
                              placeholder={linksData?.youtubeLink || ''}
                              onChange={handleLinksChange}
                              className="settings-footer-section-input"
                            />
                            <LuPencil className="settings-footer-section-input-icon" />
                          </div>
                        </div>
                        <div className="settings-footer-link">
                          <p className="settings-footer-link-label">{t('settings.tiktok_link')}</p>
                          <div className="settings-footer-section-input-div">
                            <input
                              type="text"
                              id="tiktokLink"
                              name="tiktokLink"
                              value={linksData?.tiktokLink || ''}
                              placeholder={linksData?.tiktokLink || ''}
                              onChange={handleLinksChange}
                              className="settings-footer-section-input"
                            />
                            <LuPencil className="settings-footer-section-input-icon" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="settings-colors-div">
                  <h3 className="settings-section">{t('settings.colors')}</h3>
                  <p className="settings-description">
                    {t('settings.primary_color')}
                    :
                  </p>
                  <ColorPicker
                    className="color-picker"
                    size="large"
                    showText
                    disabledAlpha
                    value={primaryColor}
                    onChange={handlePrimColorChange}
                  />
                  <br />
                  <p className="settings-description">
                    {t('settings.secondary_color')}
                    :
                  </p>
                  <ColorPicker
                    className="color-picker"
                    size="large"
                    showText
                    disabledAlpha
                    value={secondaryColor}
                    onChange={handleSecColorChange}
                  />
                </div>
              </div>
            </div>
            <button className="update-settings-button" type="button" loading={loading} onClick={showConfirmationModal}>
              {t('settings.update_button')}
            </button>
          </form>
          <ConfirmAction
            open={showConfirmation}
            setOpen={setShowConfirmation}
            onConfirm={handleSettingsConfirmation}
          />
        </div>
      </Row>
    </div>
  );
}

export default Settings;
