import React from 'react';
import PropTypes from 'prop-types';
import { IoIosArrowBack } from 'react-icons/io';

function CustomLeftArrow({ onClick }) {
  return (
    <button className="custom-arrow-left" aria-label="Save" type="button" onClick={() => onClick()}>
      <IoIosArrowBack />
    </button>
  );
}

CustomLeftArrow.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CustomLeftArrow;
