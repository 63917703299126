import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Row, Table, Input, notification,
} from 'antd';
import { useNavigate } from 'react-router-dom';
import axios from '../axios';
import Navbar from '../components/Navbar';
import NavbarMobile from '../components/NavbarMobile';
import DeleteIcon from '../images/delete_icon.svg';
import EditIcon from '../images/edit_icon.svg';
import ConfirmDelete from '../components/ConfirmDelete';
import '../css/application.css';

import { AbilityContext } from '../Abilities/Can';

function Applications() {
  const [t] = useTranslation('global');
  const navigate = useNavigate();
  const [applicationForms, setApplicationForms] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [hoveredRowKey, setHoveredRowKey] = useState(null);
  const ability = useContext(AbilityContext);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [applicationIdToDelete, setApplicationIdToDelete] = useState(null);
  const [applicationNameToDelete, setApplicationNameToDelete] = useState('');

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  const fetchApplicationForms = async () => {
    try {
      const response = await axios.get('/api/applicationForm');
      const applicationFormsData = response.data;
      setApplicationForms(applicationFormsData);
    } catch (error) {
      console.error('Error fetching Application Forms:', error);
    }
  };

  const fetchSeasons = async () => {
    try {
      const response = await axios.get('/api/season');
      const seasonsData = response.data;
      setSeasons(seasonsData);
    } catch (error) {
      console.error('Error fetching Seasons:', error);
    }
  };

  useEffect(() => {
    fetchApplicationForms();
    fetchSeasons();
  }, []);

  useEffect(() => {
    if (applicationForms.length > 0 && seasons.length > 0) {
      const applicationFormsMap = applicationForms.reduce((acc, form) => {
        acc[form.id] = { ...form, seasons: [] };
        return acc;
      }, {});

      seasons.forEach((season) => {
        const applicationForm = applicationFormsMap[season.applicationFormsID];
        if (applicationForm) {
          applicationForm.seasons.push(season.name);
        }
      });

      const data = Object.values(applicationFormsMap).map((form) => ({
        id: form.id,
        name: form.name,
        season: form.seasons.length > 0 ? form.seasons.join(', ') : t('applicationForm.no_associated'),
      }));

      setCombinedData(data);
      setFilteredData(data);
    }
  }, [applicationForms, seasons, t]);

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['3', '5', '8', '10', '15', '20', '30', '50'],
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        ...pagination,
      },
      filters,
      sorter,
    });
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filtered = combinedData.filter(
      (item) => item.name.toLowerCase().includes(value)
      || item.season.toLowerCase().includes(value),
    );
    setFilteredData(filtered);
  };

  const columns = [
    {
      title: t('applicationForm.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: '50%',
    },
    {
      title: t('applicationForm.season'),
      dataIndex: 'season',
      key: 'season',
      sorter: (a, b) => a.season.localeCompare(b.season),
      width: '50%',
    },
  ];

  const locale = {
    emptyText: t('applicationForm.empty_list'),
    triggerDesc: t('table.sort_descend'),
    triggerAsc: t('table.sort_ascend'),
    cancelSort: t('table.cancel_sort'),
  };

  const handleCreateClick = () => {
    navigate('/applicationForm/create');
  };

  const handleRowClick = (record) => {
    console.log('Row clicked:', record);
  };

  const handleEditClick = (record) => {
    navigate(`/applicationForm/${record}/edit`);
  };

  const showDeleteConfirm = (id, name) => {
    setApplicationIdToDelete(id);
    setApplicationNameToDelete(name);
    setShowConfirmation(true);
  };

  if (ability.can('delete', 'Application Forms') || ability.can('update', 'Application Forms')) {
    columns.push({
      title: '',
      key: 'actions',
      width: '65px',
      className: 'icons-column',
      render: (text, record) => (
        <Row>
          {ability.can('update', 'Application Forms') && (
            <button
              type="button"
              className={hoveredRowKey === record.id ? 'table-icon show' : 'table-icon'}
              onClick={() => handleEditClick(record.id)}
            >
              <img src={EditIcon} alt="Edit" />
            </button>
          )}
          {/* Bin icon to delete row */}
          {ability.can('delete', 'Application Forms') && (
            <button
              type="button"
              className={hoveredRowKey === record.id ? 'table-icon show' : 'table-icon'}
              onClick={(e) => {
                e.stopPropagation();
                showDeleteConfirm(record.id, record.name);
              }}
            >
              <img src={DeleteIcon} alt="Delete" />
            </button>
          )}
        </Row>
      ),
    });
  }

  const deleteRow = async () => {
    if (applicationIdToDelete) {
      try {
        await axios.delete(`/api/applicationForm/${applicationIdToDelete}`);
        // Update Table
        fetchApplicationForms();
        openNotificationWithIcon('success', applicationNameToDelete + t('confirmModal.success_delete'));
      } catch (error) {
        console.error('Error deleting user:', error);
        openNotificationWithIcon('error', t('confirmModal.error_delete') + applicationNameToDelete);
      }
    }
    setApplicationIdToDelete(null);
    setApplicationNameToDelete('');
  };

  return (
    <div className="applications-container">
      <Navbar />
      <NavbarMobile />
      <h1 className="application-title">{t('applicationForm.title')}</h1>
      <div className="search-and-table-container">
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Input
              placeholder={t('applicationForm.search_application')}
              className="search-application search-application-desktop search-application-mobile"
              value={searchText}
              onChange={handleSearch}
            />
          </Col>
        </Row>
      </div>
      <Row>
        <Col xs={24} sm={24} md={24} lg={24} className="applications-table-container">
          <Table
            locale={locale}
            columns={columns}
            dataSource={filteredData}
            className="applications-table"
            pagination={tableParams.pagination}
            onChange={handleTableChange}
            scroll={{ x: 500 }}
            onRow={(record) => ({
              onClick: () => handleRowClick(record.id),
              onMouseEnter: () => setHoveredRowKey(record.id),
              onMouseLeave: () => setHoveredRowKey(null),
            })}
          />
        </Col>
        <button type="button" className="new-application-btn" onClick={handleCreateClick}>
          {t('applicationForm.create_application')}
          <i className="plus-icon">+</i>
        </button>
      </Row>
      <ConfirmDelete
        open={showConfirmation}
        setOpen={setShowConfirmation}
        onConfirm={deleteRow} // Pass the confirmation handler function
        name={applicationNameToDelete}
      />
    </div>
  );
}

export default Applications;
