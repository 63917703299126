import React, { useState, useEffect } from 'react';
import {
  Col, Row, Input, notification, Select, Collapse,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { LuPencil } from 'react-icons/lu';
import { useNavigate, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from '../axios';
import ProfilePlaceholder from '../images/user_pic_placeholder.svg';
import Navbar from '../components/Navbar';
import NavbarMobile from '../components/NavbarMobile';
import ConfirmAction from '../components/ConfirmAction';
import PasswordForm from '../components/authentication/PasswordForm';
import languageStore from '../stores/languageStore';
import '../css/editProfile.css';

function UserEditProfile({ adminView }) {
  const navigate = useNavigate();
  const [t] = useTranslation('global');
  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';
  const companyID = 1;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [department, setDepartment] = useState('');
  const [gender, setGender] = useState('');
  const [role, setRole] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const fileInputRef = React.createRef();
  const [selectedFile, setSelectedFile] = useState(null);
  const [darkLogo, setDarkLogo] = useState('');

  const [submitted, setSubmitted] = useState(false);
  const [passedRequirements, setPassedRequirements] = useState(false);
  const [loadingImage, setLoadingImage] = useState(true);

  let userID = 0;
  if (adminView) {
    const { id } = useParams();
    userID = id;
  }

  const userInfoURL = adminView ? `/api/user/${userID}` : '/api/user/profile';
  const editUserURL = adminView ? `/api/user/${userID}/edit` : '/api/user/profile/edit';
  const navigateURL = adminView ? `/user/${userID}` : '/profile';
  const uploadImageURL = adminView ? `/api/upload/${userID}` : '/api/upload/profile';
  const [localPreview, setLocalPreview] = useState(null);

  const genderOptions = [{
    value: '1',
    label: t('gender.1'),
  },
  {
    value: '2',
    label: t('gender.2'),
  },
  {
    value: '0',
    label: t('gender.0'),
  },
  ];

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  useEffect(() => {
    axios.get(userInfoURL)
      .then((response) => {
        setName(response.data.name);
        setEmail(response.data.email);
        const genderKey = String(response.data.gender);
        setGender(genderKey);
        setDepartment(response.data.Department.id);
        setProfileImage(response.data.profileImage || ProfilePlaceholder);
        if (adminView) {
          setRole(response.data.Role.id);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openNotificationWithIcon('error', t('auth.need_login'));
          navigate('/login');
        } else if (error.response.status === 403) {
          openNotificationWithIcon('error', t('auth.not_authorized'));
          navigate('/login');
        }
      });

    const fetchDepartments = async () => {
      try {
        const response = await axios.get(
          '/api/department',
        );
        if (response.status === 200) {
          const departments = response.data;
          const deptOptions = [];
          departments.forEach((element) => {
            deptOptions.push({ value: element.id, label: element.name[selectedLanguage] });
          });
          setDepartmentOptions(deptOptions);
        }
      } catch (error) {
        console.error('Error during departments fetch:', error);
      }
    };
    fetchDepartments();

    const fetchRoles = async () => {
      try {
        const response = await axios.get(
          '/api/role',
        );
        if (response.status === 200) {
          const roles = response.data;
          const roleOpts = [];
          roles.forEach((element) => {
            roleOpts.push({ value: element.id, label: element.name[selectedLanguage] });
          });
          setRoleOptions(roleOpts);
        }
      } catch (error) {
        console.error('Error during roles fetch:', error);
      }
    };
    fetchRoles();
  }, [languageStore.getState().currentLanguage]);

  const handleSubmit = async () => {
    setSubmitted(true);
    if (!passedRequirements && (newPassword !== '' || confirmPassword !== '')) {
      openNotificationWithIcon('error', t('auth.requirements_not_met'));
      return;
    }
    try {
      const response = await axios.post(
        editUserURL,
        {
          name, email, department, gender, role, currentPassword, newPassword, confirmPassword,
        },
      );
      if (response.status === 200) {
        openNotificationWithIcon('success', t('profile.edit_success'));
        navigate(navigateURL);
      } else {
        openNotificationWithIcon('error', t('profile.edit_failed'));
      }
    } catch (error) {
      // Handle error responses
      if (error.response.status === 401 && error.response.data === 'Incorrect current password') {
        openNotificationWithIcon('error', t('profile.wrong_current_password'));
      } else if (error.response.status === 401) {
        openNotificationWithIcon('error', 'You are not authorized to view this page');
        navigate('/login');
      } else if (error.response.status === 403) {
        openNotificationWithIcon('error', 'You are not authorized to view this page');
        navigate('/login');
      } else {
        console.error('Error during profile update:', error);
        openNotificationWithIcon('error', t('profile.edit_failed'));
      }
    }
  };

  const changePasswordFields = [{
    key: 1,
    label: (
      <div className="edit-profile-input-section-header">
        <p className="edit-profile-label">{t('profile.change_password')}</p>
      </div>),

    children: (
      <div>
        <Input.Password
          type="password"
          className="edit-profile-input-password-field"
          placeholder={t('profile.current_password')}
          value={currentPassword}
          onChange={(e) => setCurrentPassword(e.target.value)}
          required
        />
        <PasswordForm
          design="3"
          submitted={submitted}
          password={newPassword}
          onPasswordChange={(password) => setNewPassword(password)}
          confirmPassword={confirmPassword}
          onConfirmPasswordChange={(password) => setConfirmPassword(password)}
          onRequirementsChange={(e) => setPassedRequirements(e)}
        />
        {/* <Input.Password
          type="password"
          className="edit-profile-input-password-field"
          placeholder={t('profile.new_password')}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
        <p>Requisitos</p>
        <Input.Password
          type="password"
          className="edit-profile-input-password-field"
          placeholder={t('profile.confirm_password')}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        /> */}
      </div>),
  }];
  useEffect(() => {
    if (selectedFile) {
      const handleUpload = async () => {
        const formData = new FormData();
        formData.append('file', selectedFile);
        try {
          const { data: { imageUrl } } = await axios.post(uploadImageURL, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });
          setProfileImage(imageUrl);
        } catch (error) {
          console.error('Error uploading file: ', error);
        }
      };
      handleUpload();
    }
  }, [selectedFile]);

  const handleProfileEditConfirmation = () => {
    handleSubmit();
  };

  const showConfirmationModal = () => {
    setShowConfirmation(true);
  };

  const triggerFileSelect = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = ({ target: { result } }) => {
        const img = new Image();
        img.src = result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const maxWidth = 1000;
          const maxHeight = 1000;
          let { width, height } = img;

          if (width > height && width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          } else if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            const compressedFile = new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now(),
            });
            const previewUrl = URL.createObjectURL(compressedFile);
            setSelectedFile(compressedFile);
            setLocalPreview(previewUrl);
          }, 'image/jpeg', 0.6);
        };
      };
    }
  };

  useEffect(() => () => {
    if (profileImage && profileImage.startsWith('blob:')) {
      URL.revokeObjectURL(profileImage);
    }
  }, [profileImage]);
  useEffect(() => {
    axios.get(`/api/settings/company/${companyID}/logos`)
      .then((response) => {
        setDarkLogo(response.data.companyLogoDark);
      })
      .catch((error) => {
        console.error('Error fetching logos:', error);
        openNotificationWithIcon('error', t('settings.logo_fetch_failed'));
      });
  }, []);
  return (
    <div>
      <Row className="user-profile-container">
        <Navbar />
        <NavbarMobile />
        <Col xs={24} sm={24} md={9} className="user-col1">
          <h1 className="user-profile-title-mobile">{t('profile.edit_profile')}</h1>
          {loadingImage && <span className="loader" />}
          <img
            src={localPreview || profileImage}
            alt="profile"
            className="profile-img"
            onLoad={() => setLoadingImage(false)}
            style={{ display: loadingImage ? 'none' : 'block' }}
          />
          <div
            label="Profile Image"
            className="edit-user-photo"
            onClick={triggerFileSelect}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                triggerFileSelect();
              }
            }}
            role="button"
            tabIndex={0}
          >
            <LuPencil />
          </div>
          <input
            label="Profile Image"
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleFileChange}
            accept="image/png, image/jpeg, image/jpg"
          />
        </Col>
        <Col xs={24} sm={24} md={15} className="user-col2">
          <img src={darkLogo} alt="logo" className="profile-logo desktop" id="logo-desktop" />
          <div className="user-col2-content">
            <div className="user-info">
              <h1 className="edit-profile-title-desktop">{t('profile.edit_profile')}</h1>
              <form className="edit-profile-input-container" onSubmit={handleSubmit}>
                <div className="edit-profile-input-section">
                  <div className="edit-profile-input-section-header">
                    <LuPencil />
                    <p className="edit-profile-label">{t('profile.name')}</p>
                  </div>
                  <Input
                    className="edit-profile-input-field"
                    value={name}
                    maxLength={320}
                    onChange={(e) => setName(e.target.value)}

                  />
                </div>
                <div className="edit-profile-input-section">
                  <div className="edit-profile-input-section-header">
                    <LuPencil />
                    <p className="edit-profile-label">{t('profile.email')}</p>
                  </div>
                  <Input
                    className="edit-profile-input-field"
                    type="email"
                    value={email}
                    maxLength={320}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="edit-profile-input-section">
                  <div className="edit-profile-input-section-header">
                    <LuPencil />
                    <p className="edit-profile-label">{t('profile.gender')}</p>
                  </div>
                  <Select
                    className="edit-profile-input-field"
                    value={gender}
                    onChange={(value) => setGender(value)}
                    options={genderOptions}
                    optionFilterProp="children"
                    filterOption={filterOption}
                    showSearch
                  />
                </div>
                <div className="edit-profile-input-section">
                  <div className="edit-profile-input-section-header">
                    <LuPencil />
                    <p className="edit-profile-label">{t('profile.department')}</p>
                  </div>
                  <Select
                    className="edit-profile-input-field"
                    value={department}
                    onChange={(value) => setDepartment(value)}
                    options={departmentOptions}
                    optionFilterProp="children"
                    filterOption={filterOption}
                    showSearch
                  />
                </div>
                {adminView && (
                  <div className="edit-profile-input-section">
                    <div className="edit-profile-input-section-header">
                      <LuPencil />
                      <p className="edit-profile-label">{t('profile.role')}</p>
                    </div>
                    <Select
                      className="edit-profile-input-field"
                      value={role}
                      onChange={(value) => setRole(value)}
                      options={roleOptions}
                      optionFilterProp="children"
                      filterOption={filterOption}
                      showSearch
                    />
                  </div>
                )}
                <div className="edit-profile-input-section" id="edit-password-collapse">
                  <Collapse
                    ghost
                    items={changePasswordFields}
                  />
                </div>
                <button className="edit-profile-submit-button" type="button" onClick={showConfirmationModal}>
                  {t('profile.update_button')}
                </button>
              </form>
              <ConfirmAction
                open={showConfirmation}
                setOpen={setShowConfirmation}
                onConfirm={handleProfileEditConfirmation} // Pass the confirmation handler function
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

UserEditProfile.propTypes = {
  adminView: PropTypes.bool.isRequired,
};

export default UserEditProfile;
