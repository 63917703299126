import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import RegisterForm from '../components/authentication/RegisterForm';
import AuthFooter from '../components/AuthFooter';
import LoginForm from '../components/authentication/LoginForm';
import Header from '../components/Header';
import '../css/auth.css';
import axios from '../axios';

function Auth({ register }) {
  const [t] = useTranslation('global');
  const navigate = useNavigate();
  const [slidingRight, setSlidingRight] = useState(register);
  const [lightLogo, setLightLogo] = useState('');
  const [darkLogo, setDarkLogo] = useState('');
  const companyID = 1;
  const handleSlide = () => {
    setSlidingRight(!slidingRight);
    navigate(slidingRight ? '/login' : '/register');
  };

  useEffect(() => {
    axios.get(`/api/settings/company/${companyID}/logos`)
      .then((response) => {
        setLightLogo(response.data.companyLogoLight);
        setDarkLogo(response.data.companyLogoDark);
      })
      .catch((error) => {
        console.error('Error fetching logos:', error);
      });
  }, []);
  return (
    <div className="auth-transition-container">
      <div className="login">
        <Row>
          <Col xs={24} sm={24} md={9} className={`register-form ${slidingRight ? 'active' : 'hide'}`}>
            <img src={darkLogo} alt="logo" className="logo-auth-register" />
            <RegisterForm />
          </Col>
          <Col xs={24} sm={24} md={6} />
          <div className={`mobile-transition-div ${slidingRight ? 'register-mobile' : 'login-mobile'}`}>
            <Header />
            <img src={lightLogo} alt="logo" className={`logo-auth ${slidingRight ? 'hide' : ''}`} />
            <div className="auth-content">
              <div className={`login-content ${slidingRight ? 'hide' : ''}`}>
                <p className="auth-subtitle">{t('auth.dont_have_account')}</p>
                <p className="auth-text">{t('auth.create_account')}</p>
                <button className="auth-transition-btn" type="button" onClick={handleSlide}>{t('auth.register')}</button>
              </div>
              <div className={`register-content ${slidingRight ? '' : 'hide'}`}>
                <p className="auth-subtitle">{t('auth.already_have_account')}</p>
                <p className="auth-text">{t('auth.login_to_continue')}</p>
                <button className="auth-transition-btn" type="button" onClick={handleSlide}>{t('auth.login')}</button>
              </div>
            </div>
            <div className={`footer-auth-register ${slidingRight ? '' : 'hide'}`}>
              <AuthFooter />
            </div>
          </div>
          <Col xs={24} sm={24} md={9} className={`login-form ${slidingRight ? 'hide' : 'active'}`}>
            <LoginForm />
            <div className="footer-auth">
              <AuthFooter />
            </div>
          </Col>
        </Row>
      </div>
      <div className={`logo-column ${slidingRight ? 'slide-right' : 'slide-left'}`}>
        <h1 className="header-title">{t('auth.welcome')}</h1>
        <div className={`login-content ${slidingRight ? 'hide' : ''}`}>
          <p className="auth-subtitle">{t('auth.dont_have_account')}</p>
          <p className="auth-text">{t('auth.create_account')}</p>
          <button className="auth-transition-btn" type="button" onClick={handleSlide}>{t('auth.register')}</button>
        </div>
        <div className={`register-content ${slidingRight ? '' : 'hide'}`}>
          <p className="auth-subtitle">{t('auth.already_have_account')}</p>
          <p className="auth-text">{t('auth.login_to_continue')}</p>
          <button className="auth-transition-btn" type="button" onClick={handleSlide}>{t('auth.login')}</button>
        </div>
        <img src={lightLogo} alt="logo" className="logo-auth" />
      </div>
    </div>
  );
}

Auth.propTypes = {
  register: PropTypes.bool.isRequired,
};

export default Auth;
