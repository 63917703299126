import React from 'react';
import { Row } from 'antd';
import TranslateButton from './TranslateButton';

function Header() {
  return (
    <div className="header">
      <Row>
        <TranslateButton />
      </Row>
    </div>
  );
}

export default Header;
