import React from 'react';
import { useTranslation } from 'react-i18next';
import '../css/aboutUs.css';

function AboutUs() {
  const [t] = useTranslation('global');

  return (
    <div className="about-us-component">
      <div className="about-us-text-div">
        <h1 className="about-us-title">{t('about_us.title')}</h1>
        <p className="about-us-description">
          Júnior Empresa da Faculdade de Engenharia no Porto. Temos +60 membros e
          +170 projetos concluídos! Submete o teu currículo e descobre mais sobre
          nós!
        </p>
      </div>
      <button type="submit" className="about-us-contact-button">{t('about_us.contact_button')}</button>
    </div>
  );
}

export default AboutUs;
