import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Offer from './Offer';
import photo from '../images/phone-call.svg';
import languageStore from '../stores/languageStore';
import axios from '../axios';
import '../css/offers.css';

function Offers() {
  const [t] = useTranslation('global');
  const [offers, setOffers] = useState([]);
  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';

  const fetchJobs = async () => {
    try {
      const response = await axios.get('/api/job');
      setOffers(response.data);
    } catch (error) {
      console.error('Error fetching Jobs:', error);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    const offerDivs = document.querySelectorAll('.offer-div');
    let maxHeight = 0;

    offerDivs.forEach((div) => {
      const height = div.clientHeight;
      if (height > maxHeight) {
        maxHeight = height;
      }
    });

    offerDivs.forEach((div) => {
      /* eslint-disable */
      div.style.height = `${maxHeight}px`;
    });
  }, [offers]);

  return (
    <div className="offers">
      <h1 className="offers-title">{t('offers.title')}</h1>
      <div className="offers-container">
        {offers.map((offer) => (
          <Offer
            name={offer.name[selectedLanguage]}
            description={offer.description[selectedLanguage]}
            photo={photo}
          />
        ))}
      </div>
    </div>
  );
}

export default Offers;
