import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createMongoAbility, AbilityBuilder } from '@casl/ability';
import axios from '../axios';
import { AbilityContext } from '../Abilities/Can';
import { useAuth } from '../contexts/AuthContext';
import AboutUs from '../components/AboutUs';
import Offers from '../components/Offers';
import Opportunities from '../components/Opportunities';
import Recruitment from '../components/Recruitment';
import Testimonials from '../components/Testimonials';
import Footer from '../components/Footer';
import TranslateButton from '../components/TranslateButton';
import LogoutIcon from '../images/logout_icon.svg';
import '../css/home.css';

function Home() {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [t] = useTranslation('global');
  const ability = useContext(AbilityContext);
  const { logout } = useAuth();

  useEffect(() => {
    axios.get(
      '/api/user/profile',
    )
      .then((response) => {
        if (response.status === 200) {
          setIsLoggedIn(true);
          axios.get(`/api/user/permission/${response.data.id}`).then((permissionResponse) => {
            const { can, rules } = new AbilityBuilder(createMongoAbility);
            permissionResponse.data.forEach((permission) => {
              can(permission.action, permission.subject);
            });
            ability.update(rules);
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        '/api/user/logout',
      );
      if (response.status === 200) {
        logout();
        setIsLoggedIn(false);
        navigate('/login');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="page-container">
      <div className="homepage-header">
        {isLoggedIn ? (
          <button type="button" className="logout-button" onClick={handleLogout}>
            <img src={LogoutIcon} className="logout-icon" alt="logout" />
            {t('auth.logout')}
          </button>
        ) : null}
        <TranslateButton className="translate-button-home" />
      </div>
      <AboutUs />
      <Opportunities />
      <Recruitment />
      <Offers />
      <Testimonials />
      <Footer />
    </div>
  );
}

export default Home;
