import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import NavbarMobile from '../components/NavbarMobile';
import '../css/App.css';
import axios from '../axios';
import '../css/404.css';

function NoPage() {
  const [t] = useTranslation('global');
  const navigate = useNavigate();
  const companyID = 1;
  const [darkLogo, setDarkLogo] = useState('');
  useEffect(() => {
    axios.get(`/api/settings/company/${companyID}/logos`)
      .then((response) => {
        setDarkLogo(response.data.companyLogoDark);
      })
      .catch((error) => {
        console.error('Error fetching logos:', error);
      });
  }, []);
  return (
    <div className="error-page-container">
      <Navbar />
      <NavbarMobile />
      <div className="error-page-navbar">
        <div className="error-page-navbar-hamburguer">
          <img src={darkLogo} alt="logo" className="logo-black-error-page-hamburguer" />
        </div>
        <div className="error-page-navbar-desktop">
          <img src={darkLogo} alt="logo" className="logo-black-error-page-desktop" />
        </div>
      </div>
      <div className="error-page-content">
        <p className="error-title">{t('error.title')}</p>
        <p className="error-404">{t('error.404')}</p>
        <p className="error-message">{t('error.404_message')}</p>
        <button className="error-back" type="button" onClick={() => navigate(-1)}>{t('error.back')}</button>
      </div>
    </div>
  );
}

export default NoPage;
