// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* Create Jobs */

.create-job-title {
    font-family: var(--font), sans-serif;
    font-weight: 700;
    color: var(--primary-color);
    font-size: 35px;
    align-items: center;
    align-content: center;
}

.create-job-popup {
    font-family: var(--font), sans-serif;
}

.create-job-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.create-job-name {
    font-family: var(--font), sans-serif;
    font-weight: 500;
    color: var(--primary-color);
    font-size: 18px;
    margin-block: 1em;
}

.create-job-icon{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.create-job-button {
    color: var(--secondary-color);
    background-color: var(--primary-color);
    font-family: var(--font), sans-serif;
    font-weight: 500;
    margin-left: auto;
}

.input-icon{
    font-family: var(--font), sans-serif;
    font-weight: 500;
    color: var(--primary-color);
    font-size: 18px;
    margin-block: 1em;
    margin-left: 25px;
}

.create-job {
    min-width: 100px;
    width: 30%;
  }`, "",{"version":3,"sources":["webpack://./src/css/createJob.css"],"names":[],"mappings":";AACA,gBAAgB;;AAEhB;IACI,oCAAoC;IACpC,gBAAgB;IAChB,2BAA2B;IAC3B,eAAe;IACf,mBAAmB;IACnB,qBAAqB;AACzB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,oCAAoC;IACpC,gBAAgB;IAChB,2BAA2B;IAC3B,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,6BAA6B;IAC7B,sCAAsC;IACtC,oCAAoC;IACpC,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,oCAAoC;IACpC,gBAAgB;IAChB,2BAA2B;IAC3B,eAAe;IACf,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,UAAU;EACZ","sourcesContent":["\n/* Create Jobs */\n\n.create-job-title {\n    font-family: var(--font), sans-serif;\n    font-weight: 700;\n    color: var(--primary-color);\n    font-size: 35px;\n    align-items: center;\n    align-content: center;\n}\n\n.create-job-popup {\n    font-family: var(--font), sans-serif;\n}\n\n.create-job-form {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n\n.create-job-name {\n    font-family: var(--font), sans-serif;\n    font-weight: 500;\n    color: var(--primary-color);\n    font-size: 18px;\n    margin-block: 1em;\n}\n\n.create-job-icon{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n\n.create-job-button {\n    color: var(--secondary-color);\n    background-color: var(--primary-color);\n    font-family: var(--font), sans-serif;\n    font-weight: 500;\n    margin-left: auto;\n}\n\n.input-icon{\n    font-family: var(--font), sans-serif;\n    font-weight: 500;\n    color: var(--primary-color);\n    font-size: 18px;\n    margin-block: 1em;\n    margin-left: 25px;\n}\n\n.create-job {\n    min-width: 100px;\n    width: 30%;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
